import React, { useState, useRef, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import CreatableSelect from 'react-select/creatable';
import Select from "react-select";
import { DateRangePicker, SingleDatePicker, toLocalizedDateString } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import moment from "moment";
import styels from "./CreateProject.module.css";
import deleteIcon from "../../assets/delete.png";
import draftIcon from "../../assets/draft.png";
import uploadIcon from "../../assets/upload.png";
import redDelete from "../../assets/deleteRed.png";
import bgImage from '../../assets/BackGround/bg3web.png';
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import {
  updateProject,
  getDivision,
  getSbu,
  getOrgUnit,
  getDepartment,
  getFunction,
  getRequiredSkill,
  getImproveLearnSkill,
  sendMessage,
  addSkill,
  deleteDraftUpload
} from "../../services/index";
import "react-dates/initialize";
import "./DateInput.module.css";
import { useEffect } from "react";
import { thumbnails, banners } from "../common/Images";
import Slider from "react-slick";
import ReactPlayer from "react-player";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ]

};

const coverSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ]
};

const EditDraft = (props) => {
  const draftData = props.location.state.draft;
  const navigate = useHistory();
  const mediaRef = useRef();
  const formRef = useRef();
  const thumbnailRef = useRef();
  const [instructionFiles, setInstructionFiles] = useState([]);
  const [keynote, setKeynote] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [requiredSkills, setRequiredSkills] = React.useState([]);
  const [improvedSkills, setImprovedSkills] = React.useState([]);
  const [saveType, setSaveType] = React.useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [thumbnailImgSrc, setThumbnailImgSrc] = useState("");
  const [coverImgSrc, setCoverImgSrc] = useState("");
  const imgThumbnailRef = useRef(null);
  const imgCoverRef = useRef(null);
  const [crop, setCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const coverRef = useRef(null);
  const [croppedThumbnailImageUrl, setCroppedThumbnailImageUrl] =
    useState(null);
  const [croppedCoverImageUrl, setCroppedCoverImageUrl] = useState(null);
  const [projectData, setProjectData] = useState(draftData);
  const [divisions, setDivisions] = useState([]);
  const [sbus, setSbus] = useState([]);
  const [orgUnits, setOrgUnits] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [skillRequired, setSkillRequired] = useState([]);
  const [skillImproveLearn, setSkillImproveLearn] = useState([]);
  const [lastDate, setLastDate] = useState(null);
  const [focusedLastDate, setFocusedLastDate] = useState(null);
  const [openForAll, setOpenForAll] = useState(false);
  // const [localThumbnail, setLocalThumbnail] = useState(null);
  const [localThumbnailIndex, setLocalThumbnailIndex] = useState(0);
  // const [localCover, setLocalCover] = useState(null);
  const [localCoverIndex, setLocalCoverIndex] = useState(0);
  const [signedUrls, setSignedUrls] = useState({});
  const [isDisabled, setIsDisabled] = useState(false);

  const handleOpenForAllChange = (e) => {
    setOpenForAll(e.target.checked);
  };

  let defaultDivision = null;
  let defaultSbu = null;
  let defaultOrgUnit = null;
  let defaultDepartment = null;
  let defaultFunction = null;

  if (projectData.division) {
    defaultDivision = JSON.parse(projectData.division);
  }


  if (projectData.sbu) {
    defaultSbu = JSON.parse(projectData.sbu);
  }
  if (projectData.orgUnit) {
    defaultOrgUnit = JSON.parse(projectData.orgUnit);
  }
  if (projectData.department) {
    defaultDepartment = JSON.parse(projectData.department);
  }

  if (projectData.function) {
    defaultFunction = JSON.parse(projectData.function);
  }



  function getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  }

  const onCropComplete = useCallback(async (crop, onChange, imgName) => {
    let result;
    if (imgName === "cover") {
      result = imgCoverRef;
    } else {
      result = imgThumbnailRef;
    }
    if (result.current && crop.width && crop.height) {
      try {
        const blob = await getCroppedImg(result.current, crop);
        const croppedImageFile = new File([blob], "cropped-image.jpeg", {
          type: "image/jpeg",
        });
        const croppedImageURL = URL.createObjectURL(croppedImageFile);
        if (imgName === "cover") {
          setCroppedCoverImageUrl(croppedImageURL);
          onChange(croppedImageFile);
        } else {
          setCroppedThumbnailImageUrl(croppedImageURL);
          onChange(croppedImageFile);
        }
      } catch (e) {
        console.error("Failed to crop image:", e);
      }
    }
  }, []);

  function onSelectFile(e, imgName) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        imgName === "cover"
          ? setCoverImgSrc(reader.result.toString() || "")
          : setThumbnailImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const mesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>

<p>Your team member [Sponsor Name] has submitted a Bridge Project for your approval. As next steps, please review the project details and proceed to:</p>

<p>
<a href="[Approve URL]" style="text-decoration: none;">
  <button style="background-color: #4CAF50; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-right: 10px;">Approve</button>
</a>

or

<a href="[Reject URL]" style="text-decoration: none;">
  <button style="background-color: #FF0000; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-left: 10px;">Needs Modification</button>
</a>
</p>

<p>Once you approve, the project will be made available to all eligible Applicants on the platform in the month of June’24 (dates will be announced shortly).</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>The details of the project are shared in the table below; we thank you for your sponsorship in enabling development and careers for Titanians!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

</body>
</html>

<p>
<a href="[Approve URL]" style="text-decoration: none;">
  <button style="background-color: #4CAF50; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-right: 10px;">Approve</button>
</a>

or

<a href="[Reject URL]" style="text-decoration: none;">
  <button style="background-color: #FF0000; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-left: 10px;">Needs Modification</button>
</a>
</p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const sponsorMesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>

<p>Your Bridge Project has been successfully submitted to [Manager Name] for approval.</p>

<p>Once [Manager Name] approves, the project will be made available to all eligible Applicants on the platform in the month of June’24 (dates will be announced shortly).</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Thank you for creating a learning and collaboration opportunity through your Bridge Project!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const sponsorAutoApproveMesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>This is to confirm successful submission of your Bridge Project "[Project ID]: [Project Title]".</p>

<p>The project is now under 'Open Projects' section and will be available to all eligible Applicants on the platform in the month of June’24 (dates will be announced shortly).</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Thank you for creating a learning and collaboration opportunity through your Bridge Project!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>
Regards,<br/>
Team Titan Career Vista
</p>
  `;

  const onUpdate = async (data) => {
    if ((saveType === "open" || saveType === "pending") && !(dateRange.startDate || dateRange.endDate)) {
      toast.error("Please select start date and end date");
      return;
    }


    if (saveType !== "trash" && saveType !== "draft" && openForAll === false && data.L1 === false && data.L2 === false && data.L3 === false && data.L4 === false && data.L5 === false && data.L6 === false && data.L7 === false && data.L8 === false && data.L9 === false) {
      toast.error("Please select at least one employee level");
      return;
    }

    if (saveType !== "trash" && saveType !== "draft" && data.weeklyTime === "Hours per week") {
      toast.error("Please select weekly time comment before submitting.");
      return
    }

    if (data.dateRange.startDate && data.dateRange.endDate) {
      const startDate = new Date(data.dateRange.startDate._d);
      const endDate = new Date(data.dateRange.endDate._d);
      const duration = endDate - startDate;
      const oneMonth = 30 * 24 * 60 * 60 * 1000;
      if (duration < oneMonth) {
        toast.error("The duration of the project must be at least one month.");
        return;
      }
    }

    const userId = window.localStorage.getItem("userId");
    const formData = new FormData();
    let trueValues = Object.keys(data).filter((key) => data[key] === true);
    if (openForAll === true) {
      trueValues = trueValues = [
        "L1",
        "L2",
        "L3",
        "L4",
        "L5",
        "L6",
        "L7",
        "L8",
        "L9",
      ];
    }
    let updatedEndDate;
    if ((projectData.endDate.length || data.dateRange.endDate) && !projectData.endDate.includes(String(new Date(dateRange.endDate._d).getTime()))) {

      updatedEndDate = [...projectData.endDate, String(new Date(dateRange.endDate._d).getTime())];
    } else {
      updatedEndDate = projectData.endDate;

    }
    formData.append("localThumbnail", localThumbnailIndex);
    formData.append("localCover", localCoverIndex);
    formData.append("thumbnail", data.thumbnail);
    formData.append("cover", data.cover);
    formData.append("openPositions", data.positions);
    data.instructions.map((item) => {
      formData.append("instructions", item);
    });
    formData.append("title", data.title);
    formData.append(
      "description",
      data.description ? data.description : projectData.description
    );
    if (data.division) {
      const divisionValue = (data.division && data.division)
      if (divisionValue) {
        formData.append("division", JSON.stringify(divisionValue));
      }
    }
    if (data.sbu) {
      const sbuValue = data.sbu && data.sbu
      if (sbuValue) {
        formData.append("sbu", JSON.stringify(sbuValue));
      }
    }
    if (data.orgUnit) {
      const orgUnitValue = data.orgUnit && data.orgUnit
      if (orgUnitValue) {
        formData.append("orgUnit", JSON.stringify(orgUnitValue));
      }
    }
    if (data.department) {
      const departmentValue = data.department && data.department
      if (departmentValue) {
        formData.append("department", JSON.stringify(departmentValue));
      }
    }
    if (data.function) {
      const functionValue = data.function && data.function
      if (functionValue) {
        formData.append("function", JSON.stringify(functionValue));
      }
    }

    trueValues.map((item) => {
      formData.append("employeeLevels[]", item);
    });
    if (!data.requiredSkills) {
      projectData.requiredSkills.map((item) => {
        formData.append("requiredSkills[]", item);
      });
    } else {
      data.requiredSkills.map((item) => {
        formData.append("requiredSkills[]", JSON.stringify(item));
      });
    }
    if (!data.improveLearnSkills) {
      projectData.improveLearnSkills.map((item) => {
        formData.append("improveLearnSkills[]", item);
      });
    } else {
      data.improveLearnSkills.map((item) => {
        formData.append("improveLearnSkills[]", JSON.stringify(item));
      });
    }
    formData.append(
      "keyDeliverables",
      data.keyDeliverables ? data.keyDeliverables : projectData.keyDeliverables
    );
    formData.append("weeklyTime", data.weeklyTime);
    if (data.dateRange.startDate) {
      formData.append("startDate", new Date(dateRange.startDate._d).getTime());

    }
    if (data.dateRange.endDate) {
      updatedEndDate.map((item) => {
        formData.append("endDate[]", item);
      })
    }
    if (data.lastDate) {
      formData.append("lastDate", new Date(data.lastDate._d).getTime());
    }
    formData.append("typeOfRequirement", data.typeOfRequirement);
    formData.append("status", saveType);
    formData.append("id", projectData._id);
    try {
      toast.info(`Please wait while we update your project...`);
      setIsDisabled(true);
      const result = await updateProject(formData);
      if (result.data.status === "200") {
        if (saveType === "pending") {
          const dataToSendManager = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("managerEmail"),
            titleName: window.localStorage.getItem("managerName"),
            sponsorName: window.localStorage.getItem("userName"),
            subject: `Bridge Project "[Project ID]" Submitted for your Approval by [Sponsor Name]`,
            message: mesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendManager);
          const dataToSendSponsor = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("userEmail"),
            titleName: window.localStorage.getItem("userName"),
            managerName: window.localStorage.getItem("managerName"),
            subject: `Your Bridge Project "[Project ID]" submitted successfully for Approval`,
            message: sponsorMesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendSponsor);
        } else if (saveType === "open") {
          const dataToSendSponsorAutoApprove = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("userEmail"),
            titleName: window.localStorage.getItem("userName"),
            managerName: window.localStorage.getItem("managerName"),
            subject: `Your Bridge Project "[Project ID]" submitted successfully for approval`,
            message: sponsorAutoApproveMesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendSponsorAutoApprove);
        }
        toast.success(`Project ${saveType === "pending" ? "Submitted for Approval" : saveType === "open" ? "created successfully" : saveType === "draft" ? `saved as draft` : "moved to trash "}`);
        setTimeout(() => {
          navigate.push("/sponsor/dashboard");
        }, 500);
        setIsDisabled(false);
      } else {
        toast.error(
          "Something went wrong, Please try again."
        );
        setIsDisabled(false);
      }
    } catch (e) {
      toast.error("Something went wrong, Please try again.");
      setIsDisabled(false);
    }
  };

  const handleInvalidSubmit = (errors) => {
    toast.error("please fill all the required fields");
  };

  const divisionList = async () => {
    const res = await getDivision();
    if (res.data.status === "200") {
      const finalData = res.data.divisions.map((item) => {
        return {
          label: item.division,
          value: item.division,
          id: item._id,
        };
      });
      setDivisions(finalData);
    }
  };

  const deleteUploadedFile = async (index) => {
    const response = await deleteDraftUpload({ id: projectData._id, index: index });
    if (response.data.status === "200") {
      setProjectData(projectData => {
        const updatedInstructions = projectData.instructions.filter((_, i) => i !== index);
        return { ...projectData, instructions: updatedInstructions };
      });
    }

  }

  const sbuList = async (divisionId) => {
    const res = await getSbu(divisionId);
    if (res.data.status === "200") {
      const finalData = res.data.sbus.map((item) => {
        return {
          label: item.sbu,
          value: item.sbu,
          id: item._id,
        };
      });
      setSbus(finalData);
    }
  };


  const orgUnitList = async (sbuId) => {
    const res = await getOrgUnit(sbuId);
    if (res.data.status === "200") {
      const finalData = res.data.orgUnits.map((item) => {
        return {
          label: item.orgUnit,
          value: item.orgUnit,
          id: item._id,
        };
      });
      setOrgUnits(finalData);
    }
  };

  const departmentList = async (sbuId) => {
    const res = await getDepartment(sbuId);
    if (res.data.status === "200") {
      const finalData = res.data.departments.map((item) => {
        return {
          label: item.department,
          value: item.department,
          id: item._id,
        };
      });
      setDepartments(finalData);
    }
  };

  const functionList = async () => {
    const res = await getFunction();
    if (res.data.status === "200") {
      const finalData = res.data.functions.map((item) => {
        return {
          label: item.function,
          value: item.function,
          id: item._id,
        };
      });
      setFunctions(finalData);
    }
  };

  const requiredSkillsList = async () => {
    const res = await getRequiredSkill();
    if (res.data.status === "200") {
      const finalData = res.data.requiredSkills.map((item) => {
        return {
          label: item.skill,
          value: item.skill,
          id: item._id,
        };
      });
      setSkillRequired(finalData);
    }
  };

  const improveLearnSkillList = async (skillData) => {
    // const skillOfIds = skillData.map((obj) => obj.id);
    // const requestData = {
    //   skillOfIds,
    // };
    const res = await getImproveLearnSkill();
    if (res.data.status === "200") {
      const finalData = res.data.improveLearnSkills.map((item) => {
        return {
          label: item.improveLearnSkills,
          value: item.improveLearnSkills,
          id: item._id,
        };
      });
      setSkillImproveLearn(finalData);
    }
  };

  const {
    control,
    register,
    watch,
    formState: { errors },
    handleSubmit,
    setValue
  } = useForm();

  const goToTrashPage = () => {
    navigate.push("/sponsor/trash");
  };

  const goToDraftsPage = () => {
    navigate.push("/sponsor/drafts");
  };

  const handleSelectThumbnail = (thumb) => {
    // setThumbnailImgSrc(""); // Clearing the src for uploaded image
    // setCroppedThumbnailImageUrl(null); // Clearing cropped image URL
    // setLocalThumbnail(thumb.path); // Setting the selected thumbnail from slider
    setLocalThumbnailIndex(thumb.key)
    // thumbnailRef.current.value = "";
  }

  const handleSelectCover = (thumb) => {
    // setCoverImgSrc(""); // Clearing the src for uploaded image
    // setCroppedCoverImageUrl(null); // Clearing cropped image URL
    // setLocalCover(thumb.path); // Setting the selected thumbnail from slider
    setLocalCoverIndex(thumb.key)
    // coverRef.current.value = "";
  }

  const removeFile = (indexToRemove) => {
    setInstructionFiles((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const postSkill = async (skill) => {
    const finalData = {
      improveLearnSkills: skill
    }
    const res = await addSkill(finalData);
    if (res.data.status === "200") {
      toast.success("Skill added successfully");
    }
  }

  useEffect(() => {
    if (projectData.startDate) {
      setDateRange({
        startDate: moment(projectData.startDate),
        endDate: moment(Number(projectData.endDate[projectData.endDate.length - 1])),
      });
    }

    divisionList();
    functionList();
    requiredSkillsList();
    improveLearnSkillList();
    sbuList(defaultDivision?.id);
    orgUnitList(defaultSbu?.id);
    departmentList(defaultSbu?.id);
    if (projectData.localThumbnail) {
      // setLocalThumbnail(thumbnails[projectData.localThumbnail].path);
      setLocalThumbnailIndex(projectData.localThumbnail)
    }
    if (projectData.localCover) {
      // setLocalCover(banners[projectData.localCover].path);
      setLocalCoverIndex(projectData.localCover)
    }
    const levels = ['L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8', 'L9'];
    levels.forEach((level) => {
      setValue(level, false);
    });
    const hasAllLevels = levels.every(level => projectData.employeeLevels.includes(level));
    setOpenForAll(hasAllLevels);
    if (!hasAllLevels) {
      projectData.employeeLevels.forEach((level) => {
        setValue(level, true);
      });
    }
  }, []);

  useEffect(() => {
    if (projectData.requiredSkills.length || projectData.improveLearnSkills.length) {
      const defaultRequiredSkills = projectData.requiredSkills.map((each) =>
        JSON.parse(each)
      );
      const defaultImprovedSkills = projectData.improveLearnSkills.map((each) =>
        JSON.parse(each)
      );
      setRequiredSkills(defaultRequiredSkills);
      setImprovedSkills(defaultImprovedSkills)
    }

    if (projectData.instructions.length) {
      projectData.instructions.forEach((resource) => {
        setSignedUrls(prevUrls => ({ ...prevUrls, [resource]: resource }));
      });
    }

    if (projectData.requiredSkills.length) {
      setValue("requiredSkills", projectData.requiredSkills.map((each) => JSON.parse(each)));
    }

    if (projectData.improveLearnSkills.length) {
      setValue("improveLearnSkills", projectData.improveLearnSkills.map((each) => JSON.parse(each)));
    }
  }, [projectData])

  const validateDescription = (value) => {
    if (value === '<p><br></p>' || value.trim() === '') {
      return 'This field is required';
    }
    return true;
  };

  return (
    <div className="Profile-page">
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styels.subdiv}>
          <div className={styels.backdiv}>
            <div className={styels.arrowDiv}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/sponsor/drafts"
              >
                <AiOutlineLeft style={{ fontSize: "32px" }} />
              </NavLink>
              <h1 className={styels.head}>Edit project</h1>
            </div>
            <div className={styels.draftCont}>
              <div className={styels.trashDiv} onClick={goToTrashPage}>
                <img src={deleteIcon} alt="deleteIcon" />
                <p>Trash</p>
              </div>
              <div className={styels.draftDiv} onClick={goToDraftsPage}>
                <img src={draftIcon} alt="deleteIcon" />
                <p>Drafts</p>
              </div>
            </div>
          </div>
          <form
            onSubmit={handleSubmit(onUpdate, handleInvalidSubmit)}
            encType="multipart/form-data"
            ref={formRef}
          >
            <div>
              <label htmlFor="title" className={styels.titleText}>
                Title
              </label>
              <input
                placeholder="Enter title"
                id="title"
                className={styels.titleInput}
                type="text"
                {...register("title", { required: true })}
                defaultValue={projectData.title}
              />
              <p>{errors.title?.message}</p>
            </div>
            <div className={styels.descriptionMargin}>
              <label htmlFor="description" className={styels.titleText}>
                Description
              </label>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: saveType !== 'trash' && saveType !== 'draft' ? 'Description is required' : false,
                  validate: saveType !== 'trash' && saveType !== 'draft' ? validateDescription : undefined,
                }}
                defaultValue={description || projectData.description}
                render={({ field }) => (
                  <ReactQuill
                    placeholder="Enter description"
                    style={{ height: "150px" }}
                    theme="snow"
                    id="description"
                    onChange={(value) => {
                      field.onChange(value);
                      setDescription(value);
                    }}
                    defaultValue={projectData.description || ""}
                  />
                )}
              />
            </div>
            {errors.description && <p style={{ color: "red" }}>{errors.description.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="division">
                Division
              </label>
              <Controller
                name="division"
                control={control}
                defaultValue={defaultDivision}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (projectData.division ? (defaultDivision && Object.keys(defaultDivision).length === 0) : true) ? 'Division is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select division"
                    name="division"
                    options={divisions}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      sbuList(values.id);

                    }}
                    defaultValue={defaultDivision}
                  />
                )}
              />
            </div>
            {errors.division && <p style={{ color: "red" }}>{errors.division.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="sbu">
                SBU
              </label>
              <Controller
                name="sbu"
                control={control}
                defaultValue={defaultSbu}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (projectData.sbu ? (defaultSbu && Object.keys(defaultSbu).length === 0) : true) ? 'SBU is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select SBU"
                    name="sbu"
                    options={sbus}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      orgUnitList(values.id);
                      departmentList(values.id);
                    }}
                    defaultValue={defaultSbu}
                  />
                )}
              />
            </div>
            {errors.sbu && <p style={{ color: "red" }}>{errors.sbu.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="orgUnit">
                Org Unit
              </label>
              <Controller
                name="orgUnit"
                control={control}
                defaultValue={defaultOrgUnit}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (projectData.orgUnit ? (defaultOrgUnit && Object.keys(defaultOrgUnit).length === 0) : true) ? 'Org Unit is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select org unit"
                    name="orgUnit"
                    options={orgUnits}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                    }}
                    defaultValue={defaultOrgUnit}
                  />
                )}
              />
            </div>
            {errors.orgUnit && <p style={{ color: "red" }}>{errors.orgUnit.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="department">
                Department
              </label>
              <Controller
                name="department"
                control={control}
                defaultValue={defaultDepartment}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (projectData.department ? (defaultDepartment && Object.keys(defaultDepartment).length === 0) : true) ? 'Department is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select department"
                    name="department"
                    options={departments}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                    }}
                    defaultValue={defaultDepartment}
                  />
                )}
              />
            </div>
            {errors.department && <p style={{ color: "red" }}>{errors.department.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="function">
                Function
              </label>
              <Controller
                name="function"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (projectData.function ? (defaultFunction && Object.keys(defaultFunction).length === 0) : true) ? 'Function is required' : false, }}
                defaultValue={defaultFunction}
                render={({ field }) => (
                  <Select
                    placeholder="Select function"
                    name="function"
                    options={functions}

                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                    }}
                    defaultValue={defaultFunction}
                  />
                )}
              />
            </div>
            {errors.function && <p style={{ color: "red" }}>{errors.function.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="positions">
                Number of open positions
              </label>
              <input className={styels.input} type="number" min="1" defaultValue={projectData.openPositions}  {...register("positions", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Number of open positions is required' : false })} />
            </div>
            {errors.positions && <p style={{ color: "red" }}>{errors.positions.message}</p>}
            <div className={styels.checkDiv}>
              <h6 className={`${styels.titleText} ${styels.checkText}`}>
                Open to
              </h6>
              <input
                type="checkbox"
                id="All"
                checked={openForAll}
                onChange={handleOpenForAllChange}
              />
              <label htmlFor="All" className={styels.cursorPointer}>
                Open for all
              </label>
              {["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"].map(
                (label) => (
                  <React.Fragment key={label}>
                    <input
                      type="checkbox"
                      id={label}
                      {...register(label)}
                      disabled={openForAll}
                      checked={openForAll ? false : watch(label)}
                      defaultChecked={projectData.employeeLevels.includes(
                        label
                      )}
                    />
                    <label
                      htmlFor={label}
                      className={
                        openForAll
                          ? styels.checkboxDisabled
                          : styels.cursorPointer
                      }
                    >
                      {label}
                    </label>
                  </React.Fragment>
                )
              )}
            </div>
            <div className="mt-4">
              <label className={styels.titleText} >
                Add required Skills
              </label>
              <Controller
                name="requiredSkills"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Skills is required' : false, }}
                defaultValue={requiredSkills}
                render={({ field }) => (
                  <CreatableSelect
                    isMulti
                    name="skills"
                    options={skillImproveLearn}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(values) => {
                      field.onChange(values);
                      setRequiredSkills(values);
                    }}
                    onCreateOption={(inputValue) => {
                      const newValue = { label: inputValue, value: inputValue };
                      setSkillImproveLearn((prev) => [...prev, newValue]);
                      setValue('requiredSkills', [...requiredSkills, newValue], { shouldValidate: true });
                      setRequiredSkills((prev) => [...prev, newValue]);
                      postSkill(inputValue)
                    }}
                    value={requiredSkills}
                    defaultValue={requiredSkills}
                    placeholder="Select skills"
                  />
                )}
              />
            </div>
            {errors.requiredSkills && <p style={{ color: "red" }}>{errors.requiredSkills.message}</p>}
            <div className="mt-4">
              <label className={styels.titleText}>
                Add Skills to improve/ learn
              </label>
              <Controller
                name="improveLearnSkills"
                control={control}
                defaultValue={improvedSkills}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Skills is required' : false, }}
                render={({ field }) => (
                  <CreatableSelect
                    isMulti
                    name="improveSkills"
                    options={skillImproveLearn}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(values) => {
                      field.onChange(values);
                      setImprovedSkills(values);
                    }}
                    onCreateOption={(inputValue) => {
                      const newValue = { label: inputValue, value: inputValue };
                      setSkillImproveLearn((prev) => [...prev, newValue]);
                      setValue('requiredSkills', [...requiredSkills, newValue], { shouldValidate: true });
                      setImprovedSkills((prev) => [...prev, newValue]);
                      postSkill(inputValue)
                    }}
                    value={field.value}
                    // defaultValue={defaultImprovedSkills}
                    placeholder="Select skills"
                  />
                )}
              />
            </div>
            {errors.improveLearnSkills && <p style={{ color: "red" }}>{errors.improveLearnSkills.message}</p>}
            <div className="mt-4">
              <label className={styels.titleText} htmlFor="keynote">
                Key deliverables
              </label>
              <Controller
                name="keyDeliverables"
                control={control}
                defaultValue={keynote || projectData.keyDeliverables}
                rules={{
                  required: saveType !== 'trash' && saveType !== 'draft' ? 'Key deliverables is required' : false,
                  validate: saveType !== 'trash' && saveType !== 'draft' ? validateDescription : undefined,
                }}
                render={({ field }) => (
                  <ReactQuill
                    style={{ height: "70px" }}
                    theme="snow"
                    id="keynote"
                    placeholder="Write the key deliverables by separating them through comma."
                    onChange={(value) => {
                      field.onChange(value);
                      setKeynote(value);
                    }}
                    defaultValue={projectData.keyDeliverables}
                  />
                )}
              />
            </div>
            {errors.keyDeliverables && <p style={{ color: "red" }}>{errors.keyDeliverables.message}</p>}
            <div className={styels.expDiv}>
              <label className={styels.titleText}>
                Weekly time commitment required
              </label>
              <select
                {...register("weeklyTime", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select weekly time commitment required' : false, })}
                style={{ marginLeft: "20px" }}
                defaultValue={
                  projectData.weeklyTime && projectData.weeklyTime

                }
              >
                <option value="" hidden="hidden">Hours per week</option>
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
                <option value={10}>10</option>
                {/* <option value={11}>11</option>
                <option value={12}>12</option>
                <option value={13}>13</option>
                <option value={14}>14</option> */}
              </select>
              <p style={{ color: "red" }}>{errors.weeklyTime && errors.weeklyTime.message}</p>
            </div>
            <div className={styels.skillsDiv}>
              <label
                className={styels.titleText}
                style={{ marginRight: "20px" }}
                htmlFor="keynote"
              >
                Start Date and End Date
              </label>
              <Controller
                name="dateRange"
                control={control}
                defaultValue={dateRange}
                render={({ field }) => (
                  <DateRangePicker
                    startDate={
                      dateRange.startDate
                        ? dateRange.startDate
                        : field.value.startDate
                    }
                    startDateId="startDate"
                    endDate={
                      dateRange.endDate
                        ? dateRange.endDate
                        : field.value.endDate
                    }
                    endDateId="endDate"
                    onDatesChange={({ startDate, endDate }) => {
                      // Reset the end date when the start date changes
                      if (!moment(startDate).isSame(field.value.startDate, 'day')) {
                        endDate = null;
                        setFocusedInput('endDate'); // Move focus to the end date
                      }
                      field.onChange({ startDate, endDate });
                      setDateRange({ startDate, endDate });
                    }}
                    focusedInput={focusedInput}
                    onFocusChange={(focused) => setFocusedInput(focused)}
                    displayFormat="DD/MM/YYYY"
                    isOutsideRange={(day) => {
                      const today = moment();
                      const june3_2024 = moment('2024-06-26');
                      const start = moment(field.value.startDate);

                      // If today is before May 15, 2024, enforce start date selection from May 15, 2024
                      if (today.isBefore(june3_2024, 'day') && focusedInput === 'startDate') {
                        return day.isBefore(june3_2024, 'day');
                      }

                      // Once a start date is selected, restrict the end date to no more than 6 months later
                      if (focusedInput === 'endDate' && field.value.startDate) {
                        const maxEndDate = start.clone().add(6, 'months');
                        // Allow dates up to 6 months after the start date, disallowing past dates
                        return day.isAfter(maxEndDate) || day.isBefore(start);
                      }

                      // For start date selection, disallow past dates if today is on or after May 15, 2024
                      if (focusedInput === 'startDate') {
                        return day.isBefore(today, 'day');
                      }

                      // This line should not be reachable but is here as a fallback to disallow past dates
                      return false; // You might adjust this fallback based on your specific needs
                    }}
                  />
                )}
              />
            </div>
            <div className={styels.checkDiv}>
              <p className={styels.titleText}>Type of project</p>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select type of project' : false, })}
                id="remote"
                type="radio"
                name="typeOfRequirement"
                value="Remote"
                defaultChecked={projectData.typeOfRequirement === "Remote"}
              />
              <label htmlFor="remote">Remote</label>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select type of project' : false, })}
                id="wfo"
                type="radio"
                name="typeOfRequirement"
                value="Work From Office"
                defaultChecked={projectData.typeOfRequirement === "Work From Office"}
              />
              <label htmlFor="wfo">Work From Office</label>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select type of project' : false, })}
                id="hybrid"
                type="radio"
                name="typeOfRequirement"
                value="Hybrid"
                defaultChecked={projectData.typeOfRequirement === "Hybrid"}
              />
              <label htmlFor="hybrid">Hybrid</label>
            </div>
            {errors.typeOfRequirement && <p style={{ color: "red" }}>{errors.typeOfRequirement.message}</p>}
            <div className={styels.skillsDiv}>
              <label
                className={styels.titleText}
                style={{ marginRight: "20px" }}
              >
                Last date to apply
              </label>
              <Controller
                name="lastDate"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select last date to apply' : false, }}
                defaultValue={projectData.lastDate ? moment(projectData.lastDate) : null}
                render={({ field }) => (
                  <SingleDatePicker
                    date={field.value}
                    onDateChange={(date) => {
                      field.onChange(date);
                      setLastDate(date);
                    }}
                    focused={focusedLastDate}
                    onFocusChange={({ focused }) => {
                      setFocusedLastDate(focused);
                    }}
                    id="lastDate"
                    numberOfMonths={1}
                    placeholder="Last Date"
                    displayFormat="DD/MM/YYYY"
                    isOutsideRange={(day) => {
                      const today = moment();
                      const june3_2024 = moment('2024-06-19');
                      return day.isBefore(today, 'day') || day.isBefore(june3_2024, 'day');
                    }}
                  />
                )}
              />
            </div>
            {errors.lastDate && <p style={{ color: "red" }}>{errors.lastDate.message}</p>}
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Additional Details
              </p>
              <p className={styels.instructionText}>
                Upload details of what this project would entail to offer applicants a better idea of the project before applying. Supported formats: audio (mp3), video (mp4) or documents (pdf)
              </p>
              <div className={styels.uploaddiv}>
                <Controller
                  name="instructions"
                  control={control}
                  defaultValue={projectData.instructions}
                  render={({ field: { onChange, value } }) => (
                    <input
                      ref={mediaRef}
                      type="file"
                      accept=".mp4, .avi, .mov, .mp3, .wav, .ogg, .pdf"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const selectedFiles = e.target.files;
                        const videoFiles = Array.from(selectedFiles).filter(
                          (file) => file.type.includes("video")
                        );
                        const documentFiles = Array.from(selectedFiles).filter(
                          (file) =>
                            file.type.includes("application/pdf") ||
                            file.name.endsWith(".doc") ||
                            file.name.endsWith(".docx")
                        );
                        if (videoFiles.length > 0) {
                          const newFiles = [
                            videoFiles[0],
                            ...documentFiles.slice(0, 2),
                          ].filter(Boolean);
                          setInstructionFiles(newFiles);
                          onChange(newFiles);
                        } else {
                          const newFiles = [
                            ...documentFiles.slice(0, 3),
                          ].filter(Boolean);
                          setInstructionFiles(newFiles);
                          onChange(newFiles);
                        }
                      }}
                      multiple
                    />
                  )}
                />
                <div
                  className={styels.uploadbtn}
                  onClick={() => mediaRef.current.click()}
                >
                  <img
                    className={styels.uploadIcon}
                    src={uploadIcon}
                    alt="uploadIcon"
                  />
                  <p>Upload media</p>
                </div>
                {instructionFiles.length ||
                  projectData.instructions.length ? (
                  <p className={styels.fileName}>Selected Files:</p>
                ) : null}
                <div className={styels.uploadedFilesDiv}>
                  {instructionFiles.length
                    ? instructionFiles.map((file, index) => (
                      <span style={{ position: "relative" }} key={index}>
                        {file.type.includes("video") ? (
                          <video
                            className={styels.additionalDetailsVideo}
                            controls
                          >
                            <source
                              src={URL.createObjectURL(file)}
                              type={file.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : file.type.includes("application/pdf") ? (
                          <iframe
                            className={styels.additionalDetailsPdf}
                            title={`PDF Preview ${index}`}
                            src={URL.createObjectURL(file)}
                            width="500"
                          />
                        ) : null}
                        <button type="button" onClick={() => removeFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                          <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                        </button>
                      </span>
                    ))
                    : projectData.instructions.length
                      ? projectData.instructions.map((file, index) => {
                        const fileExtension = file
                          ?.split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          <span style={{ position: "relative" }} key={index}>
                            {fileExtension === "mp4" ? (
                              <ReactPlayer
                                controls={true}
                                url={signedUrls[file]}
                                className={styels.additionalDetailsVideo}
                              />
                            ) : fileExtension === "pdf" ? (
                              <iframe
                                className={styels.additionalDetailsPdf}
                                title={`PDF Preview ${index}`}
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  signedUrls[file]
                                )}&embedded=true`}
                              />
                            ) : null}
                            <button type="button" onClick={() => deleteUploadedFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                              <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                            </button>
                          </span>
                        );
                      })
                      : null}
                </div>
              </div>
            </div>
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Add Thumbnail
              </p>
              <div className={styels.uploaddiv}>
                <div className={styels.sliderDiv}>
                  <Slider  {...settings}>
                    {
                      thumbnails.map((thumb) => (
                        <div onClick={() => handleSelectThumbnail(thumb)} className={styels.imageContainer} key={thumb.key} data-name={thumb.name}>
                          <img src={thumb.path} key={thumb.key} alt={thumb.name} className={`${styels.thumbOption} ${thumb.key == localThumbnailIndex ? styels.selectedThumb : ""}`} />
                          {/* <p className={styels.thumbText}>{thumb.name}</p> */}
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </div>
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Add Cover Image
              </p>
              <div className={styels.uploaddiv}>
                <div className={styels.sliderDiv}>
                  <Slider  {...coverSettings}>
                    {
                      banners.map((thumb) => (
                        <div onClick={() => handleSelectCover(thumb)} className={styels.imageContainer} key={thumb.key} data-name={thumb.name}>
                          <img src={thumb.path} key={thumb.key} alt={thumb.name} className={`${styels.coverOption} ${thumb.key == localCoverIndex ? styels.selectedCover : ""}`} />
                          {/* <p className={styels.coverText}>{thumb.name}</p> */}
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </div>
            <div className={styels.submitDiv}>
              <button
                type="submit"
                onClick={() => setSaveType("trash")}
                className={styels.delBtn}
                disabled={isDisabled}
              >
                <img src={redDelete} alt="redDelete" />
              </button>
              <button
                type="submit"
                onClick={() => setSaveType("draft")}
                className={styels.saveBtn}
                disabled={isDisabled}
              >
                Save to draft
              </button>
              <button
                type="submit"
                onClick={() => setSaveType(["1A", 0, 1, 2, 3, 4, 5].includes(window.localStorage.getItem("userLevel")) ? "open" : "pending")}
                className={styels.subBtn}
                disabled={isDisabled}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditDraft;
