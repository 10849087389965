import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styels from "./Profile.module.css";
import exemplaryExpert from "../../assets/ExemplaryExpert.png";
import noviceAchiever from "../../assets/NoviceAchiever.png";
import skillfulPerformer from "../../assets/SkillfulPerformer.png";
import proficientContributor from "../../assets/ProficientContributor.png";
import outstandingAchiever from "../../assets/OutstandingAchiever.png";
import masterCollaborator from "../../assets/MasterCollaborator.png";
import distinguishedProfessional from "../../assets/DistinguishedProfessional.png";
import elitePerformer from "../../assets/ElitePerformer.png";
import exceptionalLeader from "../../assets/ExceptionalLeader.png";
import legendaryAchiever from "../../assets/LegendaryAchiever.png";
import starImg from "../../assets/start.png";
import emptyStar from "../../assets/emptyStart.png";
import personImage from "../../assets/person.png";
import {
  getUserProfilePhotoData,
  updateAboutMe,
  getUserDetails,
} from "../../services";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "33px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "80%",
    },
  },
}));

const ProfileImage = (props) => {
  const [open, setOpen] = React.useState(false);
  const [profileData, setProfileData] = useState();
  const [userProfilePhoto, setUserProfilePhoto] = useState(personImage);
  const [msg, setMsg] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const maxCharacters = 100;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacters) {
      setMsg(inputValue);
    }
  };

  const handleAboutMe = async () => {
    const responseData = {
      description: msg,
      email: window.localStorage.getItem("userEmail"),
    };
    await updateAboutMe(responseData);
  };

  const getAboutMe = async () => {
    const res = await getUserDetails(window.localStorage.getItem("userEmail"));
    if (res.data.status === "200") {
      setMsg(res.data.data[0].description);
    }
  };

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserProfilePhoto = async (userId) => {
    const responseData = await getUserProfilePhotoData(userId);
    window.localStorage.setItem(
      "userProfilePhoto",
      responseData.data.profileData.profilePhoto
    );
    setUserProfilePhoto(
      responseData.data.profileData.profilePhoto
        ? `data:image/jpeg;base64,${responseData.data.profileData.profilePhoto}`
        : personImage
    );
  };

  const handleProfileData = async () => {
    const responseData = {
      department: window.localStorage.getItem("userDepartment"),
      experience: window.localStorage.getItem("userExperience"),
      skills: window.localStorage.getItem("userSkills")?.split(","),
      phoneNumber: window.localStorage.getItem("userPhoneNumber"),
    };
    setProfileData(responseData);
  };

  useEffect(() => {
    getUserProfilePhoto(window.localStorage.getItem("userId"));
    handleProfileData();
    getAboutMe();
  }, []);


  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={styels.headdiv}>
        <h1>Badges</h1>
        <p>
          Here you’ll be able to see complete information about the badge
          system.{" "}
        </p>
      </div>
      <div className={styels.badgesdiv}>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={noviceAchiever}
              alt="Novice Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Novice Achiever</h3>
              <p>
                Earned after completing the first streak of a project
                successfully.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={skillfulPerformer}
              alt="Skillful Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Skillful Performer</h3>
              <p>Achieved after completing two streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              src={proficientContributor}
              alt="Proficient Contributor Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Proficient Contributor</h3>
              <p> Earned after completing three streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={outstandingAchiever} alt="Outstanding Achiever Bagde" />
            <div className={styels.badgetext}>
              <h3>Outstanding Achiever</h3>
              <p>Achieved after completing four streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={masterCollaborator} alt="Master Collaborator Bagde" />
            <div className={styels.badgetext}>
              <h3>Master Collaborator</h3>
              <p>Earned after completing five streaks of 5 projects each.</p>
            </div>
          </div>
        </div>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exemplaryExpert}
              alt="Exemplary Expert Bagde"
            />
            <div className={styels.badgetext}>
              <h3>ExemplaryExpert</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 5 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={distinguishedProfessional}
              alt="Distinguished Professional Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Distinguished Professional</h3>
              <p>
                {" "}
                Earned after receiving positive feedback from project managers
                on 10 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={elitePerformer}
              alt="Elite Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Elite Performer</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 15 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exceptionalLeader}
              alt="Exceptional Leader Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Exceptional Leader</h3>
              <p>
                Earned after receiving positive feedback from project managers
                on 20 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={legendaryAchiever}
              alt="Legendary Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Legendary Achiever</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 25 different projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Profile-page">


      <div className={styels.subSection}>
        <div className={styels.profileSection}>
          <div className="Profilediv">
            {/* <img
                  src={userProfilePhoto}
                  alt="User Profile Photo"
                  width="247px"
                  height="255px"
                /> */}
            <div
              className={`${styels.profile} ${styels.profileImage}`}
              style={{
                backgroundImage: `url(${userProfilePhoto})`,
              }}
            >
              {/* <img
                onClick={handleOpen}
                className={`${styels.topBadge} ${styels.topBadgeMobile}`}
                src={exemplaryExpert}
                alt="Exemplary Expert Badge"
              />
              <img
                onClick={handleOpen}
                className={`${styels.bottomBadge} ${styels.bottomBadgeMobile}`}
                src={noviceAchiever}
                alt="Exemplary Expert Badge"
              /> */}
            </div>
          </div>
          {/* <div className={styels.badgesTitle}>
                <button>Exemplary Expert </button>
                <button>Novice Achiever</button>
              </div> */}

        </div>

      </div>


      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default ProfileImage;
