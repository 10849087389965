import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Redirect, useHistory } from "react-router-dom";
import Loader from "react-loader";
// import { receiveAccessToken, receiveIdToken } from "../actions/tokenActions";
import { receiveProfileData } from "../actions/profileActions";
import {
  getUserData,
  getUserExperienceData,
  getUserPhoneNumberData,
  getUserSkillsData,
  getUserEmployeeLevelData,
  getManagerData,
  getDivisionData,
  getFunctionData,
  addUser,
  getToken,
  getResource
} from "../services";
import Cookies from 'js-cookie';

const AuthPage = ({ location, profile, receiveProfile, receiveTokens }) => {
  let search = window.location.search;
  const navigate = useHistory();
  const [dataLoaded, setDataLoaded] = useState(true);
  const [userToken, setUserToken] = useState(null);
  const [verified, setVerified] = useState(null);
  const [empLevel, setEmpLevel] = useState(null);

  const getResourceData = async (role) => {
    const result = await getResource();
    if (result.data.status === "200" && result.data.resource) {
      const findAllow = result.data.resource.find(item => JSON.parse(item.allow)?.value === role || JSON.parse(item.allow)?.value === "both");
      if (findAllow) {
        window.localStorage.setItem("resourceAllow", true);
      } else {
        window.localStorage.setItem("resourceAllow", false);
      }
    }
  };

  const getManagerDetails = async (managerId) => {
    const result = await getManagerData(managerId);
    window.localStorage.setItem(
      "managerName",
      result.data.managerData ? result.data.managerData.firstName : null
    );
    window.localStorage.setItem(
      "managerEmail",
      result.data.managerData ? result.data.managerData.email : null
    );
  };

  const getUserDetails = async (email) => {
    const responseData = await getUserData(email);
    if (responseData.data.status === "200") {
      window.localStorage.setItem("userId", responseData.data.profileData.userId
        ? responseData.data.profileData.userId
        : null
      );
      // window.localStorage.setItem("userName", responseData.data.profileData.name
      //   ? responseData.data.profileData.name
      //   : null);
      window.localStorage.setItem("userDepartment", responseData.data.profileData.department
        ? responseData.data.profileData.department.split(" (")[0]
        : null);
      window.localStorage.setItem("userDivision", responseData.data.profileData.division
        ? responseData.data.profileData.division.split(" (")[0]
        : null);
      window.localStorage.setItem("userOrgUnit", responseData.data.profileData.orgUnit
        ? responseData.data.profileData.orgUnit.split(" (")[0]
        : null);
      window.localStorage.setItem("userTitle", responseData.data.profileData.title
        ? responseData.data.profileData.title.split(" (")[0]
        : null);

      return responseData.data.profileData;
    }
  };

  const getUserExperienceDepartment = async (userId) => {
    const responseData = await getUserExperienceData(userId);
    window.localStorage.setItem(
      "userExperience",
      responseData.data.profileData
        ? responseData.data.profileData.experience
        : null
    );
    // window.localStorage.setItem(
    //   "userDepartment",
    //   responseData.data.profileData
    //     ? responseData.data.profileData.department
    //     : null
    // );
  };

  const getUserPhoneNumber = async (userId) => {
    const responseData = await getUserPhoneNumberData(userId);
    window.localStorage.setItem(
      "userPhoneNumber",
      responseData.data.profileData
        ? responseData.data.profileData.phoneNumber
        : null
    );
  };

  const getUserSkills = async (userId) => {
    const responseData = await getUserSkillsData(userId);
    window.localStorage.setItem(
      "userSkills",
      responseData.data.profileData.skills.length
        ? responseData.data.profileData.skills
        : null
    );
  };

  const getUserEmployeeLevel = async (userId) => {
    const responseData = await getUserEmployeeLevelData(userId);
    if (responseData.data.status === "200") {
      window.localStorage.setItem("userSbu", responseData.data.profileData.sbu
        ? responseData.data.profileData.sbu
        : null);
      await getManagerDetails(responseData.data.profileData.managerId);
      return responseData.data.profileData.employeeLevel;
    }
  };

  const getUserDivision = async (userId) => {
    const responseData = await getDivisionData(userId);
    if (responseData.data.status === "200") {
      window.localStorage.setItem(
        "userDivision",
        responseData.data.division
      )
    }
  };

  const getUserFunction = async (userId) => {
    const responseData = await getFunctionData(userId);
    if (responseData.data.status === "200") {
      window.localStorage.setItem(
        "userFunction",
        responseData.data.function
      )
    }
  };

  const addUserData = async (data) => {
    const responseData = await addUser(data);
    if (responseData.data.status === "200") {
      return responseData;
    }
  };

  function parseJwt(token) {
    var base64Url = token?.split(".")[1];
    var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
    var jsonPayload = decodeURIComponent(
      window
        .atob(base64)?.split("")
        .map(function (c) {
          return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join("")
    );
    return JSON.parse(jsonPayload);
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        let params = new URLSearchParams(window.location.search);
        let token = Cookies.get('token');
        getToken(token);
        if (token) {
          const decodedJwt = parseJwt(token);
          window.localStorage.setItem("userEmail", decodedJwt.user.email);
          window.localStorage.setItem("userName", decodedJwt.user.name);
          window.localStorage.setItem("logId", decodedJwt.logId);
          const userData = await getUserDetails(decodedJwt.user.nameID);
          const level = await getUserEmployeeLevel(userData?.userId);
          window.localStorage.setItem("userLevel", level);
          setEmpLevel(level);
          setUserToken(token);
          await getResourceData(decodedJwt.userRole)
          if (level) {
            navigate.push("/home");
          }
          await getUserExperienceDepartment(userData?.userId);
          await getUserPhoneNumber(userData?.userId);
          await getUserSkills(userData?.userId);
          await getUserDivision(userData?.userId);
          await getUserFunction(userData?.userId);
          addUserData({
            name: userData?.name,
            email: decodedJwt.user.nameID,
            userId: userData?.userId,
          });
          setDataLoaded(true);
        }
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };
    fetchData();
  }, [navigate]);

  useEffect(() => {
    if (Cookies.get('token') !== null) {
      if (userToken === null && empLevel === null) {
        setUserToken(Cookies.get('token'));
        setEmpLevel(window.localStorage.getItem("userLevel"));
        const lToken = Cookies.get('token');
        const decodedJwt = parseJwt(lToken);
        if ((decodedJwt.exp + 12 * 60 * 60) * 1000 <= Date.now()) {
          navigate.push("/");
          window.location.reload();
        }
      }
      if (userToken) {
        setVerified(parseJwt(userToken).isUserVerified);
        receiveProfile(parseJwt(userToken).user);
      }
    } else {
      navigate.push("/");
      window.location.reload();
    }
  }, [userToken, empLevel]);

  const currentUrl = window.location.pathname;

  if (!userToken || dataLoaded) {
    return <Loader />;
  } else if (
    Cookies.get('token') &&
    window.localStorage.getItem("userLevel")
  ) {
    return (
      <Redirect
        to="/home"
      />
    );
  } else {
    return true;
  }
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  // state: state.token.state,
  // nonce: state.token.nonce
});

const mapDispatchToProps = (dispatch) => ({
  receiveProfile: (data) => dispatch(receiveProfileData(data)),
  // receiveTokens: (accessToken, idToken) => {
  //   dispatch(receiveAccessToken(accessToken));
  // dispatch(receiveIdToken(idToken));
  // }
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthPage);
