import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import { toast } from "react-toastify";
import styels from "./Trash.module.css";
import {
  getAdminProjectsList,
  deletePermanently,
  getRestore,
  getAdminFilterProject,
} from "../../services/index";
import image from "../../assets/titanlogo.png";
import { dateFormat, openUrl } from "../../util/commenFunctions";
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import { thumbnails } from "../common/Images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from "../user/ProjectDetails.module.css";
import exportIcon from "../../assets/export.png";
import bgImage from '../../assets/BackGround/bg2web.png';

const Trash = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [trashs, setTrashs] = useState([]);

  const getTrashList = async () => {
    const searchData = {
      status: "trash",
    };
    const res = await getAdminProjectsList(searchData);
    if (res.data.status === "200") {
      setTrashs(res.data.projects);
    }
  };

  const handleDeletePermanently = async (id) => {
    const requestData = {
      id,
    }
    const res = await deletePermanently(requestData);
    if (res.data.status === "200") {
      const projectData = trashs.filter((item) => item._id !== id);
      setTrashs(projectData);
      toast.success(`Project deleted successfully`);
    }
  };

  const handleRestore = async (id) => {
    const res = await getRestore(id);
    if (res.data.status === "200") {
      const projectData = trashs.filter((item) => item._id !== id);
      setTrashs(projectData);
      toast.success(`Project moved to draft successfully`);
    }
  };

  const filterProjects = async () => {
    const requestData = {
      searchText,
      employeeType,
      projectType,
      projectStatus,
    };
    const res = await getAdminFilterProject(requestData);
    if (res.data.status === "200") {
      setTrashs(res.data.trashResult);
    }
  };

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getTrashList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getTrashList();
    }
  }, []);

  return (
    <div>
      <div className="Profile-page">
        <Navbar />
        <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
          <div className={styels.subdiv}>
            <div className={styels.backdiv}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/admin/dashboard"
              >
                <AiOutlineLeft style={{ fontSize: "32px" }} />
              </NavLink>
              <h1 className={styels.head}>Trash</h1>
            </div>
            <p className={styels.subHead}>
              Items in trash will be permanently deleted in 60 days.
            </p>
            <div style={{ padding: "0px 30px" }}>
              <Filter />
            </div>
            <div className={styels.TrashList}>
              {trashs.map((trash, index) => {
                const finalProject = { ...trash, index };
                return (
                  <div className={styels.trash} key={index}>
                    <div className={styels.trashtHeader}>
                      <div className={styels.thumbdiv}>
                        <img
                          src={
                            trash.thumbnail === "" ||
                              trash.thumbnail === "undefined"
                              ? thumbnails[trash.localThumbnail]?.path || image
                              : trash.thumbnail
                          }
                          alt="thumbnail"
                          className={styels.thumbnail}
                        />
                      </div>
                      <div className={styels.trashTitle}>
                        <NavLink
                          key={index}
                          style={{ textDecoration: "none", color: "black" }}
                          to={{
                            pathname: "/admin/project-details",
                            state: { project: finalProject },
                          }}
                        >
                          <h1>{trash.title}</h1>
                        </NavLink>
                        <div className={styles.sponsor}>
                          <p>{JSON.parse(trash.createdBy).name}</p>
                          {/* <Tippy content="View Sponsor Profile" placement="bottom">
                            <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${trash.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={styles.link} />
                          </Tippy> */}
                        </div>
                        <h2>Created on: {dateFormat(trash.createdAt)}</h2>
                        <h2>
                          Reason of deletion:{" "}
                          <span>{trash.deleteReason}</span>
                        </h2>
                      </div>
                    </div>
                    <div>
                      <div>
                        <button
                          className={styels.deletebtn}
                          onClick={() => handleDeletePermanently(trash._id)}
                        >
                          Delete permanently
                        </button>
                        <button
                          className={styels.restorebtn}
                          onClick={() => handleRestore(trash._id)}
                        >
                          Restore
                        </button>
                        <p className={styels.warnmsg}>
                          {trash.leftDays} days left before automatic permanent
                          deletion
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trash;
