import React from "react";
import { Link, NavLink } from "react-router-dom";
import "../css/Header.css";

if (!localStorage.getItem('requestUrl')) {
  const currentUrl = window.location.href;
  localStorage.setItem('requestUrl', currentUrl);
}

const Header = ({ loggedIn, logout }) => {
  return (
    <div className="App-header">
      <div className="Header-links">
        {loggedIn ? <LoggedInNav logout={logout} /> : <LoggedOutNav />}
      </div>
    </div>
  )
}

const LoggedInNav = ({ logout }) => (
  <>
    <NavLink to="/profile">Profile</NavLink>
    <Link to="/" onClick={logout}>
      Logout
    </Link>
  </>
);



const LoggedOutNav = () => (
  <a href={`${process.env.REACT_APP_HOST_BACKEND}/login`}>
    Continue to Sign In
  </a>
);

export default Header;
