import React, { useEffect, useState } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { NavLink, useHistory } from "react-router-dom";
import ReactPlayer from "react-player";
import styles from "../user/ProjectDetails.module.css";
import Navbar from "../common/Navbar";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import {
  getApplications,
  deleteTemporary,
  shortListProject,
  deleteApplication,
  sendMessage,
  projectUpdateProgress,
  projectMarkAsComplete,
  getProjectDetails,
  updateProjectStatus,
  updateNotShortlisted,
  updateNotSelected,
} from "../../services/index";
import { dateFormat, calculateMonthWeekDifference, calculateDayDifference, countSkillMatches, openUrl } from "../../util/commenFunctions";
import ProfileImage from "../common/ProfileImage";
import ProgressBar from "../common/ProgressBar";
import StarRatings from "react-star-ratings";
import styleClasses from "./ProjectDetails.module.css";
import deleteIcon from "../../assets/deleteRed.png";
import starIcon from "../../assets/recomendstar.png";
import editIcon from "../../assets/Edit.png";
import betterIcon from "../../assets/better.png";
import downloadIcon from "../../assets/download.png";
import viewApplicationsIcon from "../../assets/viewapplications.png";
import download from "../../assets/filedownload.png";
import star from "../../assets/start.png"
import emptyStart from "../../assets/emptyStart.png"
import personImage from "../../assets/person.png"
import LinkIcon from '../../assets/export.png'
import bgImage from '../../assets/BackGround/bg3web.png';
import Slider from '@material-ui/core/Slider';
import { banners } from "../common/Images";
import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Shortlisted from "./Shortlisted";




function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "45px 105px",
    width: "1185px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px 10px",
    },
  },
  reviewpaper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "40px 70px",
    width: "1521px",
    [theme.breakpoints.down("lg")]: {
      width: "90%",
      height: "95%",
      padding: "25px 30px",
    },
    [theme.breakpoints.down("md")]: {
      width: "90%",
      height: "95%",
    },
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "80%",
      padding: "20px 10px",
    },
  },
}));

const ProgressSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);

const ProjectDetails = (props) => {
  const [showSkills, setShowSkills] = useState(false);
  const [showImproveSkills, setShowImproveSkills] = useState(false);

  const notSelectedmsg = `
Dear [Applicant Name],

Thank you for applying to the project “[Project Title]” and for participating in its selection procedure. I commend your commitment to contribute, learn & grow in your career with Titan through Bridge Projects.

I wanted to personally reach out to you to inform you that after careful consideration and evaluation, it's been decided not to go ahead with your candidature for this project for the following reason:
[Reason]

I encourage you to look for other projects published on the platform [Platform Link] and apply for the ones that match for your skillset and area of interest. 

For any concern or queries, feel free to write to me or the Bridge Projects Team @DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net.

Regards,
Team Titan Career Vista
`;

  const notShortlistedmsg = `
Dear [Applicant Name],

Thank you for your interest in the project “[Project Title]”.

After careful consideration, I have decided not to go ahead with your candidature for this project for the following reason:
[Reason]

I appreciate your commitment to contribute, learn & grow in your career with Bridge Projects. You are encouraged to look for other projects published on the platform [Platform Link] and apply for the ones that match for your skillset and area of interest.  

For any concern or queries, feel free to write to me or the Bridge Projects Team @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a>.

Regards,
Team Titan Career Vista
`;

  const history = useHistory();
  // const projectData =
  //   props.location.state?.project ||
  //   JSON.parse(window.localStorage.getItem("projectData"));
  const [buttonShow, setButtonShow] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [openModal, setOpenModal] = React.useState(false);
  const [applyData, setApplyData] = React.useState([]);
  const [notSelectedData, setNotSelectedData] = React.useState([]);
  const [notAssignedData, setNotAssignedData] = React.useState([]);
  const [msg, setMsg] = React.useState("");
  const [allowEdit, setAllowEdit] = React.useState(false);
  const [message, setMessage] = React.useState(notShortlistedmsg);
  const [openSendTab, setOpenSendTab] = React.useState(false);
  const [openRating, setOpenRating] = React.useState(false);
  const [ratingStar, setRatingStar] = React.useState(0);
  const [openProgress, setOpenProgress] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState("");



  const [openDes, setOpenDes] = React.useState(false);
  const [openKeyDeliverables, setOpenKeyDeliverables] = React.useState(false);

  const [isFullTextDisplayed, setIsFullTextDisplayed] = useState(false);
  const [isFullKDelTextDisplayed, setIsFullKDelTextDisplayed] = useState(false);
  const [projectData, setProjectData] = useState();


  const description = projectData?.description;
  const shouldTruncate = description && description?.length > 400;
  const truncatedText = extractTextFromHtml(description)?.substring(0, 400) + "<strong>...Read More</strong>";;

  const keyDeliverables = projectData?.keyDeliverables
  const shouldTruncateDeliverables = keyDeliverables && keyDeliverables?.length > 300;
  const truncatedTextDeliverables = extractTextFromHtml(keyDeliverables)?.substring(0, 300) + "<strong>...Read More</strong>";;

  const [progress, setProgress] = React.useState(0);
  const [progressStatus, setProgressStatus] = React.useState("");
  const [feedback, setFeedback] = React.useState("");

  const lastEndDateTimestamp = projectData?.endDate[projectData?.endDate?.length - 1];
  const lastEndDateMoment = moment(Number(lastEndDateTimestamp));

  const [date, setDate] = useState(lastEndDateMoment);
  const [focused, setFocused] = useState(false);
  const [activeTab, setActiveTab] = useState(1);
  const [shortlistedCount, setShortlistedCount] = useState(0);
  const [assignedList, setAssignedList] = useState([]);
  const [notShortlistedFeedback, setNotShortlistedFeedback] = useState([]);
  const [notSelectedFeedback, setNotSelectedFeedback] = useState([]);
  const [refreshShortlist, setRefreshShortlist] = useState(false);

  function textToHtml(text) {
    return text.replace(/\n/g, '<br>');
  }

  const shortListedApplicantMesg = `
<p>Dear [Title Name],</p>
 
<p>We are pleased to inform you that you have been shortlisted for the project "[Project Title]" by [Sponsor Name].</p>
 
<p>[Sponsor Name] will be in touch with you on next steps in the selection process. Key details about the project, including timelines and specific requirements, are available on <a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. Kindly review it thoroughly as you prepare for the next steps in the selection process.</p>
 
<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a>. Congratulations on being shortlisted and here’s wishing you the very best.</p>
 
<p>
Regards,<br/>
Team Titan Career Vista
</p>
`;

  const shortListedManagerMesg = `
<p>Dear [Title Name],</p>
 
<p>Hope this email finds you well.</p>
<p>Your team member [Applicant Name] has been shortlisted for the Bridge Project: "[Project ID]: [Project Title]", by [Sponsor Name].</p>
 
<p>The next steps in this process would be as follows:</p>
<p>Interview > Selection > Assignment > Project execution > Project completion</p>
<p>We will keep you informed as & when [Applicant Name] progresses in this process.</p>

<p>We encourage you to continue to support [Applicant Name] on this journey of development.</p>

<p>For your reference, the details of the project are shared in the table below:</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>
 
<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>
 
<p>
Regards,<br/>
Team Titan Career Vista
</p>
`;

  const handleDateChange = (newDate) => {
    setDate(newDate);
  };

  const isOutsideRange = (day) => {
    return day.isBefore(lastEndDateMoment, 'day') || day.isAfter(lastEndDateMoment.clone().add(1, 'month'), 'day');
  };

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setMsg(inputValue);
  };

  function extractTextFromHtml(htmlString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = htmlString;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const handleOpen = (recommendation) => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setOpenSendTab(false);
  };

  const skills = projectData?.requiredSkills?.map((each) => JSON.parse(each));
  const improvedSkills = projectData?.improveLearnSkills?.map((each) =>
    JSON.parse(each)
  );

  const handleApplyList = async () => {
    const res = await getApplications(projectData?._id);
    if (res.data.status === "200") {
      const applied = res.data?.applications[0]?.applicants?.filter(
        (item) => item.status === "applied") || [];
      const shortlisted = res.data.applications[0]?.applicants.filter(
        (item) => item.status === "shortlisted") || [];
      const assigned = res.data.applications[0]?.applicants.filter(
        (item) => item.status === "assigned") || [];
      if (assigned.length == projectData?.openPositions) {
        for (const user of applied) {
          user.alreadyAssigned = true;
        }
      }
      setApplyData(res.data.applications[0]?.applicants);
      setNotSelectedData(applied);
      setAssignedList(assigned);
      setShortlistedCount(shortlisted?.length);
      setNotAssignedData(shortlisted);
    }
  };

  const handleShortList = async (item) => {
    if (parseInt(projectData?.openPositions) === assignedList?.length) {
      toast.error(`All open positions have been filled; further shortlisting disabled`);
      return;
    }
    const applyData = {
      id: projectData._id,
      userId: item.id,
    };
    const res = await shortListProject(applyData);
    if (res.data.status === "200") {
      const dataToSendApplicant = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: item.email,
        titleName: item.name,
        sponsorName: projectData.sponsorName,
        projectTitle: projectData.title,
        message: shortListedApplicantMesg,
        subject: `Congratulations! You've Been Shortlisted for the Project: "[Project Title]" | Bridge Projects`,
      };
      await sendMessage(dataToSendApplicant);
      const dataToSendManager = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: item.managerEmail,
        titleName: item.managerName,
        sponsorName: projectData.sponsorName,
        applicantName: item.name,
        message: shortListedManagerMesg,
        subject: `Update: "[Applicant Name]" has been shortlisted for "[Project ID]: [Project Title]" | Bridge Projects`,
        id: projectData._id,
        projectId: projectData.projectId,
        projectTitle: projectData.title,
        projectDescription: projectData.description,
        projectStatus: projectData.status,
        startDate: moment(projectData.startDate).format("DD-MM-YYYY"),
        endDate: moment(Number(projectData.endDate[projectData.endDate.length - 1])).format("DD-MM-YYYY"),
        openTo: projectData.employeeLevels.join(", "),
        skillRequired: projectData.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
        timeCommitment: projectData.weeklyTime,
        type: projectData.typeOfRequirement,
        keyDeliverables: projectData.keyDeliverables,
      };
      await sendMessage(dataToSendManager);
      toast.success("Applicant Shortlisted Successfully");
      handleApplyList();
      setShortlistedCount((prevCount) => prevCount + 1);
    }
  };

  const handleDelete = async () => {
    const deleteData = {
      id: projectData._id,
      msg: msg,
    };
    const res = await deleteTemporary(deleteData);
    if (res.data.status === "200") {
      history.push("/sponsor/open-projects");
    }
  };

  const handleDeleteApplication = async (item) => {
    const deleteData = {
      id: projectData._id,
      userId: item.id,
    };
    const res = await deleteApplication(deleteData);
    if (res.data.status === "200") {
      toast.success("Application Deleted Successfully");
      history.push("/sponsor/open-projects");
    }
  };

  // const handleSendMessage = async (data) => {
  //   const htmlMessage = textToHtml(message);
  //   if (data.notSelectedData.length) {
  //     data.notSelectedData.map(async (item) => {
  //       const dataToSend = {
  //         fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
  //         toEmail: item.email,
  //         titleName: item.name,
  //         projectTitle: projectData.title,
  //         ccEmail: JSON.parse(projectData.createdBy).email,
  //         message: htmlMessage,
  //         subject: data.subject,
  //       };
  //       await sendMessage(dataToSend);
  //     })
  //     // if (res.data.status === "200") {
  //     //   toast.success("Email sent Successfully");
  //     //   history.push("/sponsor/open-projects");
  //     // }
  //   }
  //   const resUpdateStatus = await updateProjectStatus({
  //     id: projectData._id,
  //     status: "ongoing"
  //   });
  //   if (resUpdateStatus.data.status === "200") {
  //     toast.success("Project move to ongoing Successfully");
  //     history.push("/sponsor/ongoing-projects");
  //   }
  // };

  const handleSendMessage = async (data) => {
    toast.info("Sending feedback emails, please wait");
    const htmlMessage = textToHtml(message);

    if (data.notSelectedData.length) {
      for (const item of data.notSelectedData) {
        const dataToSend = {
          fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
          toEmail: item.email,
          applicantName: item.name,
          projectTitle: projectData.title,
          ccEmail: JSON.parse(projectData.createdBy).email,
          message: htmlMessage,
          subject: activeTab === 1 ? `Update on your application for the Project: “[Project Title]” | Bridge Projects` : `Update on your candidature for the Project: “[Project Title]” | Bridge Projects`,
          projectSponsor: projectData.sponsorName,
          platformUrl: `<a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${projectData?._id}">Bridge Projects Marketplace</a>`
        };

        try {
          const mailRes = await sendMessage(dataToSend);
          if (mailRes.data.status === "200") {
            const applyData = {
              id: projectData._id,
              userId: item.id,
            };
            if (activeTab === 1) {
              await updateNotShortlisted(applyData);
            }
            else if (activeTab === 2) {
              await updateNotSelected(applyData);
            }

          }
        } catch (error) {
          console.error("Error sending email or updating status", error);
        }
      }

      toast.success("Emails sent successfully");
      setOpenModal(false);
      handleApplyList();
      setRefreshShortlist(!refreshShortlist);
    } else {
      toast.warn("No candidates to send emails to");
    }
  };
  const handleCloseApplication = async () => {
    if (notSelectedData.length === 0 && notAssignedData.length === 0) {
      try {
        const resUpdateStatus = await updateProjectStatus({
          id: projectData._id,
          status: "ongoing"
        });
        if (resUpdateStatus.data.status === "200") {
          toast.success("Project moved to ongoing Successfully");
          history.push("/sponsor/ongoing-projects");
        }
      } catch (err) {
        console.error(err)
      }

    } else {
      toast.error("Please send feedback to all the Not Assigned Applicants and Not Shortlisted Applicants before closing the applications", {
        position: "top-center",
        autoClose: false,
        style: {
          width: '100vh',

        }
      });
    }



  }
  const handleClick = () => {
    history.push("/sponsor/candidate-profile");
  };


  const preview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styleClasses.deleteMsgDiv}`}
    >
      <h1>Are you sure you want to delete this project?</h1>
      <p>
        After deletion the projects will be moved to trash, where it will stay
        for 60 days before permanent deletion. You can restore or permanently
        delete your projects manually from trash section.
      </p>
      <div className={styleClasses.reasonDiv}>
        <h6>State the reason of project deletion</h6>
        <textarea value={msg} onChange={handleChange} />
      </div>
      <div className={styleClasses.btnDiv}>
        <button className={styleClasses.closeBtn} onClick={handleClose}>
          Cancel
        </button>
        <button className={styleClasses.deleteBtn} onClick={handleDelete}>
          Confirm Delete
        </button>
      </div>
    </div>
  );

  const desPreview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styles.desmodal}`}
    >
      <button className={styles.closeBtn} onClick={() => setOpenDes(false)}>close</button>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: projectData?.description }}></p>


    </div>
  );

  const keyDelPreview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styles.desmodal}`}
    >
      <button className={styles.closeBtn} onClick={() => setOpenKeyDeliverables(false)}>close</button>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: projectData?.keyDeliverables }}></p>


    </div>
  )

  const handleRating = (rating) => {
    setRatingStar(rating);
  }

  const handleMarkAsComplete = async () => {
    if (ratingStar === 0) {
      toast.error("Please rate the applicant");
    } else if (feedback === "") {
      toast.error("Please write something about the applicant");
    } else {
      const data = {
        id: projectData._id,
        userId: assignedApplicants[0].id,
        rating: ratingStar,
        feedback: feedback
      }
      const res = await projectMarkAsComplete(data);
      if (res.data.status === "200") {
        toast.success("Marked as complete successfully");
        history.push("/sponsor/completed-projects");
      }
    }
  }

  const rating = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styleClasses.deleteMsgDiv}`}
    >
      <h1>Feedback and rating</h1>
      <p>
        Your feedback and ratings will be visible in the candidates profile and help the managers in the hiring process.
      </p>
      <div className={styleClasses.reasonDiv}>
        <h6>Rate the applicant</h6>
        <StarRatings
          starRatedColor="gold"
          rating={ratingStar}
          changeRating={handleRating}
          starDimension={`${window.innerWidth > 768 ? "50px" : "30px"}`}
        />
      </div>
      <div className={styleClasses.reasonDiv}>
        <h6>Write something about the applicant</h6>
        <textarea value={feedback} onChange={(e) => setFeedback(e.target.value)} />
      </div>
      <div className={styleClasses.btnDiv}>
        <button className={styleClasses.completeBtn} onClick={() => handleMarkAsComplete()}>
          Submit
        </button>
      </div>
    </div>
  );

  const updateProgess = (value) => {
    setProgress(value);
  }

  const handleUpdateProgress = async () => {
    if (progress === 0) {
      toast.error("Please select progress");
    } else if (progressStatus === "") {
      toast.error("Please select progress status");
    } else {
      const updateData = {
        id: projectData._id,
        progressBar: progress,
        progressStatus: progressStatus,
        isDeadlineExtended: projectData.isDeadlineExtended,
      }
      if (progressStatus === "Delayed" &&
        !projectData.isDeadlineExtended &&
        date && moment(date).format('YYYY-MM-DD') !== moment(projectData?.endDate[projectData?.endDate?.length - 1]).format('YYYY-MM-DD')) {
        updateData.newEndDate = moment(date).toISOString();
        updateData.isDeadlineExtended = true;
      }
      const res = await projectUpdateProgress(updateData);
      if (res.data.status === "200") {
        toast.success("Project Progress Updated Successfully");
        history.push("/sponsor/ongoing-projects");
      }
      else {
        toast.error("Error updating project progress");
      }
    }
  }

  const progressModal = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styleClasses.deleteMsgDiv}`}
    >
      <h1>Update Project Progress</h1>
      <p>
        Click on the progress bar to update the project progress.
      </p>
      <div style={{ cursor: "pointer" }}>
        <ProgressSlider
          getAriaValueText={updateProgess}
          aria-labelledby="discrete-slider"
          valueLabelDisplay="auto"
          defaultValue={progress}
        />
      </div>
      <p>Project Progress: {progress}%</p>
      <h1>Current Project Status</h1>
      <select value={progressStatus} onChange={(e) => setProgressStatus(e.target.value)}>
        <option value="">Select Status</option>
        <option value="On Track">On Track</option>
        <option value="Delayed">Delayed</option>
      </select>
      {
        (progressStatus === "Delayed" && !projectData.isDeadlineExtended) &&
        <div>
          <h1>Extend Deadline</h1>
          <p style={{ color: "red" }}>
            This project can only be extended once, for a maximum duration of one month.
          </p>
          <SingleDatePicker
            date={lastEndDateMoment}
            onDateChange={handleDateChange}
            focused={focused}
            onFocusChange={({ focused }) => setFocused(focused)}
            numberOfMonths={1}
            isOutsideRange={isOutsideRange}
            placeholder="End Date"
            displayFormat="DD/MM/YYYY"
            verticalSpacing={-200}
          />
        </div>
      }
      <div className={styleClasses.btnDiv}>
        <button className={styleClasses.completeBtn} onClick={() => setOpenProgress(false)} >
          Close
        </button>
        <button className={styleClasses.completeBtn} onClick={() => handleUpdateProgress()}>
          Submit
        </button>
      </div>
    </div>
  );

  const onEditMsg = () => {
    setAllowEdit(!allowEdit);
  };

  const handleOpenSendTab = () => {
    setOpenSendTab(true);
  };

  const handleNotShortlistedFeedbackSelect = (item) => {
    if (notShortlistedFeedback.includes(item)) {
      setNotShortlistedFeedback(pre => {
        return pre.filter((i) => i !== item)
      })
    } else {
      setNotShortlistedFeedback(pre => {
        return [...pre, item]
      })
    }

  }

  const handleNotSelectedFeedbackSelect = (item) => {
    if (notSelectedFeedback.includes(item)) {
      setNotSelectedFeedback(pre => {
        return pre.filter((i) => i !== item)
      })
    } else {
      setNotSelectedFeedback(pre => {
        return [...pre, item]
      })
    }

  }

  const review = () => {
    return (
      <div style={modalStyle} className={classes.reviewpaper}>
        {openSendTab ? (
          <>
            <div className={styleClasses.backDiv}>
              <div onClick={() => setOpenSendTab(false)} className={styleClasses.back}>
                <AiOutlineLeft style={{ fontSize: "32px" }} />
                <p>Edit email content</p>
              </div>
              <button onClick={handleCloseModal} className={styleClasses.closeBtn}>Close</button>
            </div>
            <div className={styleClasses.senddiv}>
              <p>
                Please select the candidates you wish to provide feedback for, or choose "Send Feedback to All" to share feedback with all listed candidates.
              </p>
              <button
                onClick={() => {
                  const data = {
                    notSelectedData: activeTab === 1 ? (notShortlistedFeedback.length === 0 ? notSelectedData : notShortlistedFeedback) : notSelectedFeedback.length === 0 ? notAssignedData : notSelectedFeedback,
                    subject: `Project Application Status Updated: "[Project Title]"`,
                  };
                  handleSendMessage(data);
                }}
                className={` ${styleClasses.sendbtn}`}
              >
                {notShortlistedFeedback.length === 0 ? "Send Feedback to All" : "Send Feedback to Selected"}
              </button>
            </div>
            <div>

              <ul
                style={{ backgroundColor: "white" }} className={`${styleClasses.recommendlist} ${styleClasses.messagelist}`}
              >
                {(activeTab === 1 ? notSelectedData : activeTab === 2 ? notAssignedData : []).map((item, index) => {
                  return (
                    <li
                      className={`${styleClasses.recommendli} ${styleClasses.recommendliMsg}`}
                      key={index}
                    >
                      <NavLink
                        key={index}
                        className={styleClasses.iddiv}
                        to={{
                          pathname: "/sponsor/candidate-profile",
                          state: { item, projectData },
                        }}
                      >
                        <div
                          className={styleClasses.profile}
                          style={{
                            backgroundImage: `url(${personImage})`,
                          }}
                        ></div>
                        <div
                          className={styleClasses.details}
                          onClick={handleClick}
                        >
                          <h1>{item.name}</h1>
                          <p>Department: {item.department ? item.department : "-"}</p>
                          {/* <h6>{countSkillMatches(skills, item)} out of {skills?.length} skills matches this profile</h6> */}
                        </div>
                      </NavLink>
                      <button
                        className={`${styleClasses.shortBtn} ${notShortlistedFeedback.includes(item) || notSelectedFeedback.includes(item) ? styleClasses.shortBtnSelected : ""}`}
                        onClick={() => activeTab === 1 ? handleNotShortlistedFeedbackSelect(item) : handleNotSelectedFeedbackSelect(item)}
                      >
                        {activeTab === 1 ? notShortlistedFeedback.includes(item) ? "Selected" : "Select" : notSelectedFeedback.includes(item) ? "Selected" : "Select"}
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
          </>
        ) : (
          <div className={styleClasses.reviewwrap}>
            <div onClick={onEditMsg} className={styleClasses.editdiv}>
              <p>Update on Your Application Status</p>
              {allowEdit ? (
                // <div className={styleClasses.editbtn}>
                //   <p>Save Content Changes</p>
                // </div>
                null
              ) : (
                <div className={styleClasses.editbtn}>
                  <img src={editIcon} alt="edit icon" />
                  <p>Edit</p>
                </div>
              )}
            </div>
            {/* <div className={styleClasses.betterdiv}>
            <h1>Better luck next time!</h1>
            <img src={betterIcon} alt="better icon" />
          </div> */}

            <textarea
              readOnly={!allowEdit}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              className={styleClasses.message}
            />
            <button onClick={handleOpenSendTab} className={styleClasses.sendbtn}>
              Review & Send
            </button>
          </div>
        )}
      </div>
    )
  }

  const getProjectData = async () => {
    const res = await getProjectDetails(props.location.state ? props.location.state?.project._id : window.localStorage.getItem("projectId"));
    if (res.data.status === "200") {
      setProjectData(res.data.project);
    }
  }

  useEffect(() => {
    handleApplyList();
    setProgress(projectData?.progressBar ? projectData.progressBar : 0);
    setProgressStatus(projectData?.progressStatus ? projectData.progressStatus :
      "");
  }, [projectData]);

  useEffect(() => {
    getProjectData();
  }, [])

  const filteredDocumentsUrls = projectData?.instructions?.filter(
    (url) => !url.match(/\.(mp4|avi|mov)$/)
  );

  const filteredVideoUrls = projectData?.instructions?.find((url) =>
    url.match(/\.(mp4|avi|mov)$/)
  );



  useEffect(() => {
    setVideoUrl(filteredVideoUrls);
  }, [filteredVideoUrls]);


  const handleDownload = () => {
    filteredDocumentsUrls.forEach((url, index) => {
      setTimeout(async () => {
        const link = document.createElement('a');
        link.href = url
        link.download = true;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 1000);
    });
  };


  const renderSkills = (skills) => {

    const skillsArray = skills?.map((each) => each.label);

    let skillsPara = ""

    if (skills?.length > 1) {
      skillsPara = skillsArray.join(", ")
      if (skills.length > 3 && !showSkills) {
        return <p className={styles.skill} >{skillsPara.slice(0, 50)}<span onClick={() => setShowSkills(true)} className={styles.seeMore}>...view more </span></p>
      }
      else if (skills.length > 3 && showSkills) {
        return <p className={styles.skill}>{skillsPara}</p>
      }
      return (skillsPara)
    } else {
      return (skillsArray && skillsArray[0])
    }

  }

  const renderImproveSkills = (skills) => {

    const skillsArray = skills?.map((each) => each.label);

    let skillsPara = ""

    if (skills?.length > 1) {
      skillsPara = skillsArray.join(", ")
      if (skills.length > 3 && !showImproveSkills) {
        return <p className={styles.skill} >{skillsPara.slice(0, 50)}<span onClick={() => setShowImproveSkills(true)} className={styles.seeMore}>...view more </span></p>
      }
      else if (skills.length > 3 && showImproveSkills) {
        return <p className={styles.skill}>{skillsPara}</p>
      }
      return (skillsPara)
    } else {
      return (skillsArray && skillsArray[0])
    }

  }

  const assignedApplicants = projectData?.applicants?.filter((item) => item.status === "assigned" || item.status === "completed")

  const completedApplicants = projectData?.applicants?.filter((item) => item.status === "completed")

  const updateAssignedList = () => {
    handleApplyList();
  }

  const renderTabsView = () => {
    const renderAllApplications = () => {
      return (
        <ul className={styleClasses.recommendlist}>
          {(activeTab === 1 ? notSelectedData : activeTab === 3 && assignedList)?.map((item, index) => {
            return (
              <li className={styleClasses.recommendli} key={index}>
                <NavLink
                  className={styleClasses.iddiv}
                  to={{
                    pathname: "/sponsor/candidate-profile",
                    state: { item, projectData },
                  }}
                >
                  <div
                    className={styleClasses.profile}
                    style={{
                      backgroundImage: `url(${personImage})`,
                    }}
                  ></div>
                  <div className={styleClasses.details}>
                    <div className={styles.sponsor}>
                      <h1>{item.name}</h1>
                      {/* <Tippy content="View Candidate Profile" placement="right">
                        <img src={LinkIcon} alt="link" className={styles.link} />
                      </Tippy> */}
                    </div>
                    <p>{item.level}; {item.department}; {item.orgUnit}</p>
                    {/* <h6>{countSkillMatches(skills, item)} out of {skills?.length} skills matched</h6> */}
                  </div>
                </NavLink>
                <button
                  className={`${styleClasses.shortBtn}  ${item?.alreadyAssigned ? styleClasses.alreadyAssigned : item?.status === "applied" ? styleClasses.shortlist : styleClasses.shortListed}`}
                  onClick={() => (item?.status === "applied" && !item?.alreadyAssigned) && handleShortList(item)}
                >
                  {item?.alreadyAssigned ? "Assigned to another project already" : item?.status === "applied" ? 'Shortlist' : item?.status === "shortlisted" ? 'Shortlisted' : 'Assigned'}
                </button>
              </li>
            );
          })}
        </ul>
      )
    }


    switch (activeTab) {
      case 1:
        return renderAllApplications();
      case 2:
        return <Shortlisted projectData={projectData} onAssigne={updateAssignedList} refreshShortlist={refreshShortlist} />;
      // case 3:
      //   return renderAssignedApplicants();
      default:
        return renderAllApplications();
    }
  }

  const handleTabChange = (tab) => {
    if (tab === 1) {
      setMessage(notShortlistedmsg)
      setActiveTab(1)
    }
    else if (tab === 2) {
      setMessage(notSelectedmsg)
      setActiveTab(2)
    }
    else {
      setActiveTab(3)
    }
  }

  return (
    <div>
      <Navbar />
      <div className={styles.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.subdiv}>
          <div className={styles.headdiv}>
            <div className={`${styles.dateDiv} ${styles.dateDiv2}`}>
              <div className={styles.date}>
                <h1>Start date</h1>
                <p>{dateFormat(projectData?.startDate)}</p>
              </div>
              <div className={styles.date}>
                <h1>End date</h1>
                <p>{dateFormat(Number(projectData?.endDate[projectData?.endDate?.length - 1]))}</p>
              </div>
              <div className={styles.date}>
                <h1>Last Date to apply</h1>
                <p>{dateFormat(projectData?.lastDate)}</p>
              </div>
            </div>
            <div
              className={styles.imgDiv}
              style={{
                backgroundImage: `url(${projectData?.cover || banners[projectData?.localCover]?.path})`,
              }}
            >
              <div className={styles.backDiv}>
                <NavLink
                  style={{ textDecoration: "none", color: "white" }}
                  to="#"
                  onClick={() => history.goBack()}
                >
                  <AiOutlineLeft style={{ fontSize: "32px", color: "#ff002b" }} />
                </NavLink>
                <div className={styleClasses.iddiv}>
                  <p style={{ border: "1px solid #ffcf23" }}>ID: {projectData?.projectId}</p>
                  {(projectData?.status === "pending" || projectData?.status === "open") && (
                    <div onClick={handleOpen} className={styleClasses.deletediv}>
                      <img src={deleteIcon} alt="delete project" />
                      <p>Delete project</p>
                    </div>
                  )}
                  {/* {projectData?.status === "open" ? (
                    <NavLink
                      className={styleClasses.viewLink}
                      to={{
                        pathname: "/sponsor/shortlisted-candidates",
                        state: { projectData },
                      }}
                    >
                      <p className={styleClasses.viewCandi}>
                        View Shortlisted Applicants
                      </p>
                      <img
                        className={styleClasses.viewIcon}
                        src={viewApplicationsIcon}
                        alt="view icon"
                      />
                    </NavLink>
                  ) : null} */}
                </div>
              </div>
            </div>
            <div className={styles.titleDiv}>
              <div className={styles.title}>
                <p>Posted on: {dateFormat(projectData?.createdAt)}</p>
                <h1>{projectData?.title}</h1>
                <p>Number of open positions: {projectData?.openPositions}</p>
              </div>
              {filteredDocumentsUrls?.length > 0 && (
                <div onClick={handleDownload} className={`${styles.information} ${styles.division} ${styles.downloaddiv}`}>
                  <h1 className={styles.downText}>Reference Documents</h1>
                  <img className={styles.downIcon} src={download} alt="download" />
                </div>
              )}
            </div>
            <div className={styles.applyDiv}>
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Division</h1>
                <p>
                  {(projectData && projectData?.division) &&
                    JSON.parse(projectData?.division)?.value}
                </p>
              </div>
              {/* <div className={`${styles.information} ${styles.division}`}>
                  <h1>SBU</h1>
                  <p>
                    {projectRecommendationData &&
                      JSON.parse(projectRecommendationData?.sbu)?.value}
                  </p>
                </div>
                <div className={`${styles.information} ${styles.division}`}>
                  <h1>Org Unit</h1>
                  <p>
                    {projectRecommendationData &&
                      JSON.parse(projectRecommendationData?.orgUnit)?.value}
                  </p>
                </div> */}
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Function</h1>
                <p>{(projectData && projectData?.function) && JSON.parse(projectData?.function)?.value}</p>
              </div>
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Department</h1>
                <p>
                  {(projectData && projectData?.department) &&
                    JSON.parse(projectData?.department)
                      ?.value}
                </p>
              </div>
              <div className={`${styles.information} ${styles.division}`}>
                <h1 >Project Sponsor</h1>
                <div className={styles.sponsor}>
                  <p>{projectData?.sponsorName}</p>
                  {/* <Tippy content="View Sponsor Profile" placement="bottom">
                    <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${projectData.sponsorId}`, '_blank')} src={LinkIcon} alt="link" className={styles.link} />
                  </Tippy> */}
                </div>
              </div>

            </div>
            <p className={styles.descriptionDiv}>
              {isFullTextDisplayed || !shouldTruncate
                ? <span dangerouslySetInnerHTML={{ __html: description }}></span>
                : <span>
                  <span onClick={() => setOpenDes(true)} dangerouslySetInnerHTML={{ __html: truncatedText }}></span>
                  {/* <span className={styles.readMore} onClick={() => setOpenDes(true)} style={{ cursor: 'pointer', color: '#096070' }}>
                    Read More
                  </span> */}
                </span>
              }
            </p>
          </div>
          <div className={styles.rightdiv}>
            <div className={styles.dateDiv}>
              <div className={styles.date}>
                <h1>Start date</h1>
                <p>{dateFormat(projectData?.startDate)}</p>
              </div>
              <div className={styles.date}>
                <h1>End date</h1>
                <p>{dateFormat(Number(projectData?.endDate[projectData?.endDate.length - 1]))}</p>
              </div>
              <div className={styles.date}>
                <h1>Last Date to apply</h1>
                <p>{dateFormat(projectData?.lastDate)}</p>
              </div>
            </div>

            <div className={styles.infodiv}>
              <div className={styles.information}>
                <h1>Duration</h1>
                <p>
                  {calculateMonthWeekDifference(
                    projectData?.startDate,
                    Number(projectData?.endDate[projectData?.endDate.length - 1])
                  )}
                </p>
              </div>
              <div className={styles.information}>
                <h1>Type</h1>
                <p>{projectData?.typeOfRequirement}</p>
              </div>
              <div className={styles.information}>
                <h1>Weekly commitment</h1>
                <p>{projectData?.weeklyTime} Hours</p>
              </div>
              <div className={styles.information}>
                <h1>Open to</h1>
                <p>{projectData?.employeeLevels.length === 9 ? "Open to All" : `${projectData?.employeeLevels.join(', ')}`}</p>
              </div>
            </div>
            <div className={styles.skillDiv}>
              <p className={styles.skill} >
                {renderSkills(skills)}
              </p>
            </div>
            <div className={styles.improveDiv}>
              <p className={styles.skill} >
                {renderImproveSkills(improvedSkills)}
              </p>
            </div>

            <div className={styles.keydiv}>
              <p
                className={styles.key}
              >
                {isFullKDelTextDisplayed || !shouldTruncateDeliverables
                  ? <span dangerouslySetInnerHTML={{ __html: keyDeliverables }}></span>
                  : <span>
                    <span onClick={() => setOpenKeyDeliverables(true)} dangerouslySetInnerHTML={{ __html: truncatedTextDeliverables }}></span>
                    {/* <span className={styles.readMore} onClick={() => setOpenDes(true)} style={{ cursor: 'pointer', color: '#096070' }}>
                      Read More
                    </span> */}
                  </span>
                }
              </p>
            </div>
            {filteredVideoUrls ? (
              <div className={styleClasses.playerDiv}>
                <ReactPlayer
                  controls={true}
                  url={videoUrl}
                  className={styles.player}
                />
              </div>
            ) : null}
          </div>

        </div>
        <div className={styleClasses.applyCont}>
          {projectData?.status === "open" ? (
            <div style={{ paddingBottom: "20px" }}>
              <div className={styleClasses.applicationsdiv}>
                <div className={styleClasses.headdiv}>
                  <div className={styles.tabsDiv}>
                    <h1 onClick={() => handleTabChange(1)} className={activeTab === 1 ? styles.activeTab : ""}>Applications ({notSelectedData.length}) </h1>
                    <h1 onClick={() => handleTabChange(2)} className={activeTab === 2 ? styles.activeTab : ""}>Shortlisted Candidates ({shortlistedCount})</h1>
                    <h1 onClick={() => handleTabChange(3)} className={activeTab === 3 ? styles.activeTab : ""}>Assigned / Selected Candidates ({assignedList?.length})</h1>
                    <button role="button" className={styles.shortlist} onClick={handleCloseApplication}>
                      Close Applications
                    </button>
                  </div>
                  <div className={styles.closeDiv}>
                    <p>
                      {activeTab === 1 ?
                        "Candidates who have applied for this project, are listed here."
                        :
                        activeTab === 2 ?
                          "Candidates who are shortlisted for this project, are listed here."
                          :
                          "Candidates who have been assigned to this project, are listed here."
                      }
                    </p>
                    {
                      activeTab === 1 || activeTab === 2 ?
                        <button role="button" onClick={activeTab === 3 ? handleCloseApplication : handleOpenModal}>
                          {activeTab === 1 ? "Send Feedback to Non-Shortlisted Applicants" : "Send Feedback to Non-Assigned Applicants"}
                        </button> : null
                    }

                  </div>
                </div>
              </div>
              <div className={styleClasses.listdiv}>
                {/* <div className={styleClasses.topdiv}>
                  <img src={starIcon} alt="star" />
                  <p>Recommended (Top 3)</p>
                </div> */}
                {renderTabsView()}
              </div>
            </div>
          ) : null}
        </div>
        {projectData?.status === "ongoing" && <div style={{ display: `${projectData?.status === "ongoing" ? "flex" : "none"}` }} className={styleClasses.completeCont}>
          {assignedApplicants?.map((item, index) => {
            return (
              <div className={styleClasses.wrapDiv} key={index}>
                <div className={styleClasses.compsubdiv}>
                  <ProfileImage />
                  <div className={styleClasses.completeDiv}>
                    <h1 className={styleClasses.userName}>{item?.name}</h1>
                    <ProgressBar color={projectData?.progressStatus === "On Track" ? "#3AC652" : "#ff0000ad"} value={projectData?.progressBar ? projectData?.progressBar : 0} />
                    <p className={styleClasses.progress}>Project: <span className={styleClasses.text}>{projectData?.title}</span> </p>
                    <p className={styleClasses.progress}>Progress: <span className={styleClasses.text}>{projectData?.progressBar ? projectData?.progressBar : 0}%</span> </p>
                    <p className={styleClasses.progress}> Days Left: <span className={styleClasses.text}>{calculateDayDifference(projectData?.startDate, Number(projectData?.endDate[projectData?.endDate.length - 1]))}</span> </p>
                    {/* <p className={styleClasses.progress}> Performance: <span className={styleClasses.text}>Excellent</span></p> */}
                    <div className={styleClasses.cbtnDiv}>
                      <button onClick={() => setOpenProgress(true)} className={styleClasses.completeBtn}>Update Progress</button>
                      <button disabled={item?.status !== "completed"} onClick={() => setOpenRating(true)} className={styleClasses.completeBtn}>Mark as complete</button>
                    </div>

                  </div>
                </div>
                <div className={`${styleClasses.cbtnDiv} ${styleClasses.cbtnDivMobile}`}>
                  <button onClick={() => setOpenProgress(true)} className={styleClasses.completeBtn}>Update Progress</button>
                  <button disabled={item?.status !== "completed"} onClick={() => setOpenRating(true)} className={styleClasses.completeBtn}>Mark as complete</button>
                </div>
              </div>
            )
          }
          )}
        </div>}
        {projectData?.status === "complete" && <div style={{ display: `${projectData?.status === "complete" ? "block" : "none"}` }}>
          {completedApplicants?.map((item, index) => (
            <>
              <div className={styleClasses.completedDiv}>
                <p className={styleClasses.feedback}>
                  Name:
                  <span className={styleClasses.feedText}>
                    {" "}{item?.name}
                  </span>
                </p>
                <p className={styleClasses.feedback}>
                  Email:
                  <span className={styleClasses.feedText}>
                    {" "}{item?.email}
                  </span>
                </p>
                <div>
                  {Array.from({ length: item?.sponsorRating }).map((_, i) => (
                    <img
                      src={star}
                      alt="star"
                      key={i}
                      className={styleClasses.star}
                    />
                  ))}
                  {Array.from({ length: 5 - item?.sponsorRating }).map((_, i) => (
                    <img
                      src={emptyStart}
                      alt="star"
                      key={i}
                      className={styleClasses.emptystar}
                    />
                  ))}
                </div>
                <p className={styleClasses.feedback}>Feedback from Applicant: <span className={styleClasses.feedText}>
                  “{item?.sponsorFeedback}!”
                </span></p>
              </div>
              <div className={styleClasses.completedDiv}>
                <div>
                  {Array.from({ length: item?.rating }).map((_, i) => (
                    <img
                      src={star}
                      alt="star"
                      key={i}
                      className={styleClasses.star}
                    />
                  ))}
                  {Array.from({ length: 5 - item?.rating }).map((_, i) => (
                    <img
                      src={emptyStart}
                      alt="star"
                      key={i}
                      className={styleClasses.emptystar}
                    />
                  ))}
                </div>
                <p className={styleClasses.feedback}>Feedback from Project Sponsor: <span className={styleClasses.feedText}>
                  “{item?.feedback}”
                </span></p>
              </div>
            </>
          ))}
        </div>}
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {preview}
      </Modal>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {review()}
      </Modal>
      <Modal
        open={openRating}
        onClose={() => setOpenRating(false)}
      >
        {rating}
      </Modal>
      <Modal
        open={openProgress}
        onClose={() => setOpenProgress(false)}
      >
        {progressModal}
      </Modal>
      <Modal
        open={openDes}
        onClose={() => setOpenDes(false)}
      >
        {desPreview}
      </Modal>
      <Modal
        open={openKeyDeliverables}
        onClose={() => setOpenKeyDeliverables(false)}
      >
        {keyDelPreview}
      </Modal>
    </div>
  );
};

export default ProjectDetails;
