import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { updateLog } from '../../services';
import Cookies from 'js-cookie';

function useAutoLogout(inactivityTime = 600000) { // 10 minutes
  const timeoutIdRef = useRef(null);
  const navigate = useHistory();

  const logout = async () => {
    try {
      const response = await updateLog({ id: window.localStorage.getItem("logId"), end: new Date().getTime() });
      if (response.data.status === "200") {
        window.localStorage.clear();
        Cookies.remove("token");
        Cookies.remove("connect.sid");
        navigate.push("/");
      }
    }
    catch (err) {
      console.error(err);
    }
  };

  const resetTimer = () => {
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(logout, inactivityTime);
  };

  useEffect(() => {
    const events = ['click', 'mousemove', 'keydown', 'scroll'];
    events.forEach(event => window.addEventListener(event, resetTimer));

    resetTimer(); // Initialize the timer

    return () => {
      clearTimeout(timeoutIdRef.current);
      events.forEach(event => window.removeEventListener(event, resetTimer));
    };
  }, []);

  return {
    resetTimer // expose resetTimer if needed elsewhere
  };
}

export default useAutoLogout;