import React, { useRef, useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import styles from "./ProjectUpdateStatus.module.css";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import ProgressBar from "../common/ProgressBar";
import { Controller, useForm } from "react-hook-form";
import uploadIcon from "../../assets/upload.png";
import bgImage from '../../assets/BackGround/bg1web.png';
import { calculateMonthWeekDifference } from "../../util/commenFunctions";
import { projectUserUpdateStatus, sendMessage, getProjectDetails } from "../../services";
import { toast } from "react-toastify";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import StarRatings from "react-star-ratings";

function getModalStyle() {
  const top = 60;
  const left = 60;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
}));

const ProjectUpdateStatus = (props) => {
  const history = useHistory();
  const formRef = useRef();
  const mediaRef = useRef();
  const [instructionFiles, setInstructionFiles] = useState({});
  // const projectData = props.location.state;
  const [isFullTextDisplayed, setIsFullTextDisplayed] = useState(false);
  const [openDes, setOpenDes] = React.useState(false);
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [ratingStar, setRatingStar] = React.useState(0);
  const [projectData, setProjectData] = useState();

  const description = projectData?.description;
  const shouldTruncate = description && description?.length > 400;
  const truncatedText = description?.substring(0, 400);

  const handleRating = (rating) => {
    setRatingStar(rating);
  }

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  const updateMsg = `
<p>Hi [Title Name]</p>,

<p>Quick update! ([Sponsor Name]) just wrapped up the project - ([Project Title]) and shared their feedback. 🚀 We're buzzing with excitement!</p>

<p>Feel free to have a look at what they said.</p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const onUpdateStatus = async (data) => {
    if (projectData.progressBar === 100 && ratingStar > 0) {
      const formData = new FormData();
      formData.append("finalFile", data.instructions);
      formData.append("sponsorFeedback", data.sponsorFeedback);
      formData.append("sponsorRating", ratingStar);
      formData.append("id", projectData?._id);
      formData.append("userId", window.localStorage.getItem("userId"));
      const result = await projectUserUpdateStatus(formData);
      if (result?.data?.status === "200") {
        const dataToSend = {
          fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
          toEmail: JSON.parse(projectData.createdBy).email,
          titleName: JSON.parse(projectData.createdBy).name,
          projectTitle: projectData.title,
          ccEmail: window.localStorage.getItem("userEmail"),
          sponsorName: window.localStorage.getItem("userName"),
          message: updateMsg,
          subject: `Exciting News: Project Feedback is In!`,
        };
        await sendMessage(dataToSend);
        toast.success("Status updated successfully");
        history.push("/applicant/dashboard");
      }
    } else if (ratingStar === 0) {
      toast.error("Please rate the project before submitting");
    } else {
      toast.error("Please complete the project before submitting");
    }
  }

  const desPreview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styles.desmodal}`}
    >
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: projectData?.description }}></p>
    </div>
  );

  const getProjectData = async () => {
    const res = await getProjectDetails(props.location.state ? props.location.state?._id : window.localStorage.getItem("projectId"));
    if (res.data.status === "200") {
      setProjectData(res.data.project);
    }
  }

  useEffect(() => {
    getProjectData();
  }, [])

  return (
    <div>
      <Navbar />
      <div className={styles.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.subdiv}>
          <div className={styles.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/applicant/dashboard"
            >
              <AiOutlineLeft style={{ fontSize: "32px" }} />
            </NavLink>
            <h1 className={styles.subHead}>{projectData?.title}</h1>
          </div>
          <div className={styles.bodyDiv}>
            <p className={styles.subDescription}>
              This section provides comprehensive insights into the project's
              scope, objectives, and execution approach.
            </p>
            <div className={styles.lineDistance}>
              <span className={styles.jobTitle}>{projectData?.title}</span>
              <span className={`${styles.jobTitle} ${styles.floatRight}`} style={{ color: projectData?.progressStatus === "On Track" ? "#3AC652" : "#ff0000ad" }}>{projectData?.progressStatus ? projectData?.progressStatus : ""}</span>
            </div>
            <ProgressBar color={projectData?.progressStatus === "On Track" ? "#3AC652" : "#ff0000ad"} value={projectData?.progressBar ? projectData?.progressBar : 0} />
            <div className={styles.projectDetails}>Progress: {projectData?.progressBar ? projectData?.progressBar : 0}%</div>
            <div className={styles.projectDetails}>Duration: {calculateMonthWeekDifference(projectData?.startDate, Number(projectData?.endDate[projectData?.endDate?.length - 1]))}</div>
            <div className={styles.projectDetails}>
              Description: <p className={styles.description}>
                {isFullTextDisplayed || !shouldTruncate
                  ? <span dangerouslySetInnerHTML={{ __html: description }}></span>
                  : <span>
                    <span dangerouslySetInnerHTML={{ __html: truncatedText }}></span>
                    <span className={styles.readMore} onClick={() => setOpenDes(true)} style={{ cursor: 'pointer', color: '#096070' }}>
                      Read More
                    </span>
                  </span>
                }
              </p>
            </div>
            <div className={styles.divDistance}>
              <span className={`${styles.jobTitle} ${styles.textColor}`}>Submit your final outcome</span>
            </div>
            <form
              onSubmit={handleSubmit(onUpdateStatus)}
              encType="multipart/form-data"
              ref={formRef}
            >
              <div className={styles.skillsDiv}>
                <div className={styles.uploaddiv}>
                  <Controller
                    name="instructions"
                    control={control}
                    defaultValue={null}
                    render={({ field: { onChange, value } }) => (
                      <input
                        ref={mediaRef}
                        type="file"
                        accept="all"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          const selectedFiles = e.target.files[0];
                          setInstructionFiles(selectedFiles);
                          onChange(selectedFiles);
                        }}
                      />
                    )}
                  />
                  <div
                    className={styles.uploadbtn}
                    onClick={() => mediaRef.current.click()}
                  >
                    <img
                      className={styles.uploadIcon}
                      src={uploadIcon}
                      alt="uploadIcon"
                    />
                    <p>Upload file</p>
                  </div>
                  <div className={styles.fileNameDiv}>
                    {instructionFiles?.name ? (
                      <>
                        <p className={styles.fileName}>Selected Files:</p>
                        <span>
                          {instructionFiles.name}
                        </span>
                      </>
                    ) : null}
                  </div>
                </div>
                <h6 className={`${styles.jobTitle} ${styles.textColor} ${styles.ratingDiv}`}>Rate the project sponsor</h6>
                <StarRatings
                  starRatedColor="gold"
                  rating={ratingStar}
                  changeRating={handleRating}
                  starDimension={`${window.innerWidth > 768 ? "50px" : "30px"}`}
                />
                <div className={styles.textDiv}>
                  <textarea
                    className={styles.textArea}
                    placeholder="Write something about your project, and describe your experience. Your feedback is valuable to us."
                    {...register("sponsorFeedback")}
                    required
                  />
                </div>
                <button
                  className={styles.submitBtn}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Modal
        open={openDes}
        onClose={() => setOpenDes(false)}
      >
        {desPreview}
      </Modal>
    </div>
  );
};

export default ProjectUpdateStatus;
