import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styels from "./Profile.module.css";
import exemplaryExpert from "../../assets/ExemplaryExpert.png";
import noviceAchiever from "../../assets/NoviceAchiever.png";
import skillfulPerformer from "../../assets/SkillfulPerformer.png";
import proficientContributor from "../../assets/ProficientContributor.png";
import outstandingAchiever from "../../assets/OutstandingAchiever.png";
import masterCollaborator from "../../assets/MasterCollaborator.png";
import distinguishedProfessional from "../../assets/DistinguishedProfessional.png";
import elitePerformer from "../../assets/ElitePerformer.png";
import exceptionalLeader from "../../assets/ExceptionalLeader.png";
import legendaryAchiever from "../../assets/LegendaryAchiever.png";
import personImage from "../../assets/person.png";
import bgImage from '../../assets/BackGround/bg3web.png';
import {
  getUserProfilePhotoData,
  updateAboutMe,
  getUserDetails,
  getUserProjectCounts
} from "../../services";
import { employeeLevel, parseJwt, openUrl } from "../../util/commenFunctions";
import Cookies from "js-cookie";
import exportIcon from "../../assets/export.png";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "33px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "80%",
    },
  },
}));

const Profile = (props) => {
  const checkToken = Cookies.get("token")
  const decodedJwt = parseJwt(checkToken);
  const [open, setOpen] = React.useState(false);
  const [profileData, setProfileData] = useState();
  const [userProfilePhoto, setUserProfilePhoto] = useState(personImage);
  const [msg, setMsg] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [projectCounts, setProjectCounts] = useState(0);
  const maxCharacters = 100;



  const [readMore, setReadMore] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const skillsToShow = readMore ? profileData?.skills : profileData?.skills?.slice(0, 5);
  const hasMoreSkills = profileData?.skills?.length > 5;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacters) {
      setMsg(inputValue);
    }
  };

  const handleAboutMe = async () => {
    const responseData = {
      description: msg,
      email: window.localStorage.getItem("userEmail"),
    };
    await updateAboutMe(responseData);
  };

  const getAboutMe = async () => {
    const res = await getUserDetails(window.localStorage.getItem("userEmail"));
    if (res.data.status === "200") {
      setMsg(res.data.data[0].description);
    }
  };

  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getUserProfilePhoto = async (userId) => {
    const responseData = await getUserProfilePhotoData(userId);
    window.localStorage.setItem(
      "userProfilePhoto",
      responseData.data.profileData?.profilePhoto ? responseData.data.profileData.profilePhoto : personImage
    );
    setUserProfilePhoto(
      responseData.data.profileData?.profilePhoto
        ? `data:image/jpeg;base64,${responseData.data.profileData.profilePhoto}`
        : personImage
    );
  };

  const handleProfileData = async () => {
    const responseData = {
      department: window.localStorage.getItem("userDepartment"),
      division: window.localStorage.getItem("userDivision"),
      orgUnit: window.localStorage.getItem("userOrgUnit"),
      sbu: window.localStorage.getItem("userSbu"),
      level: window.localStorage.getItem("userLevel"),
      experience: window.localStorage.getItem("userExperience"),
      skills: window.localStorage.getItem("userSkills")?.split(","),
      email: window.localStorage.getItem("userEmail"),
      phoneNumber: window.localStorage.getItem("userPhoneNumber"),
      title: window.localStorage.getItem("userTitle"),
    };
    setProfileData(responseData);
  };

  const handleProjectCounts = async (userId) => {
    const res = await getUserProjectCounts(userId);
    if (res.data.status === "200") {
      setProjectCounts(res.data.project);
    }
  }

  useEffect(() => {
    getUserProfilePhoto(window.localStorage.getItem("userId"));
    handleProjectCounts(window.localStorage.getItem("userId"))
    handleProfileData();
    getAboutMe();
  }, []);

  // useEffect(() => {
  //   if (msg !== "") {
  //     // Clear the existing timeout
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }

  //     // Set a new timeout for 5 seconds
  //     const newTimeoutId = setTimeout(() => {
  //       handleAboutMe();
  //     }, 5000);

  //     // Save the timeout ID in the state
  //     setTimeoutId(newTimeoutId);
  //   }

  //   // Cleanup the timeout when the component unmounts
  //   return () => {
  //     if (timeoutId) {
  //       clearTimeout(timeoutId);
  //     }
  //   };
  // }, [msg, timeoutId]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={styels.headdiv}>
        <h1>Badges</h1>
        <p>
          Here you’ll be able to see complete information about the badge
          system.{" "}
        </p>
      </div>
      <div className={styels.badgesdiv}>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={noviceAchiever}
              alt="Novice Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Novice Achiever</h3>
              <p>
                Earned after completing the first streak of a project
                successfully.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={skillfulPerformer}
              alt="Skillful Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Skillful Performer</h3>
              <p>Achieved after completing two streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              src={proficientContributor}
              alt="Proficient Contributor Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Proficient Contributor</h3>
              <p> Earned after completing three streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={outstandingAchiever} alt="Outstanding Achiever Bagde" />
            <div className={styels.badgetext}>
              <h3>Outstanding Achiever</h3>
              <p>Achieved after completing four streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={masterCollaborator} alt="Master Collaborator Bagde" />
            <div className={styels.badgetext}>
              <h3>Master Collaborator</h3>
              <p>Earned after completing five streaks of 5 projects each.</p>
            </div>
          </div>
        </div>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exemplaryExpert}
              alt="Exemplary Expert Bagde"
            />
            <div className={styels.badgetext}>
              <h3>ExemplaryExpert</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 5 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={distinguishedProfessional}
              alt="Distinguished Professional Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Distinguished Professional</h3>
              <p>
                {" "}
                Earned after receiving positive feedback from project managers
                on 10 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={elitePerformer}
              alt="Elite Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Elite Performer</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 15 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exceptionalLeader}
              alt="Exceptional Leader Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Exceptional Leader</h3>
              <p>
                Earned after receiving positive feedback from project managers
                on 20 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={legendaryAchiever}
              alt="Legendary Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Legendary Achiever</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 25 different projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Profile-page">
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styels.subdiv}>
          <div className={styels.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to={() =>
                employeeLevel(decodedJwt.userRole)
              }
              onClick={handleAboutMe}
            >
              <AiOutlineLeft style={{ fontSize: "32px" }} />
            </NavLink>
            <h1 className={styels.head}>My Profile</h1>
            {/* <Tippy content="View Success Factors Profile" placement="bottom">
              <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${window.localStorage.getItem("userId")}`, '_blank')} src={exportIcon} alt="link" className={styels.link} />
            </Tippy> */}
          </div>
          <div className={styels.subSection}>
            <div className={styels.profileSection}>
              <div className="Profilediv">
                <img
                  src={userProfilePhoto}
                  alt="User Profile Photo"
                  width="230px"
                  height="255px"
                />
                {/* <div
                  className={styels.profile}
                  style={{
                    backgroundImage: `url(${userProfilePhoto})`,
                  }}
                >
                  <img
                    onClick={handleOpen}
                    className={styels.topBadge}
                    src={exemplaryExpert}
                    alt="Exemplary Expert Badge"
                  />
                  <img
                    onClick={handleOpen}
                    className={styels.bottomBadge}
                    src={noviceAchiever}
                    alt="Exemplary Expert Badge"
                  />
                </div> */}
              </div>
              {/* <div className={styels.badgesTitle}>
                <button>Exemplary Expert </button>
                <button>Novice Achiever</button>
              </div> */}
              <div className={styels.aboutdiv}>
                <h1>About me</h1>
                <textarea
                  placeholder="Write something in 100 characters."
                  value={msg}
                  onChange={handleChange}
                  maxLength={maxCharacters}
                />
              </div>
            </div>
            <div className={styels.detailSection}>
              <div className={styels.userDetails}>
                <div className={styels.skillDetails}>
                  <div className={styels.info}>
                    <h3>Name: </h3>
                    <p>{window.localStorage.getItem("userName")}</p>
                  </div>
                  <div className={styels.info}>
                    <h3>Function: </h3>
                    <p>
                      {profileData?.department === "null" ? "-" : profileData?.department}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Division: </h3>
                    <p>
                      {profileData?.division === "null" ? "-" : profileData?.division}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>SBU: </h3>
                    <p>
                      {profileData?.sbu === "null" ? "-" : profileData?.sbu}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Skills:</h3>
                    <ul className={styels.skillsList}>
                      {skillsToShow && skillsToShow.some(skill => skill !== "null")
                        ? skillsToShow.map((item, index) => (
                          <li key={index}>{item}</li>
                        ))
                        : <p>-</p>}
                      {hasMoreSkills && (
                        <li style={{ cursor: 'pointer' }} onClick={toggleReadMore}>
                          {readMore ? 'Show Less' : 'Read More'}
                        </li>
                      )}
                    </ul>
                  </div>
                  {/* <div className={styels.info}>
                    <h3>Titan Career History: </h3>
                    <p>
                      {profileData?.function === "null" ? "-" : profileData?.function}
                    </p>
                  </div> */}
                </div>
                <div className={styels.address}>
                  <div className={styels.info}>
                    <h3>Email: </h3>
                    <p>
                      {profileData?.email === "null" ? "-" : profileData?.email}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Contact Number: </h3>
                    <p>
                      {profileData?.phoneNumber === "null" ? "-" : profileData?.phoneNumber}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Experience: </h3>
                    <p>
                      {profileData?.experience === "null" ? "-" : profileData?.experience}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Level: </h3>
                    <p>
                      {profileData?.level === "null" ? "-" : profileData?.level}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Location: </h3>
                    <p>
                      {profileData?.orgUnit === "null" ? "-" : profileData?.orgUnit}
                    </p>
                  </div>
                  <div className={styels.info}>
                    <h3>Title: </h3>
                    <p>
                      {profileData?.title === "null" ? "-" : profileData?.title}
                    </p>
                  </div>
                  {/* <div className={styels.info}>
                    <h3>UJR: </h3>
                    <p>-</p>
                  </div>
                  <div className={styels.info}>
                    <h3>Ongoing Bridge Project: </h3>
                    <p>{projectCounts === 0 ? "-" : `${projectCounts} Project`}</p>
                  </div> */}
                </div>
              </div>
              {/* <div className={styels.workSection}>
                <h1>Previous Work: </h1>
                <div className={styels.projects}>
                  <ul className={styels.scrool}>
                    <li className={styels.project}>
                      <div className={styels.projectTitle}>
                        <h1>Graphic Designer</h1>
                        <div className={styels.rating}>
                          {Array.from({ length: 5 }).map((_, i) => (
                            <img
                              className={styels.star}
                              src={starImg}
                              alt="star"
                              key={i}
                            />
                          ))}
                        </div>
                      </div>
                      <p>Duration: 2 months</p>
                      <h4>
                        Feedback from Project Sponsor: “Your exceptional work
                        ethic, attention to detail, and collaborative approach
                        have made a significant impact on the success of the
                        project. Thank you for your valuable contributions!”
                      </h4>
                    </li>
                    <li className={styels.project}>
                      <div className={styels.projectTitle}>
                        <h1>Project Manager</h1>
                        <div className={styels.rating}>
                          {Array.from({ length: 4 }).map((_, i) => (
                            <img
                              className={styels.star}
                              src={starImg}
                              alt="star"
                              key={i}
                            />
                          ))}
                          {Array.from({ length: 1 }).map((_, i) => (
                            <img
                              className={styels.star}
                              src={emptyStar}
                              alt="star"
                              key={i}
                            />
                          ))}
                        </div>
                      </div>
                      <p>Duration: 2 months</p>
                      <h4>
                        Feedback from Project Sponsor: “Your exceptional work
                        ethic, attention to detail, and collaborative approach
                        have made a significant impact on the success of the
                        project. Thank you for your valuable contributions!”
                      </h4>
                    </li>
                    <li className={styels.project}>
                      <div className={styels.projectTitle}>
                        <h1>Graphic Designer</h1>
                        <div className={styels.rating}>
                          {Array.from({ length: 5 }).map((_, i) => (
                            <img
                              className={styels.star}
                              src={starImg}
                              alt="star"
                              key={i}
                            />
                          ))}
                        </div>
                      </div>
                      <p>Duration: 2 months</p>
                      <h4>
                        Feedback from Project Sponsor: “Your exceptional work
                        ethic, attention to detail, and collaborative approach
                        have made a significant impact on the success of the
                        project. Thank you for your valuable contributions!”
                      </h4>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Profile;
