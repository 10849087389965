import React, { useState, useRef, useEffect, useCallback } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import CreatableSelect from 'react-select/creatable';
import { DateRangePicker, SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import ReactCrop, { centerCrop, makeAspectCrop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import styels from "./CreateProject.module.css";
import classes from "./Drafts.module.css";
import deleteIcon from "../../assets/delete.png";
import draftIcon from "../../assets/draft.png";
import uploadIcon from "../../assets/upload.png";
import redDelete from "../../assets/deleteRed.png";
import moment from 'moment';
import {
  createProject,
  getDivision,
  getSbu,
  getOrgUnit,
  getDepartment,
  getFunction,
  getRequiredSkill,
  getImproveLearnSkill,
  sendMessage,
  addSkill,
  getSponsorProjectSuggestions,
  deleteDraftUpload,
} from "../../services/index";
import "react-dates/initialize";
import { thumbnails, banners } from "../common/Images";
import Slider from "react-slick";
import serachImg from '../../assets/search.png';
import bgImage from '../../assets/BackGround/bg5web.png';
import { dateFormat } from "../../util/commenFunctions";
import ReactPlayer from "react-player";

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ]

};

const coverSettings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: false,
      }
    },
  ]
};

const CreateProject = (props) => {
  const navigate = useHistory();
  const mediaRef = useRef();
  const formRef = useRef();
  const thumbnailRef = useRef();
  const [instructionFiles, setInstructionFiles] = useState([]);
  const [thumbnailFile, setThumbnailFile] = useState(null);
  const [keynote, setKeynote] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [requiredSkills, setRequiredSkills] = React.useState([]);
  const [improvedSkills, setImprovedSkills] = React.useState([]);
  const [saveType, setSaveType] = React.useState(null);
  const [dateRange, setDateRange] = useState({
    startDate: null,
    endDate: null,
  });
  const [focusedInput, setFocusedInput] = useState(null);
  const [divisions, setDivisions] = useState([]);
  const [sbus, setSbus] = useState([]);
  const [orgUnits, setOrgUnits] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [functions, setFunctions] = useState([]);
  const [skillRequired, setSkillRequired] = useState([]);
  const [skillImproveLearn, setSkillImproveLearn] = useState([]);
  const [focusedLastDate, setFocusedLastDate] = useState(null);
  const [thumbnailImgSrc, setThumbnailImgSrc] = useState("");
  const [coverImgSrc, setCoverImgSrc] = useState("");
  const imgThumbnailRef = useRef(null);
  const imgCoverRef = useRef(null);
  const [crop, setCrop] = useState();
  const [aspect, setAspect] = useState(16 / 9);
  const coverRef = useRef(null);
  const [croppedThumbnailImageUrl, setCroppedThumbnailImageUrl] =
    useState(null);
  const [croppedCoverImageUrl, setCroppedCoverImageUrl] = useState(null);
  const [openForAll, setOpenForAll] = useState(false);
  const [localThumbnail, setLocalThumbnail] = useState(null);
  const [localThumbnailIndex, setLocalThumbnailIndex] = useState(0);
  const [localCover, setLocalCover] = useState(null);
  const [localCoverIndex, setLocalCoverIndex] = useState(0);
  const [selectedDivision, setSelectedDivision] = useState({});
  const [selectedSbu, setSelectedSbu] = useState({});
  const [selectedOrgUnit, setSelectedOrgUnit] = useState({});
  const [selectedDepartment, setSelectedDepartment] = useState({});
  const [selectedFunction, setSelectedFunction] = useState({});
  const [projectData, setProjectData] = useState(null);
  const [signedUrls, setSignedUrls] = useState({});
  const [searchInput, setSearchInput] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const today = moment();
  const june17ThisYear = moment(new Date(today.year(), 5, 19));
  const initialDate = today.isBefore(june17ThisYear) ? june17ThisYear : null;
  const [lastDate, setLastDate] = useState(initialDate);

  const getProjectRecommendations = async (userInput) => {
    setSearchInput(userInput);
    if (userInput.trim().length === 0) {
      setSearchResult([]);
      return;
    }
    const sponsorData = {
      userId: window.localStorage.getItem("userId"),
      searchText: userInput.trim()
    }
    const response = await getSponsorProjectSuggestions(sponsorData);
    if (response.data.status === "200") {
      setSearchResult(response.data.projects);
    }
  }


  const handleOpenForAllChange = (e) => {
    setOpenForAll(e.target.checked);
  };

  function getCroppedImg(image, crop) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    if (ctx) {
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob((blob) => {
        if (!blob) {
          reject(new Error("Canvas is empty"));
          return;
        }
        resolve(blob);
      }, "image/jpeg");
    });
  }

  const onCropComplete = useCallback(async (crop, onChange, imgName) => {
    let result;
    if (imgName === "cover") {
      result = imgCoverRef;
    } else {
      result = imgThumbnailRef;
    }
    if (result.current && crop.width && crop.height) {
      try {
        const blob = await getCroppedImg(result.current, crop);
        const croppedImageFile = new File([blob], "cropped-image.jpeg", {
          type: "image/jpeg",
        });
        const croppedImageURL = URL.createObjectURL(croppedImageFile);
        if (imgName === "cover") {
          setCroppedCoverImageUrl(croppedImageURL);
          onChange(croppedImageFile);
        } else {
          setCroppedThumbnailImageUrl(croppedImageURL);
          onChange(croppedImageFile);
        }
      } catch (e) {
        console.error("Failed to crop image:", e);
      }
    }
  }, []);

  function onSelectFile(e, imgName) {
    if (e.target.files && e.target.files.length > 0) {
      setCrop(undefined);
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        imgName === "cover"
          ? setCoverImgSrc(reader.result.toString() || "")
          : setThumbnailImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onImageLoad(e) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }

  const mesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>Your team member [Sponsor Name] has submitted a Bridge Project for your approval. As next steps, please review the project details and proceed to:</p>

<p>
<a href="[Approve URL]" style="text-decoration: none;">
  <button style="background-color: #4CAF50; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-right: 10px;">Approve</button>
</a>

or

<a href="[Reject URL]" style="text-decoration: none;">
  <button style="background-color: #FF0000; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-left: 10px;">Recommend Modification</button>
</a>
</p>

<p>Once you approve, the project will be made available to all eligible Applicants on the platform.</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>The details of the project are shared in the table below; we thank you for your sponsorship in enabling development and careers for Titanians!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>
<a href="[Approve URL]" style="text-decoration: none;">
  <button style="background-color: #4CAF50; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-right: 10px;">Approve</button>
</a>

or

<a href="[Reject URL]" style="text-decoration: none;">
  <button style="background-color: #FF0000; color: white; padding: 10px 15px; border: none; border-radius: 5px; cursor: pointer; margin-left: 10px;">Recommend Modification</button>
</a>
</p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const sponsorMesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>Your Bridge Project has been successfully submitted to [Manager Name] for approval.</p>

<p>Once [Manager Name] approves, the project will be made available to all eligible Applicants on the platform.</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>The details of the project are shared in the table below; we thank you for your sponsorship in enabling development and careers for Titanians!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const sponsorAutoApproveMesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>This is to confirm successful submission of your Bridge Project "[Project ID]: [Project Title]".</p>

<p>The project is now under 'Open Projects' section and will be available to all eligible Applicants on the platform.</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>The details of the project are shared in the table below; we thank you for your sponsorship in enabling development and careers for Titanians!</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>
Regards,<br/>
Team Titan Career Vista
</p>
  `;

  const onCreate = async (data) => {
    if ((saveType === "save" || saveType === "pending") && (data.dateRange.startDate === null || data.dateRange.endDate === null)) {
      toast.error("Please select start date and end date");
      return;
    }
    if ((saveType === "save" || saveType === "pending") && data.All === false && data.L1 === false && data.L2 === false && data.L3 === false && data.L4 === false && data.L5 === false && data.L6 === false && data.L7 === false && data.L8 === false && data.L9 === false) {
      toast.error("Please select at least one employee level");
      return;
    }
    if (data.dateRange.startDate && data.dateRange.endDate) {
      const startDate = new Date(data.dateRange.startDate._d);
      const endDate = new Date(data.dateRange.endDate._d);
      const duration = endDate - startDate;
      const oneMonth = 30 * 24 * 60 * 60 * 1000;
      if (duration < oneMonth) {
        toast.error("The duration of the project must be at least one month.");
        return;
      }
    }

    const formData = new FormData();
    let trueValues = Object.keys(data).filter((key) => data[key] === true);
    if (openForAll === true) {
      trueValues = ["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"];
    }
    formData.append("localThumbnail", localThumbnailIndex);
    formData.append("localCover", localCoverIndex);
    formData.append("thumbnail", data.thumbnail);
    formData.append("cover", data.cover);
    formData.append("openPositions", data.positions);
    if (data.instructions) {
      data.instructions.map((item) => {
        formData.append("instructions", item);
      });
    }
    formData.append("title", data.title);
    formData.append("description", data.description);
    if (data.division || selectedDivision) {
      const divisionValue = data.division && data.division.length ? data.division : selectedDivision;
      if (divisionValue) {
        formData.append("division", JSON.stringify(divisionValue));
      }
    }
    if (data.sbu || selectedSbu) {
      const sbuValue = data.sbu && data.sbu.length ? data.sbu : selectedSbu;
      if (sbuValue) {
        formData.append("sbu", JSON.stringify(sbuValue));
      }
    }
    if (data.orgUnit || selectedOrgUnit) {
      const orgUnitValue = data.orgUnit && data.orgUnit.length ? data.orgUnit : selectedOrgUnit;
      if (orgUnitValue) {
        formData.append("orgUnit", JSON.stringify(orgUnitValue));
      }
    }
    if (data.department || selectedDepartment) {
      const departmentValue = data.department && data.department.length ? data.department : selectedDepartment;
      if (departmentValue) {
        formData.append("department", JSON.stringify(departmentValue));
      }
    }
    if (data.function || selectedFunction) {
      const functionValue = data.function && data.function.length ? data.function : selectedFunction;

      if (functionValue) {
        formData.append("function", JSON.stringify(functionValue));
      }
    } else {
      formData.append("function", "");
    }
    trueValues.map((item) => {
      formData.append("employeeLevels[]", item);
    });
    if (data.requiredSkills) {
      data.requiredSkills.map((item) => {
        formData.append("requiredSkills[]", JSON.stringify(item));
      });
    }

    if (data.improveLearnSkills) {
      data.improveLearnSkills.map((item) => {
        formData.append("improveLearnSkills[]", JSON.stringify(item));
      });
    }
    formData.append("keyDeliverables", data.keyDeliverables);
    if (data.weeklyTime) {
      formData.append("weeklyTime", data.weeklyTime);
    }
    if (data.dateRange.startDate) {
      formData.append("startDate", new Date(data.dateRange.startDate._d).getTime());
    }
    if (data.dateRange.endDate) {
      formData.append("endDate[]", new Date(data.dateRange.endDate._d).getTime());
    }
    if (data.lastDate) {
      formData.append("lastDate", new Date(data.lastDate._d).getTime());
    }
    formData.append("typeOfRequirement", data.typeOfRequirement);
    formData.append("status", saveType);
    formData.append("role", "sponsor");
    formData.append("createdBy", JSON.stringify({
      id: window.localStorage.getItem("userId"),
      name: window.localStorage.getItem("userName"),
      email: window.localStorage.getItem("userEmail"),
      level: window.localStorage.getItem("userLevel"),
      division: window.localStorage.getItem("userDivision"),
      function: window.localStorage.getItem("userDepartment"),
    }))
    formData.append("sponsorId", window.localStorage.getItem("userId"));
    formData.append("sponsorName", window.localStorage.getItem("userName"));
    formData.append("managerName", window.localStorage.getItem("managerName"));
    formData.append("managerEmail", window.localStorage.getItem("managerEmail"));
    try {
      const infoToastId = toast.info(`Please wait while we save your project...`, { autoClose: false });
      setIsDisabled(true);
      const result = await createProject(formData);
      if (result.data.status === "200") {
        if (saveType === "pending") {
          const dataToSendManager = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("managerEmail"),
            titleName: window.localStorage.getItem("managerName"),
            sponsorName: window.localStorage.getItem("userName"),
            subject: `Bridge Project "[Project ID]" submitted for your approval by "[Sponsor Name]"`,
            message: mesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendManager);
          const dataToSendSponsor = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("userEmail"),
            titleName: window.localStorage.getItem("userName"),
            managerName: window.localStorage.getItem("managerName"),
            subject: `Your Bridge Project "[Project ID]" submitted successfully for approval`,
            message: sponsorMesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendSponsor);
        } else if (saveType === "open") {
          const dataToSendSponsorAutoApprove = {
            fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
            toEmail: window.localStorage.getItem("userEmail"),
            titleName: window.localStorage.getItem("userName"),
            managerName: window.localStorage.getItem("managerName"),
            subject: `Your Bridge Project "[Project ID]" submitted successfully for approval`,
            message: sponsorAutoApproveMesg,
            id: result.data.project._id,
            projectId: result.data.project.projectId,
            projectTitle: result.data.project.title,
            projectDescription: result.data.project.description,
            projectStatus: result.data.project.status,
            startDate: moment(result.data.project.startDate).format("DD-MM-YYYY"),
            endDate: moment(Number(result.data.project.endDate[result.data.project.endDate.length - 1])).format("DD-MM-YYYY"),
            openTo: result.data.project.employeeLevels.join(", "),
            skillRequired: result.data.project.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
            timeCommitment: result.data.project.weeklyTime,
            type: result.data.project.typeOfRequirement,
            keyDeliverables: result.data.project.keyDeliverables,
          };
          await sendMessage(dataToSendSponsorAutoApprove);
        }
        reset();
        toast.dismiss(infoToastId);
        toast.success(`Project ${saveType === "pending" ? "Submitted for Approval"
          : saveType === "open" ? "created successfully" : saveType === "draft" ? `saved as draft` : "moved to trash"}`, { autoClose: 5000 });
        setTimeout(() => {
          navigate.push("/sponsor/dashboard");
        }, 500);
        setIsDisabled(false);
      } else if (result.data.status === "404") {
        toast.dismiss(infoToastId);
        toast.error("You are recommended to Sponsor not more than two ongoing projects simultaneously. You  are recommended to save the project as a draft and submit it when atleast one of your ongoing projects gets completed", { autoClose: 5000 });
        setIsDisabled(false);
      } else {
        toast.dismiss(infoToastId);
        toast.error(
          "Something went wrong, Please try again."
        );
        setIsDisabled(false);
      }
    } catch (e) {
      toast.error("Something went wrong, Please try again.", { autoClose: 5000 });
      setIsDisabled(false);
    }
  };





  const handleInvalidSubmit = (errors) => {
    if (errors) {
      toast.error("Please fill all the required fields")
    }
    if (errors.function) {
      toast.error("Function is required. Please select a function before submitting.", {
      });
    }
    if (errors.typeOfRequirement) {
      toast.error("type of project is required. Please select project type requirement before submitting.", {
      });
    }
  };

  const divisionList = async () => {
    const res = await getDivision();
    if (res.data.status === "200") {
      const finalData = res.data.divisions.map((item) => {
        return {
          label: item.division,
          value: item.division,
          id: item._id,
        };
      });
      setDivisions(finalData);
    }
  };

  const sbuList = async (divisionId) => {
    const res = await getSbu(divisionId);
    if (res.data.status === "200") {
      const finalData = res.data.sbus.map((item) => {
        return {
          label: item.sbu,
          value: item.sbu,
          id: item._id,
        };
      });
      setSbus(finalData);
    }
  };

  const orgUnitList = async (sbuId) => {
    const res = await getOrgUnit(sbuId);
    if (res.data.status === "200") {
      const finalData = res.data.orgUnits.map((item) => {
        return {
          label: item.orgUnit,
          value: item.orgUnit,
          id: item._id,
        };
      });
      setOrgUnits(finalData);
    }
  };

  const departmentList = async (sbuId) => {
    const res = await getDepartment(sbuId);
    if (res.data.status === "200") {
      const finalData = res.data.departments.map((item) => {
        return {
          label: item.department,
          value: item.department,
          id: item._id,
        };
      });
      setDepartments(finalData);
    }
  };

  const functionList = async () => {
    const res = await getFunction();
    if (res.data.status === "200") {
      const finalData = res.data.functions.map((item) => {
        return {
          label: item.function,
          value: item.function,
          id: item._id,
        };
      });
      setFunctions(finalData);
    }
  };

  const requiredSkillsList = async () => {
    const res = await getRequiredSkill();
    if (res.data.status === "200") {
      const finalData = res.data.requiredSkills.map((item) => {
        return {
          label: item.skill,
          value: item.skill,
          id: item._id,
        };
      });
      setSkillRequired(finalData);
    }
  };

  const improveLearnSkillList = async (skillData) => {
    // const skillOfIds = skillData.map((obj) => obj.id);
    // const requestData = {
    //   skillOfIds,
    // };
    const res = await getImproveLearnSkill();
    if (res.data.status === "200") {
      const finalData = res.data.improveLearnSkills.map((item) => {
        return {
          label: item.improveLearnSkills,
          value: item.improveLearnSkills,
          id: item._id,
        };
      });
      setSkillImproveLearn(finalData);
    }
  };

  const {
    control,
    register,
    setError,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
    setValue,
    clearErrors
  } = useForm();

  const goToTrashPage = () => {
    navigate.push("/sponsor/trash");
  };

  const goToDraftsPage = () => {
    navigate.push("/sponsor/drafts");
  };


  const handleSelectThumbnail = (thumb) => {
    // setThumbnailImgSrc(""); // Clearing the src for uploaded image
    // setCroppedThumbnailImageUrl(null); // Clearing cropped image URL
    setLocalThumbnail(thumb.path); // Setting the selected thumbnail from slider
    setLocalThumbnailIndex(thumb.key)
    // thumbnailRef.current.value = "";
  }

  const handleSelectCover = (thumb) => {
    // setCoverImgSrc(""); // Clearing the src for uploaded image
    // setCroppedCoverImageUrl(null); // Clearing cropped image URL
    setLocalCover(thumb.path); // Setting the selected thumbnail from slider
    setLocalCoverIndex(thumb.key)
    // coverRef.current.value = "";
  }

  const removeFile = (indexToRemove) => {
    setInstructionFiles((currentFiles) =>
      currentFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  const postSkill = async (skill) => {
    const finalData = {
      improveLearnSkills: skill
    }
    const res = await addSkill(finalData);
    if (res.data.status === "200") {
      toast.success("Skill added successfully");
    }
  }

  const selectAutoDivision = (data) => {
    const division = data.find(division => division.value === window.localStorage.getItem('userDivision'))
    sbuList(division?.id);
    setSelectedDivision(division)
  }

  const selectAutoSbu = (data) => {
    const sbu = data.find(sbu => sbu.value === window.localStorage.getItem('userSbu'))
    orgUnitList(sbu?.id);
    departmentList(sbu?.id);
    setSelectedSbu(sbu)
  }

  const selectAutoOrgUnit = (data) => {
    const orgUnit = data.find(orgUnit => orgUnit.value === window.localStorage.getItem('userOrgUnit'))
    setSelectedOrgUnit(orgUnit)
  }

  const selectAutoDepartment = (data) => {
    const department = data.find(department => department.value === window.localStorage.getItem('userDepartment'))
    setSelectedDepartment(department)
  }

  const selectAutoFunction = (data) => {
    const funData = data.find(fun => fun.value === window.localStorage.getItem('userFunction'))
    setSelectedFunction(funData)
  }

  useEffect(() => {
    divisions.length && selectAutoDivision(divisions)
  }, [divisions.length])

  useEffect(() => {
    sbus.length && selectAutoSbu(sbus)
  }, [sbus.length])

  useEffect(() => {
    orgUnits.length && selectAutoOrgUnit(orgUnits)
  }, [orgUnits.length])

  useEffect(() => {
    departments.length && selectAutoDepartment(departments)
  }, [departments.length])

  useEffect(() => {
    functions.length && selectAutoFunction(functions)
  }, [functions.length])

  useEffect(() => {
    divisionList();
    functionList();
    // requiredSkillsList();
    improveLearnSkillList();
  }, []);

  const getValidationRules = (fieldName) => {
    if (fieldName === 'All') {
      // Check if any of the individual checkboxes are selected
      const anySelected = ["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"].some(l => watch(l));
      return anySelected ? {} : { required: 'At least one option must be selected' };
    }
    return {};
  };

  const handleLevelChange = (label, checked) => {
    setValue(label, checked);
    const anySelected = checked || ["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"].some(l => watch(l));
    if (anySelected) {
      clearErrors(['All', 'L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8', 'L9']);
      setValue('All', false);
      setOpenForAll(false);
    } else {
      setValue('All', true, { shouldValidate: true });
    }
  };

  const deleteUploadedFile = async (index) => {
    const response = await deleteDraftUpload({ id: projectData._id, index: index });
    if (response.data.status === "200") {
      setProjectData(projectData => {
        const updatedInstructions = projectData?.instructions.filter((_, i) => i !== index);
        return { ...projectData, instructions: updatedInstructions };
      });
    }
  }

  const copyDetails = (project) => {
    setProjectData(project);
    setIsFocused(false)
    setSearchInput("")
  }

  useEffect(() => {
    if (projectData) {
      const parsedDivision = projectData.division && JSON.parse(projectData.division);
      const parsedSbu = projectData.sbu && JSON.parse(projectData.sbu);
      const parsedOrgUnit = projectData.orgUnit && JSON.parse(projectData.orgUnit);
      const parsedDepartment = projectData.department && JSON.parse(projectData.department);
      const parsedFunction = projectData.function && JSON.parse(projectData.function);
      const parsedSkills = projectData.requiredSkills && projectData.requiredSkills.map(skill => JSON.parse(skill));
      const parsedImproveLearnSkills = projectData.improveLearnSkills && projectData.improveLearnSkills.map(skill => JSON.parse(skill));
      const startDateMoment = projectData.startDate ? moment(parseInt(projectData.startDate)) : null;
      const endDateMoment = projectData.endDate && projectData.endDate.length > 0
        ? moment(parseInt(projectData.endDate[0]))
        : null;
      const lastDateMoment = projectData.lastDate ? moment(parseInt(projectData.lastDate)) : null;
      const levels = ['L1', 'L2', 'L3', 'L4', 'L5', 'L6', 'L7', 'L8', 'L9'];
      levels.forEach((level) => {
        setValue(level, false);
      });
      const hasAllLevels = levels.every(level => projectData.employeeLevels.includes(level));
      setOpenForAll(hasAllLevels);
      if (!hasAllLevels) {
        projectData.employeeLevels.forEach((level) => {
          setValue(level, true);
        });
      }
      if (projectData.instructions.length) {
        projectData.instructions.forEach((resource) => {
          setSignedUrls(prevUrls => ({ ...prevUrls, [resource]: resource }));
        });
      }

      setDescription(projectData.description);
      setKeynote(projectData.keyDeliverables);
      setSelectedDivision(parsedDivision);
      setSelectedSbu(parsedSbu);
      setSelectedOrgUnit(parsedOrgUnit);
      setSelectedDepartment(parsedDepartment);
      if (parsedFunction) {
        setSelectedFunction(parsedFunction);
      } else {
        setSelectedFunction({})
      }
      setDateRange({
        startDate: startDateMoment,
        endDate: endDateMoment
      });
      setLastDate(lastDateMoment);
      setLocalCoverIndex(Number(projectData.localCover));
      setLocalThumbnailIndex(Number(projectData.localThumbnail));
      if (projectData.requiredSkills.length) {
        setValue("requiredSkills", projectData.requiredSkills.map((each) => JSON.parse(each)));
        setRequiredSkills(parsedSkills);
      }
      if (projectData.improveLearnSkills.length) {
        setValue("improveLearnSkills", projectData.improveLearnSkills.map((each) => JSON.parse(each)));
        setImprovedSkills(parsedImproveLearnSkills)
      }

      setValue('title', projectData.title);
      setValue('positions', projectData.openPositions);
      setValue('weeklyTime', projectData.weeklyTime);
      setValue('typeOfRequirement', projectData.typeOfRequirement);
      setValue('dateRange.startDate', startDateMoment);
      setValue('dateRange.endDate', endDateMoment);
      setValue('lastDate', lastDateMoment);
      setValue('division', parsedDivision, { shouldValidate: true });
      setValue('sbu', parsedSbu, { shouldValidate: true });
      setValue('orgUnit', parsedOrgUnit, { shouldValidate: true });
      setValue('department', parsedDepartment, { shouldValidate: true });
      setValue('instructions', projectData.instructions, { shouldValidate: true });
      setValue('employeeLevels', projectData.employeeLevels, { shouldValidate: true });
      if (parsedFunction) {
        setValue('function', parsedFunction, { shouldValidate: true });
      }
      if (projectData.keyDeliverables) {
        setValue('keyDeliverables', projectData.keyDeliverables, { shouldValidate: true });
      }
      if (projectData.description) {
        setValue('description', projectData.description, { shouldValidate: true });
      }
    }
  }, [projectData, setValue, setOpenForAll, setDateRange, setLastDate, setThumbnailImgSrc, setCoverImgSrc]);

  const validateDescription = (value) => {
    if (value === '<p><br></p>' || value.trim() === '') {
      return 'This field is required';
    }
    return true;
  };

  return (
    <div className="Profile-page">
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styels.subdiv}>
          <div className={styels.backdiv}>
            <div className={styels.arrowDiv}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/sponsor/dashboard"
              >
                <AiOutlineLeft style={{ fontSize: "32px" }} />
              </NavLink>
              <h1 className={styels.head}>Create a project</h1>
            </div>
            <div className={styels.draftCont}>
              <div className={styels.trashDiv} onClick={goToTrashPage}>
                <img src={deleteIcon} alt="deleteIcon" />
                <p>Trash</p>
              </div>
              <div className={styels.draftDiv} onClick={goToDraftsPage}>
                <img src={draftIcon} alt="deleteIcon" />
                <p>Drafts</p>
              </div>
            </div>
          </div>
          <div className={styels.searchDiv}>
            <label htmlFor="search" className={styels.titleText}>
              Copy project details from an existing project
            </label>

            <div className={styels.searchInputDiv}>
              <img src={serachImg} className={styels.serachImg} />
              <input id='search' type="search" onChange={(e) => getProjectRecommendations(e.target.value)} value={searchInput} className={styels.searchInput}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setTimeout(() => setIsFocused(false), 1000)}
                placeholder="Search Project" />
            </div>
          </div>
          {isFocused && searchInput.trim() && (
            searchResult.length > 0 ? (
              <div className={styels.resultsPopup}>
                {searchResult.map((project, index) => (
                  <div className={classes.draft} key={index}>
                    <div className={classes.draftHeader}>
                      <div className={classes.thumdiv}>
                        <img
                          src={
                            project.thumbnail === "" || project.thumbnail === "undefined"
                              ? thumbnails[project.localThumbnail]?.path
                              : project.thumbnail
                          }
                          alt="thumbnail"
                          className={classes.thumbnail}
                        />
                      </div>
                      <div className={classes.draftTitle}>
                        <h1>{project.title}</h1>
                        <p>Posted on {dateFormat(project.createdAt)}</p>
                        <p>{JSON.parse(project.createdBy).name}</p>
                      </div>
                    </div>
                    <div className={classes.exportdiv}>
                      <button className={styels.copyBtn} onClick={() => copyDetails(project)}>Copy Details</button>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styels.resultsPopup}>
                <h1 className={styels.titleText}>No project found</h1>
              </div>
            )
          )}
          <form
            onSubmit={handleSubmit(onCreate, handleInvalidSubmit)}
            encType="multipart/form-data"
            ref={formRef}
          >
            <div>

              <label htmlFor="title" className={styels.titleText}>
                Title
              </label>
              <input
                placeholder="Enter title"
                id="title"
                className={styels.titleInput}
                type="text"
                {...register("title", { required: true })}
              />
              <p>{errors.title?.message}</p>
            </div>
            <div className={styels.descriptionMargin}>
              <label htmlFor="description" className={styels.titleText}>
                Description
              </label>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: saveType !== 'trash' && saveType !== 'draft' ? 'Description is required' : false,
                  validate: saveType !== 'trash' && saveType !== 'draft' ? validateDescription : undefined,
                }}
                defaultValue={description}
                render={({ field }) => (
                  <ReactQuill
                    placeholder="Enter description"
                    style={{ height: "150px" }}
                    theme="snow"
                    id="description"
                    value={description}
                    onChange={newDescription => {
                      setDescription(newDescription);
                      setValue('description', newDescription, { shouldValidate: true });
                    }}
                  />
                )}
              />
            </div>
            {errors.description && <p style={{ color: "red" }}>{errors.description.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="division">
                Division
              </label>
              <Controller
                name="division"
                control={control}
                defaultValue={divisions}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (selectedDepartment && Object.keys(selectedDivision).length === 0) ? 'Division is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select division"
                    name="division"
                    options={divisions}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      setSelectedDivision(values);
                      sbuList(values.id);
                    }}
                    value={selectedDivision}
                  />
                )}
              />
            </div>
            {errors.division && <p style={{ color: "red" }}>{errors.division.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="sbu">
                SBU
              </label>
              <Controller
                name="sbu"
                control={control}
                defaultValue={sbus}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (selectedSbu && Object.keys(selectedSbu).length === 0) ? 'SBU is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select SBU"
                    name="sbu"
                    options={sbus}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      setSelectedSbu(values);
                      orgUnitList(values.id);
                      departmentList(values.id);
                    }}
                    value={selectedSbu}
                  />
                )}
              />
            </div>
            {errors.sbu && <p style={{ color: "red" }}>{errors.sbu.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="orgUnit">
                Org Unit
              </label>
              <Controller
                name="orgUnit"
                control={control}
                defaultValue={orgUnits}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (selectedOrgUnit && Object.keys(selectedOrgUnit).length === 0) ? 'Org Unit is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select org unit"
                    name="orgUnit"
                    options={orgUnits}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      setSelectedOrgUnit(values);
                    }}
                    value={selectedOrgUnit}
                  />
                )}
              />
            </div>
            {errors.orgUnit && <p style={{ color: "red" }}>{errors.orgUnit.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="department">
                Department
              </label>
              <Controller
                name="department"
                control={control}
                defaultValue={departments}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (selectedDepartment && Object.keys(selectedDepartment).length === 0) ? 'Department is required' : false, }}
                render={({ field }) => (
                  <Select
                    placeholder="Select department"
                    name="department"
                    options={departments}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      setSelectedDepartment(values);
                    }}
                    value={selectedDepartment}
                  />
                )}
              />
            </div>
            {errors.department && <p style={{ color: "red" }}>{errors.department.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="function">
                Function
              </label>
              <Controller
                name="function"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' && (selectedFunction && Object.keys(selectedFunction).length === 0) ? 'Function is required' : false, }}
                defaultValue={functions}
                render={({ field }) => (
                  <Select
                    placeholder="Select function"
                    name="function"
                    options={functions}
                    classNamePrefix="Select"
                    onChange={(values) => {
                      field.onChange(values);
                      setSelectedFunction(values);
                    }}
                    value={selectedFunction}
                  />
                )}
              />
            </div>
            {errors.function && <p style={{ color: "red" }}>{errors.function.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="positions">
                Number of open positions
              </label>
              <input className={styels.input} type="number" min="1" {...register("positions", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Number of open positions is required' : false })} />
            </div>
            {errors.positions && <p style={{ color: "red" }}>{errors.positions.message}</p>}
            <div className={styels.checkDiv}>
              <h6 className={`${styels.titleText} ${styels.checkText}`}>
                Open to
              </h6>
              <input
                type="checkbox"
                id="All"
                checked={openForAll}
                onChange={handleOpenForAllChange}
              />
              <label htmlFor="All" className={styels.cursorPointer}>
                Open for all
              </label>
              {["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9"].map(
                (label) => (
                  <React.Fragment key={label}>
                    <input
                      type="checkbox"
                      id={label}
                      {...register(label)}
                      disabled={openForAll}
                      checked={openForAll ? false : watch(label)}
                    />
                    <label
                      htmlFor={label}
                      className={
                        openForAll
                          ? styels.checkboxDisabled
                          : styels.cursorPointer
                      }
                    >
                      {label}
                    </label>
                  </React.Fragment>
                )
              )}
            </div>
            <div className="mt-4">
              <label className={styels.titleText} htmlFor="skills">
                Add required Skills
              </label>
              <Controller
                name="requiredSkills"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select skills required' : false, }}
                render={({ field }) => (
                  <CreatableSelect
                    isMulti
                    name="skills"
                    options={skillImproveLearn}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(values) => {
                      field.onChange(values);
                      setRequiredSkills(values);
                    }}
                    onCreateOption={(inputValue) => {
                      const newValue = { label: inputValue, value: inputValue };
                      setSkillImproveLearn((prev) => [...prev, newValue]);
                      setValue('requiredSkills', [...requiredSkills, newValue], { shouldValidate: true });
                      setRequiredSkills((prev) => [...prev, newValue]);
                      postSkill(inputValue)
                    }}
                    value={requiredSkills}
                    defaultValue={requiredSkills}
                    placeholder="Select skills"

                  />
                )}
              />
            </div>
            {errors.requiredSkills && <p style={{ color: "red" }}>{errors.requiredSkills.message}</p>}
            <div className="mt-4">
              <label className={styels.titleText} htmlFor="improve">
                Add Skills to improve/ learn
              </label>
              <Controller
                name="improveLearnSkills"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select skills to improve/ learn' : false, }}
                render={({ field }) => (
                  <CreatableSelect
                    isMulti
                    name="improveSkills"
                    options={skillImproveLearn}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    onChange={(values) => {
                      field.onChange(values);
                      setImprovedSkills(values);
                    }}
                    onCreateOption={(inputValue) => {
                      const newValue = { label: inputValue, value: inputValue };
                      setSkillImproveLearn((prev) => [...prev, newValue]);
                      setValue('requiredSkills', [...requiredSkills, newValue], { shouldValidate: true });
                      setImprovedSkills((prev) => [...prev, newValue]);
                      postSkill(inputValue)
                    }}
                    value={improvedSkills}
                    defaultValue={improvedSkills}
                    placeholder="Select skills"
                  />
                )}
              />
            </div>
            {errors.improveLearnSkills && <p style={{ color: "red" }}>{errors.improveLearnSkills.message}</p>}
            <div className={styels.skillsDiv}>
              <label className={styels.titleText} htmlFor="keynote">
                Key deliverables
              </label>
              <Controller
                name="keyDeliverables"
                control={control}
                rules={{
                  required: saveType !== 'trash' && saveType !== 'draft' ? 'Please write key deliverables' : false,
                  validate: saveType !== 'trash' && saveType !== 'draft' ? validateDescription : undefined,
                }}
                defaultValue={keynote}
                render={({ field }) => (
                  <ReactQuill
                    style={{ height: "70px" }}
                    theme="snow"
                    id="keynote"
                    placeholder="Write the key deliverables by separating them through comma."
                    value={keynote}
                    onChange={newKeynote => {
                      setKeynote(newKeynote);
                      setValue('keyDeliverables', newKeynote, { shouldValidate: true });
                    }}
                  />
                )}
              />
            </div>
            {errors.keyDeliverables && <p style={{ color: "red" }}>{errors.keyDeliverables.message}</p>}
            <div className={styels.expDiv}>
              <label className={styels.titleText}>
                Weekly time commitment required
              </label>
              <select
                {...register("weeklyTime", { required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select weekly time commitment required' : false, })}
                style={{ marginLeft: "20px" }}
                className={styels.hinput}
              >
                <option
                  value=""
                  defaultValue={"Hours per week"}
                  hidden="hidden"
                >
                  Hours per week
                </option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="5">5</option>
                <option value="6">6</option>
                <option value="7">7</option>
                <option value="8">8</option>
                <option value="9">9</option>
                <option value="10">10</option>
                {/* <option value="11">11</option>
                <option value="12">12</option>
                <option value="13">13</option>
                <option value="14">14</option> */}
              </select>
            </div>
            <p style={{ color: "red" }}>{errors.weeklyTime && errors.weeklyTime.message}</p>
            <div className={styels.skillsDiv}>
              <label
                className={styels.titleText}
                style={{ marginRight: "20px" }}
                htmlFor="startEndDate"
              >
                Start Date and End Date
              </label>

              <Controller
                name="dateRange"
                control={control}
                defaultValue={dateRange}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select start date and end date' : false, }}
                render={({ field }) => (
                  <DateRangePicker
                    startDate={field.value.startDate} // momentPropTypes.momentObj or null,
                    startDateId="your_unique_start_date_id" // PropTypes.string.isRequired,
                    endDate={field.value.endDate} // momentPropTypes.momentObj or null,
                    endDateId="your_unique_end_date_id" // PropTypes.string.isRequired,
                    onDatesChange={({ startDate, endDate }) => {
                      field.onChange({ startDate, endDate }); // Update the dateRange field
                      setDateRange({ startDate, endDate }); // Update the state
                    }}
                    focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                    onFocusChange={focusedInput => setFocusedInput(focusedInput)} // PropTypes.func.isRequired,
                    // required
                    displayFormat="DD/MM/YYYY"
                    isOutsideRange={(day) => {
                      const today = moment();
                      const june3_2024 = moment('2024-06-26');
                      const start = moment(field.value.startDate);

                      // If today is before May 15, 2024, enforce start date selection from May 15, 2024
                      if (today.isBefore(june3_2024, 'day') && focusedInput === 'startDate') {
                        return day.isBefore(june3_2024, 'day');
                      }

                      // Once a start date is selected, restrict the end date to no more than 6 months later
                      if (focusedInput === 'endDate' && field.value.startDate) {
                        const maxEndDate = start.clone().add(6, 'months');
                        // Allow dates up to 6 months after the start date, disallowing past dates
                        return day.isAfter(maxEndDate) || day.isBefore(start);
                      }

                      // For start date selection, disallow past dates if today is on or after May 15, 2024
                      if (focusedInput === 'startDate') {
                        return day.isBefore(today, 'day');
                      }

                      // This line should not be reachable but is here as a fallback to disallow past dates
                      return false; // You might adjust this fallback based on your specific needs
                    }}
                  />
                )}
              />
            </div>
            {errors.dateRange && <p style={{ color: "red" }}>{errors.dateRange.message}</p>}
            <div className={styels.checkDiv}>
              <p className={styels.titleText}>Type of project</p>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? "Type of project is required" : false })}
                id="remote"
                type="radio"
                name="typeOfRequirement"
                value="Remote"
              />
              <label htmlFor="remote">Remote</label>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? "Type of project is required" : false })}
                id="wfo"
                type="radio"
                name="typeOfRequirement"
                value="Work From Office"
              />
              <label htmlFor="wfo">Work From Office</label>
              <input
                {...register("typeOfRequirement", { required: saveType !== 'trash' && saveType !== 'draft' ? "Type of project is required" : false })}
                id="hybrid"
                type="radio"
                name="typeOfRequirement"
                value="Hybrid"
              />
              <label htmlFor="hybrid">Hybrid</label>
            </div>
            {errors.typeOfRequirement && (
              <p style={{ color: "red" }}>{errors.typeOfRequirement.message}</p>
            )}
            <div className={styels.skillsDiv}>
              <label
                className={styels.titleText}
                style={{ marginRight: "20px" }}
              >
                Last date to apply
              </label>
              <Controller
                name="lastDate"
                control={control}
                rules={{ required: saveType !== 'trash' && saveType !== 'draft' ? 'Please select last date to apply' : false, }}
                defaultValue={lastDate}
                render={({ field }) => (
                  <SingleDatePicker
                    date={field.value} // momentPropTypes.momentObj or null
                    onDateChange={date => {
                      field.onChange(date); // Update the lastDate field
                      setLastDate(date); // Update the state
                    }}
                    focused={focusedLastDate} // PropTypes.bool
                    onFocusChange={({ focused }) => setFocusedLastDate(focused)} // PropTypes.func.isRequired
                    id="lastDate" // PropTypes.string.isRequired,
                    numberOfMonths={1}
                    placeholder="Last Date"
                    displayFormat="DD/MM/YYYY"
                    isOutsideRange={(day) => {
                      const today = moment();
                      const june3_2024 = moment('2024-06-19');
                      return day.isBefore(today, 'day') || day.isBefore(june3_2024, 'day');
                    }}
                  />
                )}
              />
            </div>
            {errors.lastDate && <p style={{ color: "red" }}>{errors.lastDate.message}</p>}
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Additional Details
              </p>
              <p className={styels.instructionText}>
                Upload details of what this project would entail to offer applicants a better idea of the project before applying. Supported formats: audio (mp3), video (mp4) or documents (pdf)
              </p>
              <div className={styels.uploaddiv}>
                <Controller
                  name="instructions"
                  control={control}
                  defaultValue={projectData?.instructions}
                  render={({ field: { onChange, value } }) => (
                    <input
                      ref={mediaRef}
                      type="file"
                      accept=".mp4, .avi, .mov, .mp3, .wav, .ogg, .pdf"
                      style={{ display: "none" }}
                      onChange={(e) => {
                        const selectedFiles = e.target.files;
                        const videoFiles = Array.from(selectedFiles).filter(
                          (file) => file.type.includes("video")
                        );
                        const documentFiles = Array.from(selectedFiles).filter(
                          (file) =>
                            file.type.includes("application/pdf") ||
                            file.name.endsWith(".doc") ||
                            file.name.endsWith(".docx")
                        );
                        if (videoFiles.length > 0) {
                          const newFiles = [
                            videoFiles[0],
                            ...documentFiles.slice(0, 2),
                          ].filter(Boolean);
                          setInstructionFiles(newFiles);
                          onChange(newFiles);
                        } else {
                          const newFiles = [
                            ...documentFiles.slice(0, 3),
                          ].filter(Boolean);
                          setInstructionFiles(newFiles);
                          onChange(newFiles);
                        }
                      }}
                      multiple
                    />
                  )}
                />
                <div
                  className={styels.uploadbtn}
                  onClick={() => mediaRef.current.click()}
                >
                  <img
                    className={styels.uploadIcon}
                    src={uploadIcon}
                    alt="uploadIcon"
                  />
                  <p>Upload media</p>
                </div>
                {instructionFiles.length ||
                  projectData?.instructions.length ? (
                  <p className={styels.fileName}>Selected Files:</p>
                ) : null}
                <div className={styels.uploadedFilesDiv}>
                  {instructionFiles.length
                    ? instructionFiles.map((file, index) => (
                      <span style={{ position: "relative" }} key={index}>
                        {file.type.includes("video") ? (
                          <video
                            className={styels.additionalDetailsVideo}
                            controls
                          >
                            <source
                              src={URL.createObjectURL(file)}
                              type={file.type}
                            />
                            Your browser does not support the video tag.
                          </video>
                        ) : file.type.includes("application/pdf") ? (
                          <iframe
                            className={styels.additionalDetailsPdf}
                            title={`PDF Preview ${index}`}
                            src={URL.createObjectURL(file)}
                            width="500"
                          />
                        ) : null}
                        <button type="button" onClick={() => removeFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                          <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                        </button>
                      </span>
                    ))
                    : projectData?.instructions.length
                      ? projectData?.instructions.map((file, index) => {
                        const fileExtension = file
                          ?.split(".")
                          .pop()
                          .toLowerCase();
                        return (
                          <span style={{ position: "relative" }} key={index}>
                            {fileExtension === "mp4" ? (
                              <ReactPlayer
                                controls={true}
                                url={signedUrls[file]}
                                className={styels.additionalDetailsVideo}
                              />
                            ) : fileExtension === "pdf" ? (
                              <iframe
                                className={styels.additionalDetailsPdf}
                                title={`PDF Preview ${index}`}
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(
                                  signedUrls[file]
                                )}&embedded=true`}
                              />
                            ) : null}
                            <button type="button" onClick={() => deleteUploadedFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                              <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                            </button>
                          </span>
                        );
                      })
                      : null}
                </div>
              </div>
            </div>
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Add Thumbnail
              </p>
              <div className={styels.uploaddiv}>
                <div className={styels.sliderDiv}>
                  <Slider  {...settings}>
                    {
                      thumbnails.map((thumb) => (
                        <div onClick={() => handleSelectThumbnail(thumb)} className={styels.imageContainer} key={thumb.key} data-name={thumb.name}>
                          <img src={thumb.path} key={thumb.key} alt={thumb.name} className={`${styels.thumbOption} ${thumb.key === localThumbnailIndex ? styels.selectedThumb : ""}`} />
                          {/* <p className={styels.thumbText}>{thumb.name}</p> */}
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </div>
            <div className={styels.skillsDiv}>
              <p className={`${styels.titleText} ${styels.intrText}`}>
                Add Cover Image
              </p>
              <div className={styels.uploaddiv}>
                <div className={styels.sliderDiv}>
                  <Slider style={{ overflow: "visible" }}  {...coverSettings}>
                    {
                      banners.map((thumb) => (
                        <div
                          onClick={() => handleSelectCover(thumb)}
                          className={styels.imageContainer}
                          key={thumb.key}
                          data-name={thumb.name}>
                          <img
                            src={thumb.path}
                            key={thumb.key}
                            alt={thumb.name}
                            className={`${styels.coverOption} ${thumb.key === localCoverIndex ? styels.selectedCover : ""}`} />
                          {/* <p className={styels.coverText}>{thumb.name}</p> */}
                        </div>
                      ))
                    }
                  </Slider>
                </div>
              </div>
            </div>
            <div className={styels.submitDiv}>
              <button
                type="submit"
                onClick={() => setSaveType("trash")}
                className={styels.delBtn}
                disabled={isDisabled}
              >
                <img src={redDelete} alt="redDelete" />
              </button>
              <button
                type="submit"
                onClick={() => setSaveType("draft")}
                className={styels.saveBtn}
                disabled={isDisabled}
              >
                Save to draft
              </button>
              <button
                type="submit"
                onClick={() => setSaveType(["1A", "0", "1", "2", "3", "4", "5"].includes(window.localStorage.getItem("userLevel")) ? "open" : "pending")}
                className={styels.subBtn}
                disabled={isDisabled}
              >
                Submit
              </button>
            </div>
          </form>
        </div >
      </div >
    </div >
  );
};

export default CreateProject;
