import React, { useState } from "react";
import styels from "./DownloadData.module.css";
import downloadIcon from "../../assets/download.png";
import { SingleDatePicker } from "react-dates";
import { downloadProjectsData } from "../../services/index";
import XLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';

const DownloadData = ({ onClose }) => {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [startDateFocus, setStartDateFocus] = useState(false);
  const [endDateFocus, setEndDateFocus] = useState(false);

  const handleDownloadData = async () => {
    const currentUrl = window.location.pathname;
    let requestData = {
      startDate: startDate?.valueOf() ? startDate?.valueOf() : null,
      endDate: endDate?.valueOf() ? endDate?.valueOf() : null,
      userId: localStorage.getItem("userId"),
    };
    if (currentUrl.includes("admin")) {
      requestData = {
        startDate: requestData.startDate,
        endDate: requestData.endDate,
      };
    }
    const response = await downloadProjectsData(requestData);
    if (response.data.status === "200") {
      handleDownload(response.data.projects)
      onClose();
    }
  };

  const handleDownload = (downloadData) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileName = 'projectsReport';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(downloadData, { skipHeader: true });
    ws['!cols'] = [];
    for (let i = 0; i < 24; i++) {
      ws['!cols'].push({ width: 12 });
    }
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };

  return (
    <div>
      <div className={styels.downloadPosition}>
        <h1 className={styels.downloadHeading}>
          Please specify the duration of the data you want to download.
        </h1>
        <div className={styels.downloadDateDiv}>
          <div className={styels.dateDiv}>
            <div className={styels.buttonDiv} role="button">
              <SingleDatePicker
                date={startDate}
                onDateChange={(date) => {
                  setStartDate(date);
                }}
                focused={startDateFocus}
                onFocusChange={({ focused }) => setStartDateFocus(focused)}
                numberOfMonths={1}
                displayFormat="DD-MM-YYYY"
                showClearDate={true}
                isOutsideRange={() => false}
                placeholder="Start Date"
              />
            </div>
            <div className={styels.buttonDiv} role="button">
              <SingleDatePicker
                date={endDate}
                onDateChange={(date) => {
                  setEndDate(date);
                }}
                focused={endDateFocus}
                onFocusChange={({ focused }) => setEndDateFocus(focused)}
                numberOfMonths={1}
                displayFormat="DD-MM-YYYY"
                showClearDate={true}
                isOutsideRange={() => false}
                placeholder="End Date"
              />
            </div>
          </div>
          <div
            className={styels.downloadButtonDiv}
            onClick={() => handleDownloadData()}
          >
            <img
              src={downloadIcon}
              alt="downloadIcon"
              className={styels.downloadIcon}
            />
            <p className={styels.downloadButtonText}>Download Data</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadData;
