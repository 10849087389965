export const dateFormat = (date) => {
  const dateConvert = new Date(date);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const formattedDate = dateConvert.toLocaleDateString("en-IN", options);
  return formattedDate;
};

export const calculateMonthWeekDifference = (startDate, endDate) => {
  const timeDifference = endDate - startDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const monthsDifference = Math.floor(daysDifference / 30.44); // Average days per month
  const remainingDays = daysDifference % 30.44;
  const weeksDifference = Math.floor(remainingDays / 7);
  const remainingWeeks = weeksDifference;

  const monthsText =
    monthsDifference > 1
      ? `${monthsDifference} months`
      : monthsDifference === 1
        ? `${monthsDifference} month`
        : "";
  const weeksText =
    remainingWeeks > 1
      ? `${remainingWeeks} weeks`
      : remainingWeeks === 1
        ? `${remainingWeeks} week`
        : "";

  const monthWeeksDifference =
    monthsText && weeksText
      ? `${monthsText} and ${weeksText}`
      : monthsText || weeksText;
  return monthWeeksDifference;
};


export const calculateDayDifference = (startDate, endDate) => {
  const timeDifference = endDate - startDate;
  const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  return daysDifference;
};


export const employeeLevel = (userRole) => {
  if (userRole === "admin") {
    return "/admin/dashboard";
  } else if (userRole === "user") {
    return "/applicant/dashboard";
  } else if (userRole === "sponsor") {
    return "/sponsor/dashboard";
  } else {
    window.location.replace("/");
  }
};

export const parseJwt = (token) => {
  var base64Url = token?.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)?.split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}

export const countSkillMatches = (skills, item) => {
  const itemSkills = item.skills;
  if (itemSkills) {
    const matchCount = itemSkills.filter(itemSkill =>
      skills.some(skill => skill.label === itemSkill)
    ).length;
    return matchCount;
  }
  return 0
}

export const openUrl = (url, target) => {
  window.open(url, target);
}

export const capitalizeFirstCharacter = (str) => {
  if (!str) return str;
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}
