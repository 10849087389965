import { RECEIVE_PROFILE_DATA, CLEAR_ALL } from "../util/actionTypes";

// function parseJwt(token) {
//   var base64Url = token?.split(".")[1];
//   var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
//   var jsonPayload = decodeURIComponent(
//     window
//       .atob(base64)
//       ?.split("")
//       .map(function (c) {
//         return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
//       })
//       .join("")
//   );
//   return JSON.parse(jsonPayload);
// }

const profileReducer = (state = null, { type, payload }) => {
  switch (type) {
    case RECEIVE_PROFILE_DATA:
      return {
        email: payload.profile.nameID,
        fullname: payload.profile.Name,
        // username: payload.profile.username,
        // company: payload.profile.company,
        // dept: payload.profile.department,
        // title: payload.profile.title
      };
    case CLEAR_ALL:
      return null;
    default:
      return state;
  }
};

export default profileReducer;
