import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import styles from "./Shorlisted.module.css";
import { getShortList, AssignProject, sendMessage } from "../../services/index";
import { toast } from "react-toastify";
import personImage from "../../assets/person.png";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import classes from "../user/ProjectDetails.module.css";
import LinkIcon from '../../assets/export.png'
import moment from "moment";

const Shortlisted = (props) => {
  const { projectData, onAssigne, refreshShortlist } = props;
  const [shortListData, setShortListData] = useState([]);
  const [assignedProjects, setAssignedProjects] = useState([]);

  const handleShortList = async () => {
    const res = await getShortList(projectData._id);
    if (res.data.status === "200") {
      const shortlisted = res.data.shortlistProjects[0]?.applicants.filter(
        (item) => item.status === "shortlisted") || [];
      const assigned = res.data.shortlistProjects[0]?.applicants.filter(
        (item) => item.status === "assigned") || [];
      if (assigned.length == projectData?.openPositions) {
        for (const user of shortlisted) {
          user.alreadyAssigned = true;
        }
      }
      setShortListData(shortlisted);
      setAssignedProjects(assigned)
    }
  };

  const assignApplicantMesg = `
<p>Dear [Title Name],</p>

<p>We are thrilled to inform you that after careful consideration, you have been selected for the project “[Project Title]” by [Sponsor Name]. Your skills and experience make you an ideal candidate for this project, and we are confident in your ability to contribute significantly.</p>

<p>Key details about the project, including timelines and specific requirements, are available on the platform <a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. We encourage you to reach out to [Sponsor Name] to get started with the project</p>

<p>Hope you'll be making the most of this opportunity to enhance your skillset, broaden your network & gain diverse experience. For any concern or queries, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a>.</p>

<p>Wishing you an enriching experience with the project!</p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const assignManagerMesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>This is to update you that your team member [Applicant Name] has been selected for the Bridge Project: "[Project ID]: [Project Title]".</p>

<p>We encourage you to continue to support [Applicant Name] on this journey of development so that they</p>

<p>For your reference, the details of the project are shared in the table below:</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>
Regards,<br/>
Team Titan Career Vista
</p>
  `;

  const handleAssign = async (item) => {
    if (assignedProjects.length >= Number(projectData.openPositions)) {
      toast.error(`You can't assign more than ${projectData.openPositions} applicants`);
    } else {
      const applyData = {
        id: projectData._id,
        userId: item.id,
      };
      const res = await AssignProject(applyData);
      if (res.data.status === "200") {
        const dataToSendApplicant = {
          fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
          toEmail: item.email,
          titleName: item.name,
          projectTitle: projectData.title,
          sponsorName: projectData.sponsorName,
          message: assignApplicantMesg,
          subject: `Congratulations! You've Been Selected for the Project: "[Project Title]" | Bridge Projects`,
        };
        await sendMessage(dataToSendApplicant);
        const dataToSendManager = {
          fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
          toEmail: item.managerEmail,
          titleName: item.managerName,
          message: assignManagerMesg,
          subject: `Update: [Applicant Name] has been selected for "[Project ID]: [Project Title]" | Bridge Projects`,
          applicantName: item.name,
          sponsorName: projectData.sponsorName,
          id: projectData._id,
          projectId: projectData.projectId,
          projectTitle: projectData.title,
          projectDescription: projectData.description,
          projectStatus: projectData.status,
          startDate: moment(projectData.startDate).format("DD-MM-YYYY"),
          endDate: moment(Number(projectData.endDate[projectData.endDate.length - 1])).format("DD-MM-YYYY"),
          openTo: projectData.employeeLevels.join(", "),
          skillRequired: projectData.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
          timeCommitment: projectData.weeklyTime,
          type: projectData.typeOfRequirement,
          keyDeliverables: projectData.keyDeliverables,
        };
        await sendMessage(dataToSendManager);
        const assignedProjects = res.data.assigned.applicants?.filter((item) => item.status === "assigned")
        handleShortList();
        onAssigne();
        toast.success(`Applicant Assigned ${assignedProjects.length} of ${projectData.openPositions} Successfully`);
      }
    }
  };

  useEffect(() => {
    handleShortList();
  }, [refreshShortlist]);

  return (
    <ul className={styles.recommendlist}>
      {shortListData?.map((item, index) => {
        return (
          <li className={styles.recommendli} key={index}>
            <NavLink
              style={{ display: "flex" }}
              to={{
                pathname: "/admin/candidate-profile",
                state: { item, projectData },
              }}
            >
              <div
                className={styles.profile}
                style={{
                  backgroundImage: `url(${personImage})`,
                }}
              ></div>
              <div className={styles.details}>
                <div className={classes.sponsor}>
                  <h1>{item.name}</h1>
                  {/* <Tippy content="View Candidate Profile" placement="right">
                    <img src={LinkIcon} alt="link" className={classes.link} />
                  </Tippy> */}
                </div>
                {/* <h1>{item?.name}</h1> */}
                <p>{item.level}; {item.department}; {item.orgUnit}</p>
              </div>
            </NavLink>
            <button
              className={`${styles.shortBtn} ${item?.status === "assigned" ? styles.shortListed : item?.alreadyAssigned ? styles.alreadyAssigned : ""}`}
              onClick={() => (item?.status === "shortlisted" && !item?.alreadyAssigned) && handleAssign(item)}
            >
              {item?.status === "assigned" ? "Assigned" : item?.alreadyAssigned ? "Assigned to another project already" : "Assign"}
            </button>
          </li>
        );
      })}
    </ul>
  );
};

export default Shortlisted;
