import React, { useEffect } from "react";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
// import { CLEAR_ALL } from "../util/actionTypes";
import { ToastContainer } from "react-toastify";
import "../Fonts/CaustenBlack.ttf";
import "../css/App.css";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import AuthPage from "./AuthPage";
import PublicPage from "./PublicPage";
import HomePage from "./HomePage";
import Notifications from "./common/Notifications";
import Profile from "./common/Profile";
import Resources from "./common/Resources";
import AdminDashboard from "./admin/Dashboard";
import AdminCreateProject from "./admin/CreateProject";
import AdminDrafts from "./admin/Drafts";
import AdminEditDraft from "./admin/EditDraft";
import AdminTrash from "./admin/Trash";
import AdminPendingProjects from "./admin/PendingProjects";
import AdminOpenProjects from "./admin/OpenProjects";
import AdminShortlisted from "./admin/Shortlisted";
import AdminCandidate from "./admin/Candidate";
import AdminOngoingProjects from "./admin/OngoingProjects";
import AdminCompletedProjects from "./admin/CompletedProjects";
import AdminProjectDetails from "./admin/ProjectDetails";
import SponcorDashboard from "./sponcor/Dashboard";
import SponcorCreateProject from "./sponcor/CreateProject";
import SponcorDrafts from "./sponcor/Drafts";
import SponcorEditDraft from "./sponcor/EditDraft";
import SponcorTrash from "./sponcor/Trash";
import SponcorPendingProjects from "./sponcor/PendingProjects";
import SponcorOpenProjects from "./sponcor/OpenProjects";
import SponcorShortlisted from "./sponcor/Shortlisted";
import SponcorCandidate from "./sponcor/Candidate";
import SponcorOngoingProjects from "./sponcor/OngoingProjects";
import SponcorCompletedProjects from "./sponcor/CompletedProjects";
import SponsorProjectDetails from "./sponcor/ProjectDetails";
import UserDashboard from "./user/Dashboard";
import UserAppliedCompletedProjects from "./user/AppliedCompletedProjects";
import UserOngoingProjects from "./user/OngoingProjects";
import UserProjectDetails from "./user/ProjectDetails";
import UserProjectUpdateStatus from "./user/ProjectUpdateStatus";
import ThankYou from "./common/ThankYou";
import UnderProgress from "./common/UnderProgress";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'react-tooltip/dist/react-tooltip.css'

const App = ({ loggedIn, logout }) => {

  return (
    <Router>
      <div className="App-page">
        <ToastContainer delay={500} />
        <Switch>
          <ProtectedRoute
            component={AdminDashboard}
            open={loggedIn}
            path="/admin/dashboard"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminPendingProjects}
            open={loggedIn}
            path="/admin/pending-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminOpenProjects}
            open={loggedIn}
            path="/admin/open-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminOngoingProjects}
            open={loggedIn}
            path="/admin/ongoing-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminCompletedProjects}
            open={loggedIn}
            path="/admin/completed-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminShortlisted}
            open={loggedIn}
            path="/admin/shortlisted-candidates"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminCandidate}
            open={loggedIn}
            path="/admin/candidate-profile"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminProjectDetails}
            open={loggedIn}
            path="/admin/project-details"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminCreateProject}
            open={loggedIn}
            path="/admin/create-project"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminDrafts}
            open={loggedIn}
            path="/admin/drafts"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminEditDraft}
            open={loggedIn}
            path="/admin/edit-draft"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminEditDraft}
            open={loggedIn}
            path="/admin/edit-project"
            redirect="/"
          />
          <ProtectedRoute
            component={AdminTrash}
            open={loggedIn}
            path="/admin/trash"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorDashboard}
            open={loggedIn}
            path="/sponsor/dashboard"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorPendingProjects}
            open={loggedIn}
            path="/sponsor/pending-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorOpenProjects}
            open={loggedIn}
            path="/sponsor/open-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorOngoingProjects}
            open={loggedIn}
            path="/sponsor/ongoing-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorCompletedProjects}
            open={loggedIn}
            path="/sponsor/completed-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorShortlisted}
            open={loggedIn}
            path="/sponsor/shortlisted-candidates"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorCandidate}
            open={loggedIn}
            path="/sponsor/candidate-profile"
            redirect="/"
          />
          <ProtectedRoute
            component={SponsorProjectDetails}
            open={loggedIn}
            path="/sponsor/project-details"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorCreateProject}
            open={loggedIn}
            path="/sponsor/create-project"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorDrafts}
            open={loggedIn}
            path="/sponsor/drafts"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorEditDraft}
            open={loggedIn}
            path="/sponsor/edit-draft"
            redirect="/"
          />
          <ProtectedRoute
            component={SponcorTrash}
            open={loggedIn}
            path="/sponsor/trash"
            redirect="/"
          />
          <ProtectedRoute
            component={Profile}
            open={loggedIn}
            path="/profile"
            redirect="/"
          />
          <ProtectedRoute
            component={Notifications}
            open={loggedIn}
            path="/notifications"
            redirect="/"
          />
          <ProtectedRoute
            component={Resources}
            open={loggedIn}
            path="/resources"
            redirect="/"
          />
          <ProtectedRoute
            component={UserDashboard}
            open={loggedIn}
            path="/applicant/dashboard"
            redirect="/"
          />
          <ProtectedRoute
            component={UserAppliedCompletedProjects}
            open={loggedIn}
            path="/applicant/applied-completed-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={UserOngoingProjects}
            open={loggedIn}
            path="/applicant/ongoing-projects"
            redirect="/"
          />
          <ProtectedRoute
            component={UserProjectDetails}
            open={loggedIn}
            path="/applicant/project-details"
            redirect="/"
          />
          <ProtectedRoute
            component={UserProjectUpdateStatus}
            open={loggedIn}
            path="/applicant/project-update-status"
            redirect="/"
          />
          <ProtectedRoute
            component={HomePage}
            open={loggedIn}
            path="/home"
            redirect="/"
          />
          <ProtectedRoute
            component={ThankYou}
            open={loggedIn}
            path="/thank-you"
            redirect="/"
          />
          <ProtectedRoute
            component={UnderProgress}
            open={loggedIn}
            path="/under-progress"
            redirect="/"
          />
          <ProtectedRoute
            component={AuthPage}
            open={loggedIn}
            path="/auth"
            redirect="/"
          />
          {/* <Route component={AuthPage} path="/auth" /> */}
          <Route component={PublicPage} path="/" />
          {/* <Route component={HomePage} path="/home" /> */}
        </Switch>
      </div>
    </Router>
  )
}

const mapStateToProps = (state) => ({
  loggedIn: state.token.accessToken,
})

// const mapDispatchToProps = (dispatch) => ({
//   logout: () => dispatch({ type: CLEAR_ALL }),
// });

export default connect(
  mapStateToProps
  // mapDispatchToProps
)(App);
