import React, { useEffect } from "react";
import Header from "./Header";
import backgroundImage from "../assets/loginbg.png";
import { useHistory } from "react-router-dom";
import Cookies from 'js-cookie';

export default function Logincomp({ loggedIn, logout }) {
  const history = useHistory();
  const token = Cookies.get('token');
  const empLevel = window.localStorage.getItem("userLevel");

  useEffect(() => {
    if (token && empLevel) {
      history.push("/home");
    } else {
      history.push("/");
    }
   

  }, [token, empLevel]);

  return (
    <>
      <div
        style={{
          backgroundImage: `url(${backgroundImage})`,
          height: "100vh",
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "top",
        }}
      >
        <div style={{ paddingTop: "25%" }}>
          <Header loggedIn={loggedIn} logout={logout} />
        </div>
      </div>
    </>
  );
}
