import React, { useState, useEffect } from "react";
import { AiOutlineLeft } from "react-icons/ai";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";
import ReactPlayer from "react-player";
import styles from "./ProjectDetails.module.css";
import Navbar from "../common/Navbar";
import { toast } from "react-toastify";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import {
  getProjectDetails,
} from "../../services/index";
import downloadIcon from "../../assets/download.png";
import { dateFormat, calculateMonthWeekDifference, openUrl, capitalizeFirstCharacter } from "../../util/commenFunctions";
import Preview from "./Preview";
import { useHistory } from "react-router-dom";
import download from "../../assets/filedownload.png";
import { banners } from "../common/Images";
import StarRatings from "react-star-ratings";
import starIcon from "../../assets/recomendstar.png";
import star from "../../assets/start.png"
import emptyStart from "../../assets/emptyStart.png";
import LinkIcon from '../../assets/export.png'
import bgImage from '../../assets/BackGround/bg2web.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

function getModalStyle() {
  const top = 60;
  const left = 60;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      padding: "20px 10px",
    },
  },
}));

const ProjectDetails = (props) => {
  const history = useHistory();
  // const projectRecommendationData =
  //   props.location.state?.recommendation ||
  //   JSON.parse(window.localStorage.getItem("projectData"));
  const [showSkills, setShowSkills] = useState(false);
  const [showImproveSkills, setShowImproveSkills] = useState(false);
  const projectData = props.location;
  const [open, setOpen] = React.useState(false);
  const [openDes, setOpenDes] = React.useState(false);
  const [openKeyDeliverables, setOpenKeyDeliverables] = React.useState(false);

  const [isFullTextDisplayed, setIsFullTextDisplayed] = useState(false);
  const [isFullKDelTextDisplayed, setIsFullKDelTextDisplayed] = useState(false);
  const [projectRecommendationData, setProjectRecommendationData] = useState();
  const [videoUrl, setVideoUrl] = React.useState("");
  const findStatus = projectRecommendationData?.applicants.find(
    (item) => item.id === window.localStorage.getItem("userId")
  );
  const description = projectRecommendationData?.description;
  const shouldTruncate = description && description?.length > 400;
  const truncatedText = description?.substring(0, 400) + "<strong>...Read More</strong>";

  const keyDeliverables = projectRecommendationData?.keyDeliverables
  const shouldTruncateDeliverables = keyDeliverables && keyDeliverables?.length > 300;
  const truncatedTextDeliverables = keyDeliverables?.substring(0, 300) + "<strong>...Read More</strong>";

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const skills = projectRecommendationData?.requiredSkills.map((each) =>
    JSON.parse(each)
  );

  const improvedSkills = projectRecommendationData?.improveLearnSkills.map(
    (each) => JSON.parse(each)
  );

  const filteredDocumentsUrls = projectRecommendationData?.instructions?.filter(
    (url) => !url.match(/\.(mp4|avi|mov)$/)
  );

  const filteredVideoUrls = projectRecommendationData?.instructions.find(
    (url) => url.match(/\.(mp4|avi|mov)$/)
  );

  useEffect(() => {
    setVideoUrl(filteredVideoUrls);
  }, [filteredVideoUrls]);


  const handleDownload = () => {
    filteredDocumentsUrls.forEach((url, index) => {
      setTimeout(async () => {
        const link = document.createElement('a');
        link.href = url;
        link.download = true; // This will prompt a download
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }, index * 1000); // 1 second delay between each download
    });
  };

  const renderSkills = (skills) => {
    const skillsArray = skills?.map((each) => each.label);
    let skillsPara = ""
    if (skills?.length > 1) {
      skillsPara = skillsArray.join(", ")
      if (skills.length > 3 && !showSkills) {
        return <p className={styles.skill} >{skillsPara?.slice(0, 50)}<span onClick={() => setShowSkills(true)} className={styles.seeMore}>...view more </span></p>
      }
      else if (skills.length > 3 && showSkills) {
        return <p className={styles.skill}>{skillsPara}</p>
      }
      return (skillsPara)
    } else {
      return (skillsArray && skillsArray[0])
    }
  }

  const renderImproveSkills = (skills) => {
    const skillsArray = skills?.map((each) => each.label);
    let skillsPara = ""
    if (skills?.length > 1) {
      skillsPara = skillsArray.join(", ")
      if (skills.length > 3 && !showImproveSkills) {
        return <p className={styles.skill} >{skillsPara?.slice(0, 50)}<span onClick={() => setShowImproveSkills(true)} className={styles.seeMore}>...view more </span></p>
      }
      else if (skills.length > 3 && showImproveSkills) {
        return <p className={styles.skill}>{skillsPara}</p>
      }
      return (skillsPara)
    } else {
      return (skillsArray && skillsArray[0])
    }
  }

  const preview = (
    <Preview
      recommendation={projectRecommendationData}
      onApply={handleClose}
      getRecommendations={projectData.setRecommendations}
      getProjectCountsList={projectData.getProjectCountsList}
      recommendations={projectData.state?.recommendations}
    />
  );

  const desPreview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styles.desmodal}`}
    >
      <button className={styles.closeBtn} onClick={() => setOpenDes(false)}>close</button>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: projectRecommendationData?.description }}></p>


    </div>
  );

  const keyDelPreview = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styles.desmodal}`}
    >
      <button className={styles.closeBtn} onClick={() => setOpenKeyDeliverables(false)}>close</button>
      <p className={styles.description} dangerouslySetInnerHTML={{ __html: projectRecommendationData?.keyDeliverables }}></p>


    </div>
  )

  const getProjectData = async () => {
    const res = await getProjectDetails(props.location.state ? props.location.state?.recommendation._id : window.localStorage.getItem("projectId"));
    if (res.data.status === "200") {
      setProjectRecommendationData(res.data.project);
    }
  }

  useEffect(() => {
    getProjectData();
  }, [])

  return (
    <div>
      <Navbar />
      <div className={styles.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.subdiv}>
          <div className={styles.headdiv}>
            <div className={`${styles.dateDiv} ${styles.dateDiv2}`}>
              <div className={styles.date}>
                <h1>Start date</h1>
                <p>{dateFormat(projectRecommendationData?.startDate)}</p>
              </div>
              <div className={styles.date}>
                <h1>End date</h1>
                <p>{dateFormat(Number(projectRecommendationData?.endDate[projectRecommendationData?.endDate?.length - 1]))}</p>
              </div>
              <div className={styles.date}>
                <h1>Last Date to apply</h1>
                <p>{dateFormat(projectRecommendationData?.lastDate)}</p>
              </div>
            </div>
            <div
              className={styles.imgDiv}
              style={{
                backgroundImage: `url(${projectRecommendationData?.cover || banners[projectRecommendationData?.localCover]?.path})`,
              }}
            >
              <div className={styles.backDiv}>
                <NavLink
                  style={{ textDecoration: "none", color: "white" }}
                  to="#"
                  onClick={() => history.goBack()}
                >
                  <AiOutlineLeft style={{ fontSize: "32px", color: "#ff002b" }} />
                </NavLink>
                <p style={{ border: "1px solid #ffcf23" }}>ID: {projectRecommendationData?.projectId}</p>
              </div>
            </div>
            <div className={styles.titleDiv}>
              <div className={styles.title}>
                <p>Posted on: {dateFormat(projectRecommendationData?.createdAt)}</p>
                <h1>{projectRecommendationData?.title}</h1>
                <p>Number of open positions: {projectRecommendationData?.openPositions}</p>
              </div>
              {filteredDocumentsUrls?.length > 0 && (
                <div onClick={handleDownload} className={`${styles.information} ${styles.division} ${styles.downloaddiv}`}>
                  <h1 className={styles.downText}>Reference documents</h1>
                  <img className={styles.downIcon} src={download} alt="download" />
                </div>
              )}
            </div>
            <div className={styles.applyDiv}>
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Division</h1>
                <p>
                  {projectRecommendationData &&
                    JSON.parse(projectRecommendationData?.division)?.value}
                </p>
              </div>
              {/* <div className={`${styles.information} ${styles.division}`}>
                              <h1>SBU</h1>
                              <p>
                                {projectRecommendationData &&
                                  JSON.parse(projectRecommendationData?.sbu)?.value}
                              </p>
                            </div>
                            <div className={`${styles.information} ${styles.division}`}>
                              <h1>Org Unit</h1>
                              <p>
                                {projectRecommendationData &&
                                  JSON.parse(projectRecommendationData?.orgUnit)?.value}
                              </p>
                            </div> */}
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Function</h1>
                <p>{projectRecommendationData && JSON.parse(projectRecommendationData?.function)?.value}</p>
              </div>
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Department</h1>
                <p>
                  {projectRecommendationData &&
                    JSON.parse(projectRecommendationData?.department)
                      ?.value}
                </p>
              </div>
              <div className={`${styles.information} ${styles.division}`}>
                <h1>Project Sponsor</h1>
                <div className={styles.sponsor}>
                  <p>{projectRecommendationData?.sponsorName}</p>
                  {/* <Tippy content="View Sponsor Profile" placement="bottom">
                    <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${projectRecommendationData.sponsorId}`, '_blank')} src={LinkIcon} alt="link" className={styles.link} />
                  </Tippy> */}
                </div>
              </div>
            </div>
            <p className={styles.descriptionDiv}>
              {isFullTextDisplayed || !shouldTruncate
                ? <span dangerouslySetInnerHTML={{ __html: description }}></span>
                : <span>
                  <span onClick={() => setOpenDes(true)} dangerouslySetInnerHTML={{ __html: truncatedText }}></span>
                  {/* <span className={styles.readMore} onClick={() => setOpenDes(true)} style={{ cursor: 'pointer', color: '#096070' }}>
                      Read More
                    </span> */}
                </span>
              }
            </p>
          </div>
          <div className={styles.rightdiv}>
            <div className={styles.dateDiv}>
              <div className={styles.date}>
                <h1>Start date</h1>
                <p>{dateFormat(projectRecommendationData?.startDate)}</p>
              </div>
              <div className={styles.date}>
                <h1>End date</h1>
                <p>{dateFormat(Number(projectRecommendationData?.endDate[projectRecommendationData?.endDate?.length - 1]))}</p>
              </div>
              <div className={styles.date}>
                <h1>Last Date to apply</h1>
                <p>{dateFormat(projectRecommendationData?.lastDate)}</p>
              </div>
            </div>
            <div className={styles.infodiv}>
              <div className={styles.information}>
                <h1>Duration</h1>
                <p>
                  {calculateMonthWeekDifference(
                    projectRecommendationData?.startDate,
                    Number(projectRecommendationData?.endDate[projectRecommendationData?.endDate.length - 1])
                  )}
                </p>
              </div>
              <div className={styles.information}>
                <h1>Type</h1>
                <p>{projectRecommendationData?.typeOfRequirement}</p>
              </div>
              <div className={styles.information}>
                <h1>Weekly commitment</h1>
                <p>{projectRecommendationData?.weeklyTime} Hours</p>
              </div>
              <div className={styles.information}>
                <h1>Open to</h1>
                <p>{projectRecommendationData?.employeeLevels.length === 9 ? "Open to All" : `${projectRecommendationData?.employeeLevels.join(', ')}`}</p>
              </div>
            </div>
            <div className={styles.skillDiv}>
              <p className={styles.skill} >
                {renderSkills(skills)}
              </p>
            </div>
            <div className={styles.improveDiv}>
              <p className={styles.skill} >
                {renderImproveSkills(improvedSkills)}
              </p>
            </div>
            <div className={styles.keydiv}>
              <p
                className={styles.key}
              >
                {isFullKDelTextDisplayed || !shouldTruncateDeliverables
                  ? <span dangerouslySetInnerHTML={{ __html: keyDeliverables }}></span>
                  : <span>
                    <span onClick={() => setOpenKeyDeliverables(true)} dangerouslySetInnerHTML={{ __html: truncatedTextDeliverables }}></span>
                    {/* <span className={styles.readMore} onClick={() => setOpenDes(true)} style={{ cursor: 'pointer', color: '#096070' }}>
                      Read More
                    </span> */}
                  </span>
                }
              </p>
            </div>
            {filteredVideoUrls ? (
              <div className={styles.playerDiv}>
                <ReactPlayer
                  controls={true}
                  url={videoUrl}
                  className={styles.player}
                />
              </div>
            ) : null}
            {findStatus === undefined && (
              <div className={`${styles.information} ${styles.division}`}>
                <button
                  className={styles.apllyBtn}
                  onClick={() => handleOpen(projectRecommendationData)}
                >
                  Apply
                </button>
              </div>
            )}
          </div>
        </div>
        {["not shortlisted", "not assigned", "shortlisted", "applied"].includes(findStatus?.status) && (
          <div className={styles.appliedDiv}>
            <div
              className={`${styles.appliedInformation} ${styles.appliedDivision}`}
            >
              <h1>Progress Status</h1>
              <p>{findStatus?.status === "not shortlisted" ? "Application not taken ahead for this project" : findStatus?.status === "not assigned" ? "Candidature not taken ahead for this project" : findStatus?.status === "shortlisted" ? "Shortlisted for the project" : findStatus?.status === "assigned" ? "Assigned for the project" : "The project is still open for applications and the shortlisting process is ongoing. You will be notified soon, about the update."}</p>
            </div>
          </div>
        )}
        <div style={{ display: `${findStatus?.status === "completed" ? "block" : "none"}` }}>
          <div className={styles.completedDiv}>
            <p className={styles.feedback}>
              Name:
              <span className={styles.feedText}>
                {" "}{findStatus?.name}
              </span>
            </p>
            <p className={styles.feedback}>
              Email:
              <span className={styles.feedText}>
                {" "}{findStatus?.email}
              </span>
            </p>
            <div>
              {Array.from({ length: findStatus?.sponsorRating }).map((_, i) => (
                <img
                  src={star}
                  alt="star"
                  key={i}
                  className={styles.star}
                />
              ))}
              {Array.from({ length: 5 - findStatus?.sponsorRating }).map((_, i) => (
                <img
                  src={emptyStart}
                  alt="star"
                  key={i}
                  className={styles.emptystar}
                />
              ))}
            </div>
            <p className={styles.feedback}>Feedback from Applicant: <span className={styles.feedText}>
              “{findStatus?.sponsorFeedback}!”
            </span></p>
          </div>
          <div className={styles.completedDiv}>
            <div>
              {Array.from({ length: findStatus?.rating }).map((_, i) => (
                <img
                  src={star}
                  alt="star"
                  key={i}
                  className={styles.star}
                />
              ))}
              {Array.from({ length: 5 - findStatus?.rating }).map((_, i) => (
                <img
                  src={emptyStart}
                  alt="star"
                  key={i}
                  className={styles.emptystar}
                />
              ))}
            </div>
            <p className={styles.feedback}>Feedback from  Project Sponsor: <span className={styles.feedText}>
              “{findStatus?.feedback}”
            </span></p>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {preview}
      </Modal>
      <Modal
        open={openDes}
        onClose={() => setOpenDes(false)}
      >
        {desPreview}
      </Modal>
      <Modal
        open={openKeyDeliverables}
        onClose={() => setOpenKeyDeliverables(false)}
      >
        {keyDelPreview}
      </Modal>
    </div>
  );
};

export default ProjectDetails;
