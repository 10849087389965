import React, { useState, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "./Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import "react-dates/lib/css/_datepicker.css";
import { toast } from "react-toastify";
import "react-image-crop/dist/ReactCrop.css";
import styels from "../admin/CreateProject.module.css";
import uploadIcon from "../../assets/upload.png";
import { addResource, getResource, deleteUploadedResource } from "../../services/index";
import "react-dates/initialize";
import ReactPlayer from "react-player";
import Cookies from "js-cookie";
import { parseJwt } from "../../util/commenFunctions";
//  import PDFViewer from "./PdfViewer";
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import deleteIcon from "../../assets/delete.png"
import classes from "./Resources.module.css"
import { thumbnails } from "./Images";
import draftStyles from '../sponcor/Drafts.module.css'
import videoIcon from '../../assets/video.png'
import pdfIcon from '../../assets/pdf.png'
import pptIcon from '../../assets/ppt.png'
import docIcon from '../../assets/word.png'
import bgImage from '../../assets/BackGround/bg5web.png';

const ResourcesTab = (props) => {
    const checkToken = Cookies.get("token")
    const decodedJwt = parseJwt(checkToken);
    const mediaRef = useRef();
    const formRef = useRef();
    const [resourceId, setResourceId] = useState();
    const [resources, setResources] = useState([]);
    const [allow, setAllow] = useState({ "label": "Both", "value": "both" });
    const [resourceFiles, setResourceFiles] = useState([]);
    const [signedUrls, setSignedUrls] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [pdfUrls, setPdfUrls] = useState([]);
    const [allowUpload, setAllowUpload] = useState(false);
    const [showResource, setShowResource] = useState(false);
    const [selectedResource, setSelectedResource] = useState(null);

    const docViewerConfig = {
        header: {
            disableHeader: true,
            retainURLParams: false,
        }
    };

    useEffect(() => {
        const fetchUrls = async () => {
            setIsLoading(true);
            setIsLoading(false);
        };
        fetchUrls();
    }, [resources]);

    const allowAccessTo = [
        {
            label: "Sponsor",
            value: "sponsor"
        }, {
            label: "Applicant",
            value: "user"
        }, {
            label: "Both",
            value: "both"
        }
    ]

    const userRole = decodedJwt.userRole

    const onCreate = async (data) => {
        const formData = new FormData();
        if (data.resources) {
            formData.append("resource", data.resources[0]);
            formData.append("allow", JSON.stringify(data.allow));
            formData.append("description", data.description);
            const infoToastId = toast.info('Submitting data...', { autoClose: false });
            try {
                let result;
                result = await addResource(formData);
                toast.dismiss(infoToastId);
                if (result.data.status === "200") {
                    toast.success(`Resource ${resourceId ? "updated" : "created"} successfully`);
                    setTimeout(() => window.location.reload(), 2000);
                }
            } catch (e) {
                toast.dismiss(infoToastId);
                toast.error("Something went wrong, Please try again.");
            }
        }
    };

    const getResourceData = async () => {
        const result = await getResource();
        if (result.data.status === "200" && result?.data?.resource) {
            if (userRole === "admin") {
                setResources(result.data.resource);
            } else if (userRole === "sponsor") {
                setResources(result.data.resource.filter(item => JSON.parse(item.allow)?.value === "sponsor" || JSON.parse(item.allow)?.value === "both"));
            } else if (userRole === "user") {
                setResources(result.data.resource.filter(item => JSON.parse(item.allow)?.value === "user" || JSON.parse(item.allow)?.value === "both"));
            }
            setResourceId(result.data.resource._id);
        } else {
            setResources([]);
        }
    };

    const {
        control,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        getResourceData();
    }, []);

    useEffect(() => {
        if (Array.isArray(resources)) {
            resources.forEach((resource) => {
                setSignedUrls(prevUrls => ({ ...prevUrls, [resource.resource]: resource.resource }));
            });
        }

        if (resources) {
            const documentResources = resources.filter(resource =>
                resource.resource.toLowerCase().endsWith('.pdf') ||
                resource.resource.toLowerCase().endsWith('.doc') ||
                resource.resource.toLowerCase().endsWith('.docx') ||
                resource.resource.toLowerCase().endsWith('.ppt') ||
                resource.resource.toLowerCase().endsWith('.pptx')
            );

            const urlsPromises = documentResources.map(resource => resource.resource);

            Promise.all(urlsPromises).then(urlsResponses => {
                const documentSignedUrls = urlsResponses.map((response, index) => {
                    const fileExtension = documentResources[index].resource.split('.').pop();
                    const fileType = fileExtension === 'pdf' ? 'pdf' :
                        (fileExtension === 'doc' || fileExtension === 'docx') ? 'doc' :
                            (fileExtension === 'ppt' || fileExtension === 'pptx') ? 'ppt' : '';
                    const fileName = `resource.${fileExtension}`;
                    return {
                        uri: response,
                        fileType: fileType,
                        fileName: fileName
                    };
                });
                setPdfUrls(documentSignedUrls);
            });
        }


    }, [resources]);

    if (isLoading) {
        return <div>Loading...</div>
    }

    const removeFile = (indexToRemove) => {
        setResourceFiles((currentFiles) =>
            currentFiles.filter((_, index) => index !== indexToRemove)
        );
    };

    const deleteUploadedFile = async (resourceId) => {
        const response = await deleteUploadedResource({ id: resourceId });
        if (response.data.status === "200") {
            // setResources(resources => {
            //     const updatedResources = resources.filter((_, i) => i !== index);
            //     return updatedResources; // Make sure you return the filtered array
            // });

            toast.success("Resource deleted successfully");
        }
        window.location.reload();
    }

    const onSelectedResource = (resource) => {
        setSelectedResource(resource);
        setShowResource(true);
    }

    // const renderResourceView = () => {
    //     if (selectedResource) {
    //         const fileExtension = selectedResource.resource
    //             ?.split(".")
    //             .pop()
    //             .toLowerCase();
    //         return (
    //             <div className={draftStyles.draftsList}>
    //                 <h1 className={styels.titleText}>Description</h1>
    //                 <p>{selectedResource.description}</p>
    //                 <h1 className={styels.titleText}>Resource</h1>
    //                 <span>
    //                     {fileExtension === "mp4" ? (

    //                         <ReactPlayer
    //                             controls={true}
    //                             url={signedUrls[selectedResource.resource]}
    //                             className={styels.additionalDetailsVideo}
    //                             style={{ margin: "auto" }}
    //                         />

    //                     ) : fileExtension === "pdf" || fileExtension === "docx" || fileExtension === "doc"  || fileExtension === "pptx" || fileExtension === "ppt" ? (
    //                         //  <PDFViewer  className={styels.additionalDetailsPdf} index={index} fileUrl={signedUrls[file]}/>
    //                         <DocViewer className={classes.pdfDiv} documents={pdfUrls} pluginRenderers={DocViewerRenderers} config={docViewerConfig} />
    //                     ) : null}
    //                 </span>
    //             </div>
    //         );
    //     }
    // }

    const renderResourceView = () => {
        if (selectedResource) {
            const fileExtension = selectedResource.resource
                ?.split(".")
                .pop()
                .toLowerCase();

            const resourceUrl = signedUrls[selectedResource.resource];

            return (
                <div className={draftStyles.draftsList}>
                    <h1 className={styels.titleText}>Description</h1>
                    <p>{selectedResource.description}</p>
                    <h1 className={styels.titleText}>Resource</h1>
                    <span>
                        {fileExtension === "mp4" ? (
                            <ReactPlayer
                                controls={true}
                                url={resourceUrl}
                                className={styels.additionalDetailsVideo}
                                style={{ margin: "auto" }}
                                config={{
                                    file: {
                                        attributes: {
                                            controlsList: 'nodownload'
                                        }
                                    }
                                }}
                            />
                        ) : fileExtension === "docx" || fileExtension === "doc" || fileExtension === "pptx" || fileExtension === "ppt" ? (
                            <DocViewer
                                className={styels.pdfDiv}
                                documents={[{ uri: resourceUrl, fileType: fileExtension, fileName: `resource.${fileExtension}` }]}
                                pluginRenderers={DocViewerRenderers}
                                config={docViewerConfig}
                            />
                        ) : fileExtension === "pdf" ? (
                            <iframe
                                className={styels.additionalDetailsPdf}
                                title="PDF Preview"
                                src={`https://docs.google.com/viewer?url=${encodeURIComponent(resourceUrl)}&embedded=true&timestamp=${new Date().getTime()}`}
                            />
                        ) : null}
                    </span>
                </div>
            );
        }
    }


    const backNavigation = () => {
        setShowResource(false);
        setAllowUpload(false);
    }

    return (

        <div className="Profile-page">
            <Navbar />
            <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
                <div className={styels.subdiv}>
                    <div className={styels.backdiv}>
                        <div className={`${styels.arrowDiv} ${classes.arrowDiv}`}>
                            <NavLink
                                style={{ textDecoration: "none", color: "black" }}
                                to={userRole === "admin" ? (showResource || allowUpload ? "/resources" : "/admin/dashboard") : userRole === "sponsor" ? (showResource ? "/resources" : "/sponsor/dashboard") : (showResource ? "/resources" : "/applicant/dashboard")}

                            >
                                <AiOutlineLeft style={{ fontSize: "32px" }} onClick={backNavigation} />
                            </NavLink>
                            <h1 className={styels.head}>
                                {allowUpload ? "Upload Resource" : "Resources"}
                            </h1>
                            {(!allowUpload && userRole === "admin") && (
                                <div className={`${styels.uploadbtn} ${classes.uploadbtn}`} onClick={() => setAllowUpload(true)}>
                                    <img
                                        className={styels.uploadIcon}
                                        src={uploadIcon}
                                        alt="uploadIcon"
                                    />
                                    <p>Upload Resource</p>
                                </div>
                            )

                            }

                        </div>
                    </div>
                    {
                        (userRole === "admin" && allowUpload) ? (
                            <>
                                <form
                                    onSubmit={handleSubmit(onCreate)}
                                    encType="multipart/form-data"
                                    ref={formRef}
                                >
                                    <div>
                                        <label htmlFor="title" className={styels.titleText}>
                                            Description
                                        </label>
                                        <input
                                            placeholder="Enter description"
                                            id="title"
                                            className={`${styels.titleInput} ${classes.titleInput}`}
                                            type="text"
                                            {...register("description")}
                                            required
                                        />
                                    </div>
                                    <div className={styels.skillsDiv}>
                                        <p className={`${styels.titleText} ${styels.intrText}`}>
                                            Resource
                                        </p>

                                        <div className={styels.uploaddiv}>
                                            <Controller
                                                name="resources"
                                                control={control}
                                                defaultValue={null}
                                                render={({ field: { onChange, value } }) => (
                                                    <input
                                                        ref={mediaRef}
                                                        type="file"
                                                        accept=".mp4, .avi, .mov, .wav, .ogg, .pdf, .doc, .docx, .ppt, .pptx"
                                                        style={{ display: "none" }}
                                                        onChange={(e) => {
                                                            const selectedFiles = e.target.files;
                                                            const videoFiles = Array.from(selectedFiles).filter(
                                                                (file) => file.type.includes("video")
                                                            );
                                                            const documentFiles = Array.from(selectedFiles).filter(
                                                                (file) => file.type.includes("application/pdf")
                                                                    || file.type.includes("application/msword")
                                                                    || file.type.includes("application/vnd.openxmlformats-officedocument.wordprocessingml.document")
                                                                    || file.type.includes("application/vnd.ms-powerpoint")
                                                                    || file.type.includes("application/vnd.openxmlformats-officedocument.presentationml.presentation")
                                                            );
                                                            if (videoFiles.length > 0) {
                                                                const newFiles = [
                                                                    videoFiles[0],
                                                                    ...documentFiles.slice(0, 2),
                                                                ].filter(Boolean);
                                                                setResourceFiles(newFiles);
                                                                onChange(newFiles);
                                                            } else {
                                                                const newFiles = [
                                                                    ...documentFiles.slice(0, 3),
                                                                ].filter(Boolean);
                                                                setResourceFiles(newFiles);
                                                                onChange(newFiles);
                                                            }
                                                        }}
                                                    />
                                                )}
                                            />
                                            <div
                                                className={styels.uploadbtn}
                                                onClick={() => mediaRef.current.click()}
                                            >
                                                <img
                                                    className={styels.uploadIcon}
                                                    src={uploadIcon}
                                                    alt="uploadIcon"
                                                />
                                                <p>Upload Resource</p>
                                            </div>
                                            <div>
                                                {resourceFiles.length || resources?.length ? (
                                                    <p className={styels.fileName}>Selected File: {resourceFiles[0]?.name}</p>
                                                ) : null}
                                                {resourceFiles.length ? resourceFiles.map((file, index) => (
                                                    <span className={classes.precont} key={index}>
                                                        {file.type.includes("video") ? (
                                                            <video
                                                                className={`${styels.additionalDetailsVideo} ${classes.additionalDetailsVideo}`}
                                                                controls
                                                            >
                                                                <source
                                                                    src={URL.createObjectURL(file)}
                                                                    type={file.type}
                                                                />
                                                                Your browser does not support the video tag.
                                                            </video>
                                                        ) : file.type.includes("application/pdf")

                                                            ? (
                                                                <iframe
                                                                    className={styels.additionalDetailsPdf}
                                                                    title={`PDF Preview ${index}`}
                                                                    src={URL.createObjectURL(file)}
                                                                />
                                                            ) : null}
                                                        {/* <button type="button" onClick={() => removeFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                                                            <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                                                        </button> */}
                                                    </span>
                                                )) : null
                                                    //  resources?.length
                                                    //     ? resources?.map((file, index) => {
                                                    //         const fileExtension = file
                                                    //             ?.split(".")
                                                    //             .pop()
                                                    //             .toLowerCase();
                                                    //         return (
                                                    //             <span className={classes.pdfDiv} style={{ position: "relative" }} key={index}>
                                                    //                 {fileExtension === "mp4" ? (
                                                    //                     <ReactPlayer
                                                    //                         controls={true}
                                                    //                         url={signedUrls[file]}
                                                    //                         className={styels.additionalDetailsVideo}
                                                    //                         style={{ margin: "auto" }}
                                                    //                     />

                                                    //                 ) : fileExtension === "pdf" ? (
                                                    //                     //  <PDFViewer  className={styels.additionalDetailsPdf} index={index} fileUrl={signedUrls[file]}/>
                                                    //                     <DocViewer documents={pdfUrls} pluginRenderers={DocViewerRenderers} config={docViewerConfig} />
                                                    //                 ) : null}
                                                    //                 <button type="button" onClick={() => deleteUploadedFile(index)} className={`${styels.removeBtn} ${styels.removeBtn}`}>
                                                    //                     <img src={deleteIcon} className={styels.deleteIcon} alt="Remove file" />
                                                    //                 </button>
                                                    //             </span>
                                                    //         );
                                                    //     }) : null
                                                }
                                            </div>
                                        </div>
                                    </div>
                                    <div className={styels.skillsDiv}>
                                        <label className={styels.titleText} htmlFor="allow">
                                            Allow access to
                                        </label>
                                        <Controller
                                            name="allow"
                                            control={control}
                                            defaultValue={allow}
                                            render={({ field }) => (
                                                <Select
                                                    placeholder="Select allow access to"
                                                    name="division"
                                                    options={allowAccessTo}
                                                    classNamePrefix="Select"
                                                    onChange={(values) => {
                                                        field.onChange(values);
                                                        setAllow(values);
                                                    }}
                                                    required
                                                    value={allow}
                                                />
                                            )}
                                        />
                                    </div>
                                    <div className={styels.submitDiv}>
                                        <button
                                            type="submit"

                                            className={styels.subBtn}
                                        >
                                            Submit
                                        </button>
                                    </div>

                                </form>

                            </>
                        ) : (

                            !showResource ?
                                (
                                    <div className={styels.skillsDiv}>
                                        {resources?.length ?
                                            <>
                                                <div className={draftStyles.draftsList}>
                                                    {resources.map((resource, index) => {
                                                        return (
                                                            <div key={index} className={draftStyles.draft}>
                                                                <div className={draftStyles.draftHeader}>
                                                                    <div className={draftStyles.thumdiv}>
                                                                        <img
                                                                            src={
                                                                                resource?.resource?.split('.').pop().toLowerCase() === "pdf" ? pdfIcon :
                                                                                    resource?.resource?.split('.').pop().toLowerCase() === "ppt" || resource?.resource?.split('.').pop().toLowerCase() === "pptx" ? pptIcon :
                                                                                        resource?.resource?.split('.').pop().toLowerCase() === "doc" || resource?.resource?.split('.').pop().toLowerCase() === "docx" ? docIcon :
                                                                                            videoIcon
                                                                            }
                                                                            alt="thumbnail"
                                                                            className={draftStyles.thumbnail}
                                                                        />
                                                                    </div>
                                                                    <div className={`${draftStyles.draftTitle} ${classes.draftTitle}`}>
                                                                        <h1>{resource.description}</h1>

                                                                    </div>
                                                                </div>
                                                                <div className={draftStyles.exportdiv}>
                                                                    <button onClick={() => onSelectedResource(resource)} className={draftStyles.editbtn}>View</button>
                                                                    {userRole === "admin" &&
                                                                        <img onClick={() => deleteUploadedFile(resource._id)} src={deleteIcon} className={classes.deleteIcon} alt="Remove file" />
                                                                    }

                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                </div>
                                            </>
                                            :
                                            null
                                        }
                                    </div>
                                )
                                :
                                (
                                    renderResourceView()
                                )
                        )
                    }
                </div>
            </div>
        </div>
    );
};

export default ResourcesTab;
