import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styels from "../common/Profile.module.css";
import exemplaryExpert from "../../assets/ExemplaryExpert.png";
import noviceAchiever from "../../assets/NoviceAchiever.png";
import skillfulPerformer from "../../assets/SkillfulPerformer.png";
import proficientContributor from "../../assets/ProficientContributor.png";
import outstandingAchiever from "../../assets/OutstandingAchiever.png";
import masterCollaborator from "../../assets/MasterCollaborator.png";
import distinguishedProfessional from "../../assets/DistinguishedProfessional.png";
import elitePerformer from "../../assets/ElitePerformer.png";
import exceptionalLeader from "../../assets/ExceptionalLeader.png";
import legendaryAchiever from "../../assets/LegendaryAchiever.png";
import bgImage from '../../assets/BackGround/bg3web.png';
import personImage from "../../assets/person.png";
import styleClasses from "./Candidate.module.css";
import { AssignProject, getApplications, sendMessage, shortListProject } from "../../services/index";
import { toast } from "react-toastify";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from "../user/ProjectDetails.module.css";
import LinkIcon from '../../assets/export.png'
import { openUrl } from "../../util/commenFunctions";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "33px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "80%",
    },
  },
}));

const Profile = (props) => {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [applications, setApplications] = useState([]);
  const [candidateData, setCandidateData] = useState(
    props.location.state?.item
  );
  const [readMore, setReadMore] = useState(false);
  const [modalStyle] = useState(getModalStyle);

  const projectData = props.location.state?.projectData;
  const skillsToShow = readMore ? candidateData?.skills : candidateData?.skills?.slice(0, 5);
  const hasMoreSkills = candidateData?.skills?.length > 5;
  const classes = useStyles();
  const assignedList = projectData?.applicants.filter(item => item.status === "assigned");

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };


  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const assignApplicantMesg = `
<p>Dear [Title Name],</p>

<p>Congratulations! We are pleased to announce that you have been selected for '[Project Title]'. Your skills and experience make you an ideal candidate for this project, and we are confident in your ability to contribute significantly.</p>

<p>Key details about the project, including timelines and specific requirements, are available on <a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. Kindly review it thoroughly and get in touch with the project sponsor if you have any questions or need further clarification.</p>

<p>We believe your expertise will greatly benefit the success of this project, and we look forward to a successful collaboration.</p>

<p>
Regards,<br/>
Team Titan Career Vista
</p>
  `;

  const assignManagerMesg = `
<p>Dear [Title Name],</p>

<p>Congratulations! We are pleased to announce that you have been selected for '[Project Title]'. Your skills and experience make you an ideal candidate for this project, and we are confident in your ability to contribute significantly.</p>

<p>Key details about the project, including timelines and specific requirements, are available on <a href="${process.env.REACT_APP_HOST_FRONTEND}/sponsor/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. Kindly review it thoroughly and get in touch with the project sponsor if you have any questions or need further clarification.</p>

<p>We believe your expertise will greatly benefit the success of this project, and we look forward to a successful collaboration.</p>

<p>
Regards,<br/>
Team Titan Career Vista
</p>
  `;

  const shortListedApplicantMesg = `
  <p>Dear [Title Name],</p>
   
  <p>We are pleased to inform you that you have been shortlisted for the project '[Project Title]' by [Sponsor Name].</p>
   
  <p>[Sponsor Name] will be in touch with you on next steps in the selection process. Key details about the project, including timelines and specific requirements, are available on <a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. Kindly review it thoroughly as you prepare for the next steps in the selection process.</p>
   
  <p>For any concern or queries, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a>. Congratulations on being shortlisted and here’s wishing you the very best.</p>

  <p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>
   
  <p>
  Regards,<br/>
  Team Titan Career Vista
  </p>
  `;

  const shortListedManagerMesg = `
  <p>Dear [Title Name],</p>
   
  <p>We are pleased to inform you that you have been shortlisted for the project '[Project Title]' by [Sponsor Name].</p>
   
  <p>[Sponsor Name] will be in touch with you on next steps in the selection process. Key details about the project, including timelines and specific requirements, are available on <a href="${process.env.REACT_APP_HOST_FRONTEND}/sponsor/project-details/${projectData?._id}">Bridge Projects Marketplace</a>. Kindly review it thoroughly as you prepare for the next steps in the selection process.</p>
   
  <p>For any concern or queries, feel free to write to us @DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net. Congratulations on being shortlisted and here’s wishing you the very best.</p>
   
  <p>
  Regards,<br/>
  Team Titan Career Vista
  </p>
  `;

  const handleAssign = async (userId) => {
    const applyData = {
      id: projectData?._id,
      userId,
    };
    const res = await AssignProject(applyData);
    if (res.data.status === "200") {
      const dataToSendApplicant = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: candidateData.email,
        titleName: candidateData.name,
        projectId: projectData.projectId,
        projectTitle: projectData.title,
        message: assignApplicantMesg,
        subject: `Congratulations! You've Been Selected for the Project: "[Project ID]: [Project Title]"`,
      };
      await sendMessage(dataToSendApplicant);
      const dataToSendManager = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: projectData.managerEmail,
        titleName: projectData.managerName,
        projectId: projectData.projectId,
        projectTitle: projectData.title,
        message: assignManagerMesg,
        subject: `Congratulations! You've Been Selected for the Project: "[Project ID]: [Project Title]"`,
      };
      await sendMessage(dataToSendManager);
      toast.success("Applicant Assigned Successfully");
      setCandidateData((prevData) => ({
        ...prevData,
        status: "assigned",
      }));
    }
  };

  const handleShortList = async (userId) => {
    if (parseInt(projectData?.openPositions) === assignedList?.length) {
      toast.error(`All open positions have been filled; further shortlisting disabled`);
      return;
    }
    const applyData = {
      id: projectData?._id,
      userId,
    };
    const res = await shortListProject(applyData);
    if (res.data.status === "200") {
      const dataToSendApplicant = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: candidateData.email,
        titleName: candidateData.name,
        sponsorName: projectData?.sponsorName,
        projectTitle: projectData.title,
        message: shortListedApplicantMesg,
        subject: `Congratulations! You've Been Shortlisted for the Project: "[Project Title]" | Bridge Projects`,
      };
      await sendMessage(dataToSendApplicant);
      const dataToSendManager = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: projectData.managerEmail,
        titleName: projectData.managerName,
        sponsorName: projectData.sponsorName,
        projectTitle: projectData.title,
        message: shortListedManagerMesg,
        subject: `Congratulations! You've Been Shortlisted for the Project: "[Project Title]" | Bridge Projects`,
      };
      await sendMessage(dataToSendManager);
      toast.success("Applicant Shortlisted Successfully");
      setCandidateData((prevData) => ({
        ...prevData,
        status: "shortlisted",
      }));
    }
  };

  const handleOtherApplications = async () => {
    const res = await getApplications(projectData?._id);
    if (res.data.status === "200") {
      const filterData = res.data.applications[0].applicants.filter(
        (item) => item.id !== candidateData.id && item.status === candidateData.status
      );
      setApplications(filterData);
    }
  };

  const handleCandidate = (item) => {
    setCandidateData(item);
    window.scrollTo(0, 0);
  };

  const goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    handleOtherApplications();
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    handleOtherApplications();
  }, [candidateData]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={styels.headdiv}>
        <h1>Badges</h1>
        <p>
          Here you’ll be able to see complete information about the badge
          system.{" "}
        </p>
      </div>
      <div className={styels.badgesdiv}>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={noviceAchiever}
              alt="Novice Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Novice Achiever</h3>
              <p>
                Earned after completing the first streak of a project
                successfully.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={skillfulPerformer}
              alt="Skillful Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Skillful Performer</h3>
              <p>Achieved after completing two streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              src={proficientContributor}
              alt="Proficient Contributor Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Proficient Contributor</h3>
              <p> Earned after completing three streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={outstandingAchiever} alt="Outstanding Achiever Bagde" />
            <div className={styels.badgetext}>
              <h3>Outstanding Achiever</h3>
              <p>Achieved after completing four streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={masterCollaborator} alt="Master Collaborator Bagde" />
            <div className={styels.badgetext}>
              <h3>Master Collaborator</h3>
              <p>Earned after completing five streaks of 5 projects each.</p>
            </div>
          </div>
        </div>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exemplaryExpert}
              alt="Exemplary Expert Bagde"
            />
            <div className={styels.badgetext}>
              <h3>ExemplaryExpert</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 5 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={distinguishedProfessional}
              alt="Distinguished Professional Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Distinguished Professional</h3>
              <p>
                {" "}
                Earned after receiving positive feedback from project managers
                on 10 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={elitePerformer}
              alt="Elite Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Elite Performer</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 15 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exceptionalLeader}
              alt="Exceptional Leader Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Exceptional Leader</h3>
              <p>
                Earned after receiving positive feedback from project managers
                on 20 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={legendaryAchiever}
              alt="Legendary Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Legendary Achiever</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 25 different projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="Profile-page">
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={`${styels.subdiv} ${styleClasses.subdivC}`}>
          <div className={styels.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="#"
              onClick={goBack}
            >
              <AiOutlineLeft className={styleClasses.arrow} />
            </NavLink>
            <h1 className={`${styels.head} ${styleClasses.heading}`}>
              Candidate Profile
            </h1>
          </div>
          <div className={styleClasses.containerSection}>
            <div
              className={`${styels.profileSection} ${styleClasses.profileSectionC}`}
            >
              <div
                className={`${styels.profile} ${styleClasses.profileC}`}
              >
                <img
                  src={candidateData.profilePhoto || personImage}
                  alt="User Profile Photo"
                  width="185px"
                  height="168px"
                />
                {/* <img
                  onClick={handleOpen}
                  className={`${styels.topBadge} ${styleClasses.topBadgeC}`}
                  src={exemplaryExpert}
                  alt="Exemplary Expert Badge"
                />
                <img
                  onClick={handleOpen}
                  className={`${styels.bottomBadge} ${styleClasses.bottomBadgeC}`}
                  src={noviceAchiever}
                  alt="Exemplary Expert Badge"
                /> */}
              </div>
              {/* <div
                className={`${styels.badgesTitle} ${styleClasses.badgesTitleM}`}
              >
                <button className={styleClasses.badgeBtn}>
                  Exemplary Expert{" "}
                </button>
                <button className={styleClasses.badgeBtn}>
                  Novice Achiever
                </button>
              </div> */}
              <div
                className={`${styels.skillDetails} ${styleClasses.skillDetailsC}`}
              >
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Name: </h3>
                  <div className={styles.sponsor}>
                    <p>{candidateData?.name ? candidateData?.name : "-"}</p>
                    {/* <Tippy content="View Candidate SF Profile" placement="right">
                      <img style={{ cursor: 'pointer' }} onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${candidateData.sponsorId}`, '_blank')} src={LinkIcon} alt="link" className={styles.link} />
                    </Tippy> */}
                  </div>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Function: </h3>
                  <p>{candidateData?.department === "null" ? "-" : candidateData?.department}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Division: </h3>
                  <p>{candidateData?.division === "null" ? "-" : candidateData?.division}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>SBU: </h3>
                  <p>{candidateData?.sbu === "null" ? "-" : candidateData?.sbu}</p>
                </div>
                {/* <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Ongoing Bridge Project: </h3>
                  <p>{projectCounts === 0 ? "-" : `${projectCounts} Project`}</p>
                </div> */}
                <div className={`${styels.info} ${styleClasses.skillInfoM}`}>
                  <h3>Skills: </h3>
                  <u1
                    className={`${styels.skillsList} ${styleClasses.skillsListC}`}
                  >
                    {skillsToShow && skillsToShow.some(skill => skill !== "null")
                      ? skillsToShow.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))
                      : <p>-</p>}
                    {hasMoreSkills && (
                      <li style={{ cursor: 'pointer' }} onClick={toggleReadMore}>
                        {readMore ? 'Show Less' : 'Read More'}
                      </li>
                    )}
                  </u1>
                </div>
              </div>
              <div
                className={`${styels.skillDetails} ${styleClasses.skillDetailsC}`}
              >
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Email: </h3>
                  <p>{candidateData?.email === "null" ? "-" : candidateData?.email}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Contact Number: </h3>
                  <p>{candidateData?.phone === "null" ? "-" : candidateData?.phone}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Experience: </h3>
                  <p>{candidateData?.experience === "null" ? "-" : candidateData?.experience}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.infoC}`}>
                  <h3>Level: </h3>
                  <p>{candidateData?.level === "null" ? "-" : candidateData?.level}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.skillInfoM}`}>
                  <h3>Location: </h3>
                  <p>{candidateData?.orgUnit === "null" ? "-" : candidateData?.orgUnit}</p>
                </div>
                <div className={`${styels.info} ${styleClasses.skillInfoM}`}>
                  <h3>Title: </h3>
                  <p>{candidateData?.title === "null" ? "-" : candidateData?.title}</p>
                </div>
              </div>
            </div>
            <div className={`${styels.aboutdiv} ${styleClasses.aboutdivC}`}>
              <h1>Message from applicant</h1>
              <p>{candidateData?.msg}</p>
            </div>
            <div className={styleClasses.otherdiv}>
              <div className={styleClasses.skillworkdiv}>
                <div className={styleClasses.skilldiv}>
                  {/* <div
                    className={`${styels.badgesTitle} ${styleClasses.badgesTitleC}`}
                  >
                    <button className={styleClasses.badgeBtn}>
                      Exemplary Expert{" "}
                    </button>
                    <button className={styleClasses.badgeBtn}>
                      Novice Achiever
                    </button>
                  </div> */}
                  {/* <div className={`${styels.info} ${styleClasses.skillInfo}`}>
                    <h3>Skills: </h3>
                    <u1
                      className={`${styels.skillsList} ${styleClasses.skillsListC}`}
                    >
                      <li>Designing</li>
                      <li>Communication</li>
                      <li>Client Handling</li>
                      <li>Adobe Photoshop</li>
                    </u1>
                  </div> */}
                </div>
                {/* <div className={styels.workSection}>
                  <h1>Previous Work: </h1>
                  <div className={styels.projects}>
                    <ul className={`${styels.scrool} ${styleClasses.scroolC}`}>
                      <li className={styels.project}>
                        <div className={styels.projectTitle}>
                          <h1>Graphic Designer</h1>
                          <div className={styels.rating}>
                            {Array.from({ length: 5 }).map((_, i) => (
                              <img
                                className={styels.star}
                                src={starImg}
                                alt="star"
                                key={i}
                              />
                            ))}
                          </div>
                        </div>
                        <p>Duration: 2 months</p>
                        <h4>
                          Feedback from Project Sponsor: “Your exceptional work
                          ethic, attention to detail, and collaborative approach
                          have made a significant impact on the success of the
                          project. Thank you for your valuable contributions!”
                        </h4>
                      </li>
                      <li className={styels.project}>
                        <div className={styels.projectTitle}>
                          <h1>Project Manager</h1>
                          <div className={styels.rating}>
                            {Array.from({ length: 4 }).map((_, i) => (
                              <img
                                className={styels.star}
                                src={starImg}
                                alt="star"
                                key={i}
                              />
                            ))}
                            {Array.from({ length: 1 }).map((_, i) => (
                              <img
                                className={styels.star}
                                src={emptyStar}
                                alt="star"
                                key={i}
                              />
                            ))}
                          </div>
                        </div>
                        <p>Duration: 2 months</p>
                        <h4>
                          Feedback from Project Sponsor: “Your exceptional work
                          ethic, attention to detail, and collaborative approach
                          have made a significant impact on the success of the
                          project. Thank you for your valuable contributions!”
                        </h4>
                      </li>
                      <li className={styels.project}>
                        <div className={styels.projectTitle}>
                          <h1>Graphic Designer</h1>
                          <div className={styels.rating}>
                            {Array.from({ length: 5 }).map((_, i) => (
                              <img
                                className={styels.star}
                                src={starImg}
                                alt="star"
                                key={i}
                              />
                            ))}
                          </div>
                        </div>
                        <p>Duration: 2 months</p>
                        <h4>
                          Feedback from Project Sponsor: “Your exceptional work
                          ethic, attention to detail, and collaborative approach
                          have made a significant impact on the success of the
                          project. Thank you for your valuable contributions!”
                        </h4>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
              <div className={styleClasses.applicationsdiv}>
                <div className={styleClasses.headdiv}>
                  <h1>Other Applications</h1>
                  <p>Preferable candidates for projects</p>
                </div>
                {applications.length === 0 ? (
                  <h1 style={{ textAlign: "center" }}>
                    No other applications have been received at this moment
                  </h1>
                ) : (
                  <ul className={styleClasses.recommendlist}>
                    {applications.map((item, index) => {
                      return (
                        <li className={styleClasses.recommendli} key={index}>
                          <div className={styleClasses.wraper}>
                            <div
                              className={styleClasses.profile}
                              style={{
                                backgroundImage: `url(${personImage})`,
                              }}
                            ></div>
                            <div className={styleClasses.details}>
                              <h1>{item.name}</h1>
                              <p>{item.level}; {item.department}; {item.orgUnit}</p>
                            </div>
                          </div>
                          <button onClick={() => handleCandidate(item)}>
                            See Profile
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            </div>
            {(candidateData?.status === "shortlisted" || candidateData?.status === "applied" || candidateData?.status === "assigned") && (
              <button
                className={`${styleClasses.assignbtn} ${(candidateData?.status === "shortlisted" || candidateData?.status === "applied") ? '' : styleClasses.shortListed
                  }`}
                onClick={() => candidateData?.status === "shortlisted" ? handleAssign(candidateData.id) : candidateData?.status === "applied" ? handleShortList(candidateData.id) : null}
              >
                {candidateData?.status === "assigned" ? "Assigned" : candidateData?.status === "applied" ? "Shortlist" : candidateData?.status === "shortlisted" ? "Assign" : null}
              </button>
            )}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Profile;
