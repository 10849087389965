import React, { useEffect, useState } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./Dashboard.module.css";
import Navbar from "../common/Navbar";
import {
  getRecommendationList,
  getUserProjectCount,
  getUserFilterProject,
} from "../../services/index";
import image from "../../assets/titanlogo.png";
import bgImage from '../../assets/BackGround/bg1web.png';
import { dateFormat, openUrl } from "../../util/commenFunctions";
import Preview from "./Preview";
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import { thumbnails } from "../common/Images";
import exportIcon from "../../assets/export.png";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styleClasses from "./ProjectDetails.module.css";

function getModalStyle() {
  const top = 60;
  const left = 60;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
  },
}));


const openNewTab = (data) => {
  window.localStorage.setItem("projectId", data._id);
  const newTab = window.open("/applicant/project-details", "_blank");
  newTab.focus();
};

const Dashboard = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [recommendations, setRecommendations] = React.useState([]);
  const [selectedProject, setSelectedProject] = useState([]);
  const [allProjects, setAllProjects] = React.useState([]);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const history = useHistory();

  const handleOpen = (recommendation) => {
    setSelectedProject(recommendation);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const getRecommendations = async () => {
    const res = await getRecommendationList({
      userId: window.localStorage.getItem("userId"),
      level: window.localStorage.getItem("userLevel"),
    });
    if (res.data.status === "200") {
      setRecommendations(res.data.projects);
    }
  };

  const getProjectCountsList = async () => {
    const userId = window.localStorage.getItem("userId");
    const res = await getUserProjectCount(userId);
    if (res.data.status === "200") {
      setAllProjects(res.data);
    }
  };

  const filterProjects = async () => {
    const userId = window.localStorage.getItem("userId");
    const requestData = {
      userId,
      searchText,
      employeeType,
      projectType,
      projectStatus,
      level: window.localStorage.getItem("userLevel"),
    };
    const res = await getUserFilterProject(requestData);
    if (res.data.status === "200") {
      setRecommendations(res.data.recommendationResult);
      setAllProjects(res.data);
    }
  };

  const preview = (
    <Preview
      recommendation={selectedProject}
      onApply={handleClose}
      getRecommendations={getRecommendations}
      getProjectCountsList={getProjectCountsList}
    />
  );

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getRecommendations();
      getProjectCountsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getRecommendations();
      getProjectCountsList();
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.subdiv}>
          <h1 className={styles.head}>Dashboard {window.localStorage.getItem("userRole") === "sponsor" && " - Applicant"}</h1>
          {
            window.localStorage.getItem("userRole") === "sponsor" &&
            <p className={styles.navtext} onClick={() => history.push("/sponcor/dashboard")}>Click here to switch to Project Sponsor Dashboard</p>
          }
          <Filter />
          <div className={styles.cardsDiv}>
            <div className={styles.card}>
              <div className={styles.countDiv}>
                <h1>Applied & Completed Projects</h1>
                <p>
                  Total Projects:{" "}
                  {allProjects?.appliedResult?.length ||
                    allProjects?.completedResult?.length
                    ? allProjects?.appliedResult?.length +
                    allProjects?.completedResult?.length
                    : 0}
                </p>
              </div>
              <div>
                <NavLink to="/applicant/applied-completed-projects">
                  <button className={styles.viewbtn}>View all</button>
                </NavLink>
              </div>
            </div>
            <div
              className={styles.card}
              style={{ backgroundColor: "#8364AB", border: "1px solid pink" }}
            >
              <div className={styles.countDiv}>
                <h1>Ongoing Project</h1>
                <p>
                  Total Projects:{" "}
                  {allProjects?.onGoingResult?.length
                    ? allProjects?.onGoingResult?.length
                    : 0}
                </p>
              </div>
              <div className={styles.btnDiv}>
                <NavLink to="/applicant/ongoing-projects">
                  <button className={styles.viewbtn}>View all</button>
                </NavLink>
              </div>
            </div>
          </div>
          <h1 className={styles.subHead}>Projects Available</h1>
          <div className={styles.projectsDiv}>
            {recommendations?.map((recommendation, index) => {
              return (
                <div className={styles.draft} key={index}>
                  <NavLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={{
                      pathname: "/applicant/project-details",
                      state: {
                        recommendation,
                        recommendations,
                      },
                      setRecommendations,
                      getProjectCountsList,
                    }}
                  >
                    <div className={styles.draftHeader}>
                      <div className={styles.thumdiv}>
                        <img
                          src={
                            recommendation.thumbnail === "" ||
                              recommendation.thumbnail === "undefined"
                              ? thumbnails[recommendation.localThumbnail]?.path || image
                              : recommendation.thumbnail
                          }
                          alt="thumbnail"
                          className={styles.thumbnail}
                        />
                      </div>
                      <div className={styles.draftTitle}>
                        <h1>{recommendation.title}</h1>
                        <p>Posted on {dateFormat(recommendation.createdAt)}</p>
                        <div className={styleClasses.sponsor}>
                          <p>{JSON.parse(recommendation.createdBy).name}</p>
                          {/* <Tippy content="View Sponsor Profile" placement="bottom">
                            <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${recommendation.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={styleClasses.link} />
                          </Tippy> */}
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <div className={styles.exportdiv}>
                    <button
                      className={styles.editbtn}
                      onClick={() => handleOpen(recommendation)}
                    >
                      Apply
                    </button>
                    <img
                      onClick={() => openNewTab(recommendation)}
                      src={exportIcon}
                      className={styles.export}
                      title="Open in new tab"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {preview}
      </Modal>
    </div>
  );
};

export default Dashboard;
