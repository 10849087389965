// reducers.js
import * as actionTypes from "../util/actionTypes";

const initialState = {
  searchText: localStorage.getItem("filterSearchText") || "",
  employeeType: JSON.parse(localStorage.getItem("filterEmployeeType")) || [],
  projectType: JSON.parse(localStorage.getItem("filterProjectType")) || [],
  projectStatus: JSON.parse(localStorage.getItem("filterProjectStatus")) || [],
};

const filterReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_TEXT:
      const newSearchText = action.payload;
      localStorage.setItem("filterSearchText", newSearchText);
      return {
        ...state,
        searchText: newSearchText,
      };

    case actionTypes.SET_EMPLOYEE_TYPE:
      const newEmployeeType = action.payload;
      localStorage.setItem(
        "filterEmployeeType",
        JSON.stringify(newEmployeeType)
      );
      return {
        ...state,
        employeeType: newEmployeeType,
      };

    case actionTypes.SET_PROJECT_TYPE:
      const newProjectType = action.payload;
      localStorage.setItem("filterProjectType", JSON.stringify(newProjectType));
      return {
        ...state,
        projectType: newProjectType,
      };

    case actionTypes.SET_PROJECT_STATUS:
      const newProjectStatus = action.payload;
      localStorage.setItem(
        "filterProjectStatus",
        JSON.stringify(newProjectStatus)
      );
      return {
        ...state,
        projectStatus: newProjectStatus,
      };

    default:
      return state;
  }
};

export default filterReducer;
