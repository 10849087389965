import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styles from "./OngoingProjects.module.css";
import Navbar from "../common/Navbar";
import {
  getUserProjectCount,
  getUserFilterProject,
} from "../../services/index";
import image from "../../assets/titanlogo.png";
import bgImage from '../../assets/BackGround/bg5web.png';
import { dateFormat, openUrl } from "../../util/commenFunctions";
import { AiOutlineLeft } from "react-icons/ai";
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import { thumbnails } from "../common/Images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import classes from "./ProjectDetails.module.css";
import exportIcon from "../../assets/export.png";
const OngoingProjects = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [onGoingProjects, setOnGoingProjects] = useState([]);

  const getProjectsList = async () => {
    const userId = window.localStorage.getItem("userId");
    const res = await getUserProjectCount(userId);
    if (res.data.status === "200") {
      setOnGoingProjects(res.data.onGoingResult);
    }
  };

  const filterProjects = async () => {
    const userId = window.localStorage.getItem("userId");
    const requestData = {
      userId,
      searchText,
      employeeType,
      projectType,
      projectStatus,
      level: window.localStorage.getItem("userLevel"),
    };
    const res = await getUserFilterProject(requestData);
    if (res.data.status === "200") {
      setOnGoingProjects(res.data.onGoingResult);
    }
  };

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getProjectsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getProjectsList();
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styles.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.subdiv}>
          <div className={styles.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/applicant/dashboard"
            >
              <AiOutlineLeft style={{ fontSize: "32px" }} />
            </NavLink>
            <h1 className={styles.subHead}>Ongoing Projects</h1>
          </div>
          <Filter />
          <div className={styles.projectsDiv}>
            {onGoingProjects.map((project, index) => {
              return (
                <div className={styles.draft} key={index}>
                  <NavLink
                    style={{ textDecoration: "none", color: "black" }}
                    to={{
                      pathname: "/applicant/project-details",
                      state: { recommendation: project },
                    }}
                  >
                    <div className={styles.draftHeader}>
                      <div className={styles.thumdiv}>
                        <img
                          src={
                            project.thumbnail === "" ||
                              project.thumbnail === "undefined"
                              ? thumbnails[project.localThumbnail]?.path || image
                              : project.thumbnail
                          }
                          alt="thumbnail"
                          className={styles.thumbnail}
                        />
                      </div>
                      <div className={styles.draftTitle}>
                        <h1>{project.title}</h1>
                        <p>Posted on {dateFormat(project.createdAt)}</p>
                        <div className={classes.sponsor}>
                          <p>{JSON.parse(project.createdBy).name}</p>
                          {/* <Tippy content="View Sponsor Profile" placement="bottom">
                            <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${project.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={classes.link} />
                          </Tippy> */}
                        </div>
                      </div>
                    </div>
                  </NavLink>
                  <div className={styles.exportdiv}>
                    <NavLink
                      to={{
                        pathname: "/applicant/project-update-status",
                        state: project,
                      }}
                    >
                      <button className={styles.editbtn}>Update Status</button>
                    </NavLink>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingProjects;
