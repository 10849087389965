import React, { useEffect, useState } from "react";
import styles from "./ThankYou.module.css";
import logo from "../../assets/titanlogo.png";
import { projectUpdateStatus, getProjectDetails, sendMessage, saveModifications } from "../../services";
import Navbar from "./Navbar";
import ReactQuill from "react-quill";
import styels from '../sponcor/CreateProject.module.css';
import bgImage from '../../assets/BackGround/bg4web.png';

const ThankYouPage = ({ loggedIn }) => {
  const [action, setAction] = useState(null);
  const [clarification, setClarification] = useState('');
  const [isModificationsSent, setIsModificationsSent] = useState(false);
  const currentUrl = window.location.pathname;
  const modifiedUrl = currentUrl.replace("/thank-you/", "");
  const urlArray = modifiedUrl?.split("/");

  const approveMsg = `
<p>Dear [Title Name],</p>

<p>[Manager Name] has approved your Project "[Project ID]: [Project Title]".</p>
<p>The project has moved from ‘Pending Projects’ to ‘Open Projects’ on the platform. It is now available for all eligible Applicants to apply.</p>

<p>Here’s the next steps you can take to ensure a successful start to your project:</p>
<ol>
  <li>Popularise your project and seek applications</li>
  <li>Shortlist applications on the platform</li>
  <li>Set-up interviews with shortlisted applicants</li>
  <li>Select an applicant and assign to the project</li>
</ol>

<p>We hope you find the right match for your project in the stipulated time!</p>
<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const rejectMsg = `
<p>Dear [Title Name],</p>

<p>[Manager Name] has suggested that you make modifications on the Project "[Project ID]: [Project Title]".</p>
<p>[Manager Name]'s suggestion is as follows:</p>

" ${clarification} "

<p>The project has moved from ‘Pending Projects’ to ‘Drafts’ on the platform. It is now available for further editing by you.</p>
<p>Kindly make the recommended changes in the project draft and re-submit the project for approval.</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const SendEmail = async (projectData) => {
    const dataToSend = {
      fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
      toEmail: JSON.parse(projectData.data.project.createdBy).email,
      titleName: JSON.parse(projectData.data.project.createdBy).name,
      ccEmail: projectData.data.project.managerEmail,
      subject: urlArray[1] === "1" ? `Your Bridge Project "[Project ID]" has been approved by "[Manager Name]"` : `Clarification sought by "[Manager Name]" on your Bridge Project "[Project ID]"`,
      message: urlArray[1] === "1" ? approveMsg : rejectMsg,
      managerName: projectData.data.project.managerName,
      projectId: projectData.data.project.projectId,
      projectTitle: projectData.data.project.title,
    };
    await sendMessage(dataToSend);
  }

  const handleApproveReject = async () => {
    const projectData = await getProjectDetails(urlArray[0]);
    if (projectData.data.project.managerEmail === localStorage.getItem("userEmail")) {
      if (projectData.data.project.status === "pending" && urlArray[1] === "1") {
        const res = await projectUpdateStatus({
          projectId: urlArray[0],
          status: "open",
        });
        if (res.data.status === "200") {
          await SendEmail(projectData)
          setAction("accepted");
        }
      } else if (projectData.data.project.status === "pending" && urlArray[1] === "2") {
        handleModicificationRequest();
        setAction("accepted");
      } else {
        setAction("rejected");
      }
    } else {
      setAction("denied");
    }
  };

  useEffect(() => {
    setAction(null);
    handleApproveReject();
  }, []);

  const onSuggestModifications = async () => {
    const saveModificationsData = await saveModifications({
      projectId: urlArray[0],
      needMmodificationReason: clarification
    })
    if (saveModificationsData.data.status === "200") {
      setIsModificationsSent(true);
      const projectData = await getProjectDetails(urlArray[0]);
      await SendEmail(projectData)
    }
  }

  const handleModicificationRequest = () => {
    return (
      <div>
        <div className={styels.descriptionMargin}>
          <label htmlFor="description" className={styels.titleText}>
            Request for Project Modification
          </label>
          <p className={styels.instructionText}>
            Please provide your recommendations for changes in the project here, ensuring clarity and guidance for the sponsor.
          </p>
          <ReactQuill
            placeholder=""
            style={{ height: "150px" }}
            theme="snow"
            id="description"
            value={clarification}
            onChange={(input) => setClarification(input)}
          />
        </div>
        <button
          onClick={onSuggestModifications}
          className={styels.subBtn}
        >
          Submit
        </button>
      </div>
    )
  }

  return (
    <div className={styles.thankYouPage}>
      <Navbar />
      <div className={styles._header} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styles.logo}>
          <img src={logo} alt="Titan Logo" />
        </div>
      </div>
      <div className={styles._body}>
        <div className={styles._box}>
          {action === "accepted" && (
            urlArray[1] === "1" ?
              <h2 style={{ color: "green" }}> "You have successfully approved the project submission." </h2> : (urlArray[1] === "2" && !isModificationsSent) ? handleModicificationRequest() : <h2 style={{ color: "green" }}>Thank you! Project Modification Request submitted successfully.</h2>
          )}
          {action === "rejected" && (
            <h2 style={{ color: "red" }}>{urlArray[1] === "1" ? "This project has already been approved." : "This project modification has already been submitted."}</h2>
          )}
          {action === "denied" && (
            <h2 style={{ color: "red" }}>Sorry, you are not authorized to perform this action.</h2>
          )}
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
