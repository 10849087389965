import corporate from "../../assets/Banners/Corporate.png";
import digital from "../../assets/Banners/Digital.png";
import generic from "../../assets/Banners/Generic.png";
import generic2 from "../../assets/Banners/Generic2.png"
import manufacturing from "../../assets/Banners/Manufacturing.png";
import marketing from "../../assets/Banners/Marketing.png";
import supplyChain from "../../assets/Banners/SupplyChain.png";

import csr from "../../assets/Thumbnails/CSR.png";
import digitalT from "../../assets/Thumbnails/Digital.png";
import genericT from "../../assets/Thumbnails/Generic.png";
import manufacturingT from "../../assets/Thumbnails/Manufacturing.png";
import othersGeneric from "../../assets/Thumbnails/Others(Generic).png"
import sales from "../../assets/Thumbnails/Sales.png";
import supplyChainT from "../../assets/Thumbnails/SupplyChain.png";

export const thumbnails = [
    {
        key: 0,
        name: "csr",
        path: genericT
    },
    {
        key: 1,
        name: "digital",
        path: digitalT
    },
    {
        key: 2,
        name: "manufacturing",
        path: manufacturingT
    },
    {
        key: 3,
        name: "Others Generic",
        path: othersGeneric
    },
    {
        key: 4,
        name: "sales",
        path: sales
    },
    {
        key: 5,
        name: "supply chain",
        path: supplyChainT
    },
    {
        key: 6,
        name: "generic",
        path: csr
    },
]

export const banners = [
    {
        key: 0,
        name: "corporate",
        path: generic2
    },
    {
        key: 1,
        name: "digital",
        path: digital
    },
    {
        key: 2,
        name: "generic",
        path: generic
    },
    {
        key: 3,
        name: "manufacturing",
        path: manufacturing
    },
    {
        key: 4,
        name: "marketing",
        path: marketing
    },
    {
        key: 5,
        name: "supply chain",
        path: supplyChain
    },
    {
        key: 6,
        name: "corporate",
        path: corporate
    },

]