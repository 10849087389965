import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineLeft } from "react-icons/ai";
import Navbar from "../common/Navbar";
import styels from "./Drafts.module.css";
import classes from "./PendingProjects.module.css";
import {
  getAdminProjectsList,
  getAdminFilterProject,
  projectUpdateStatus,
  sendMessage
} from "../../services/index";
import { dateFormat, openUrl } from "../../util/commenFunctions";
import image from "../../assets/titanlogo.png";
import exportIcon from "../../assets/export.png";
import bgImage from '../../assets/BackGround/bg4web.png';
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import { thumbnails } from "../common/Images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from "../user/ProjectDetails.module.css";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "20px 40px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "80%",
    },
  },
}));

const PendingProjects = () => {
  const history = useHistory();
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [pendingProjects, setPendingProjects] = useState([]);
  const [open, setOpen] = useState(false);
  const [modalStyle] = useState(getModalStyle);
  const [selcetdProject, setSelcetdProject] = useState();
  const [reason, setReason] = useState("");

  const modalClasses = useStyles();
  const maxCharacters = 100;

  const approvalMsg = `
<p>Dear [Title Name],</p>

<p>Administrator has approved your Project "[Project ID]: [Project Title]".</p>

</p>The project has moved from ‘Pending Projects’ to ‘Open Projects’ on the platform. It is now available for all eligible Applicants to apply.</p>

<p>Here’s the next steps you can take to ensure a successful start to your project:</p>
<ol>
  <li>Popularise your project and seek applications</li>
  <li>Shortlist applications on the platform</li>
  <li>Set-up interviews with shortlisted applicants</li>
  <li>Select an applicant and assign to the project</li>
</ol>

<p>We hope you find the right match for your project in the stipulated time!</p>
<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const rejectMsg = `
<p>Dear [Title Name],</p>

<p>Administrator has suggested that you make modifications on the Project "[Project ID]: [Project Title]". The modification recommendation is as follows:
[Admin Comment]</p>

<p>The project has moved from ‘Pending Projects’ to ‘Drafts’ on the platform. It is now available for further editing by you.</p>

<p>To ensure a successful approval of the project, we recommend you to make suggested modifications in the project draft and re-submit the project for approval.</p>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacters) {
      setReason(inputValue);
    }
  };

  const handleReason = async () => {
    if (reason) {
      const applyData = {
        projectId: selcetdProject._id,
        rejectReason: reason,
        status: "rejected",
      };
      const res = await projectUpdateStatus(applyData);
      if (res.data.status === "200") {
        toast.success("Project rejected successfully!");
        handleClose();
        getPendingProjectsList()
        const dataToSend = {
          fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
          toEmail: JSON.parse(selcetdProject.createdBy).email,
          titleName: JSON.parse(selcetdProject.createdBy).name,
          ccEmail: window.localStorage.getItem("userEmail"),
          projectId: selcetdProject.projectId,
          projectTitle: selcetdProject.title,
          reason: reason,
          message: rejectMsg,
          subject: `Modification required for your Bridge Project "[Project ID]: [Project Title]"`,
        };
        await sendMessage(dataToSend);
      }
    } else {
      toast.error("Please enter a message before submitting");
    }
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateStatus = async (project, status) => {
    setSelcetdProject(project);
    const applyData = {
      projectId: project._id,
      status,
    };
    const res = await projectUpdateStatus(applyData);
    if (res.data.status === "200") {
      toast.success("Project approved successfully!");
      getPendingProjectsList()
      const dataToSend = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: JSON.parse(project.createdBy).email,
        titleName: JSON.parse(project.createdBy).name,
        projectId: project.projectId,
        projectTitle: project.title,
        ccEmail: window.localStorage.getItem("userEmail"),
        message: approvalMsg,
        subject: `Your Bridge Project "[Project ID]" has been approved`,
      };
      await sendMessage(dataToSend);
    }
  };

  const getPendingProjectsList = async () => {
    const searchData = {
      status: "pending",
    };
    const res = await getAdminProjectsList(searchData);
    if (res.data.status === "200") {
      setPendingProjects(res.data.projects);
    }
  };

  const filterProjects = async () => {
    const requestData = {
      searchText,
      employeeType,
      projectType,
      projectStatus,
    };
    const res = await getAdminFilterProject(requestData);
    if (res.data.status === "200") {
      setPendingProjects(res.data.pendingResult);
    }
  };

  const body = (
    <div style={modalStyle} className={modalClasses.paper}>
      <div className={classes.aboutdiv}>
        <h1>Rejection Reason</h1>
        <textarea
          placeholder="Write something in 100 characters."
          value={reason}
          onChange={handleChange}
          maxLength={maxCharacters}
        />
      </div>
      <button className={classes.assignbtn} onClick={() => handleReason()}>
        Submit
      </button>
    </div>
  );

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getPendingProjectsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getPendingProjectsList();
    }
  }, []);

  const openNewTab = (data) => {
    window.localStorage.setItem("projectId", data._id);
    const newTab = window.open("/admin/project-details", "_blank");
    newTab.focus();
  };

  return (
    <div>
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={`${styels.subdiv} ${classes.subdiv}`}>
          <div className={styels.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/admin/dashboard"
            >
              <AiOutlineLeft style={{ fontSize: "32px" }} />
            </NavLink>
            <h1 className={styels.head}>Pending Projects</h1>
          </div>
          <div style={{ padding: "0px 30px" }}>
            <Filter />
          </div>
          <div className={styels.draftsList}>
            {pendingProjects.map((project, index) => {
              const finalProject = { ...project, index };
              return (
                <div className={styels.draft} key={index}>
                  <div className={styels.draftHeader}>
                    <div className={styels.thumdiv}>
                      <img
                        src={
                          project.thumbnail === "" ||
                            project.thumbnail === "undefined"
                            ? thumbnails[project.localThumbnail]?.path || image
                            : project.thumbnail
                        }
                        alt="thumbnail"
                        className={styels.thumbnail}
                      />
                    </div>
                    <div className={styels.draftTitle}>
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/admin/project-details",
                          state: { project: finalProject },
                        }}
                      >
                        <h1>{project.title}</h1>
                      </NavLink>
                      <p>Posted on {dateFormat(project.createdAt)}</p>
                      <div className={styles.sponsor}>
                        <p>{JSON.parse(project.createdBy).name}</p>
                        {/* <Tippy content="View Sponsor Profile" placement="bottom">
                          <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${project.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={styles.link} />
                        </Tippy> */}
                      </div>
                    </div>
                  </div>
                  <div className={classes.exportdiv}>
                    <button
                      className={classes.approveBtn}
                      onClick={() => handleUpdateStatus(project, "open")}
                    >
                      Approve
                    </button>
                    <button
                      className={classes.rejectBtn}
                      onClick={() => {
                        setSelcetdProject(project)

                        handleOpen()
                      }}
                    >
                      Reject
                    </button>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={{
                        pathname: "/admin/edit-project",
                        state: { draft: finalProject, isEditProject: true },
                      }}
                    >
                      <button className={`${styels.editbtn} ${classes.editbtn}`}>Edit</button>
                    </NavLink>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={{
                        pathname: "/admin/project-details",
                        state: { project: finalProject },
                      }}
                    >
                      <button className={styels.editbtn}>View Details</button>
                    </NavLink>
                    <img
                      onClick={() => openNewTab(finalProject)}
                      src={exportIcon}
                      className={classes.export}
                      title="Open in new tab"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default PendingProjects;
