import {
  SET_SEARCH_TEXT,
  SET_EMPLOYEE_TYPE,
  SET_PROJECT_TYPE,
  SET_PROJECT_STATUS,
} from "../util/actionTypes";

export const setSearchText = (text) => ({
  type: SET_SEARCH_TEXT,
  payload: text,
});

export const setEmployeeType = (employeeType) => ({
  type: SET_EMPLOYEE_TYPE,
  payload: employeeType,
});

export const setProjectType = (projectType) => ({
  type: SET_PROJECT_TYPE,
  payload: projectType,
});

export const setProjectStatus = (projectStatus) => ({
  type: SET_PROJECT_STATUS,
  payload: projectStatus,
});
