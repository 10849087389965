import { combineReducers } from "redux";
import profileReducer from "./profileReducer";
 import tokenReducer from "./tokenReducer";
import filterReducer from "./filterReducer";

export default combineReducers({
  profile: profileReducer,
  filter: filterReducer,
  token: tokenReducer
});
