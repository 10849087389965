import React, { useState, useEffect, useRef } from "react"; // useCallback
import { connect } from "react-redux";
// import SearchIcon from "@material-ui/icons/Search";
// import NotificationsNoneOutlinedIcon from "@material-ui/icons/NotificationsNoneOutlined";
import Avatar from "@material-ui/core/Avatar";
import TitalLogo from "../../assets/light-logo.png";
import Badge from "@material-ui/core/Badge";
import { AiOutlineBars } from "react-icons/ai";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { Link, NavLink, useHistory } from "react-router-dom";
import {
  checkTokenValidity,
  updateLog
} from "../../services";
import styleClasses from "./Navbar.module.css";
import logo from "../../assets/light-logo.png";
import { withStyles } from "@material-ui/core/styles";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import PersonOutlineIcon from "@material-ui/icons/PersonOutline";
import AssessmentOutlinedIcon from "@material-ui/icons/AssessmentOutlined";
import DataUsageOutlinedIcon from '@material-ui/icons/DataUsageOutlined';
import SyncAltOutlinedIcon from "@material-ui/icons/SyncAltOutlined";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { employeeLevel, parseJwt } from "../../util/commenFunctions";
import Cookies from "js-cookie";
import useAutoLogout from "./useAutoLogout";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

function Navbar({
  loggedIn,
  logout,
  profile,
  isSearchActive,
  refreshNo,
  ...rest
}) {
  useAutoLogout(600000)
  const checkToken = Cookies.get("token")
  const decodedJwt = parseJwt(checkToken);
  const currentUrl = window.location.pathname;
  // const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  // const [currentUserLastSeenTime, setCurrentUserLastSeenTime] = useState(0);
  // const [countOfNotifications, setcountOfNotifications] = useState(0);
  // const [dashboardUrl, setDashboardUrl] = useState("/sponsor/dashboard");

  const searchRef = useRef(null);
  const navigate = useHistory();
  const [anchorE2, setAnchorE2] = useState(null);

  const [isAuthenticated, setIsAuthenticated] = useState(true);

  useEffect(() => {

    const verifyToken = async () => {
      const res = await checkTokenValidity();
      if (res.data.status === 401 || res.data.status === 403) {
        window.localStorage.clear();
        Cookies.remove("token");
        Cookies.remove("connect.sid");
        navigate.push("/");
        setIsAuthenticated(false);
      }
      else {
        setIsAuthenticated(true);
      }
    };

    verifyToken();

  }, [loggedIn]);


  const handleClick2 = (event) => {
    setAnchorE2(event.currentTarget);
  };

  const handleClose2 = () => {
    setAnchorE2(null);
  };

  //  ------------------------
  // const { fullname } = profile; // email
  // -------------------------

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogOut = async () => {
    try {
      const response = await updateLog({ id: window.localStorage.getItem("logId"), end: new Date().getTime() });
      if (response.data.status === "200") {
        window.localStorage.clear();
        Cookies.remove("token");
        Cookies.remove("connect.sid");
        navigate.push("/");
      }
    }
    catch (err) {
      console.error(err);
    }
  };

  // const getCurrentUserLastSeen = async () => {
  //   const result = await getUserLastSeenTime(email);
  //   if (result?.data?.status === "200") {
  //     const lt = result.data.lastSeenTime;
  //     setCurrentUserLastSeenTime(() => {
  //       return result.data.lastSeenTime;
  //     });
  //     const result2 = await getMyNotificationsCount(email);
  //     const cnt = result2.data.notification;
  //     const unSeen = cnt?.filter((notification) => notification.addedOn > lt);
  //     setcountOfNotifications(unSeen?.length);
  //   }
  // };

  // const getNotificationsCount = useCallback(async () => {
  //   const result = await getMyNotificationsCount(email);
  //   const cnt = result.data.notification;
  //   const unSeen = cnt?.filter(
  //     (notification) => notification.addedOn > currentUserLastSeenTime
  //   );
  //   setcountOfNotifications(unSeen?.length);
  // }, [currentUserLastSeenTime]);

  // useEffect(()=>{
  //   getCurrentUserLastSeen();
  //   const interval = setInterval(() => {
  //        getCurrentUserLastSeen();
  //   }, 1000);
  //   return () => clearInterval(interval);
  // },[])

  // useEffect(() => {}, [countOfNotifications]);

  // useEffect(() => {}, [currentUserLastSeenTime]);

  // useEffect(() => {
  //   if (isSearchActive) searchRef.current.focus();
  // }, []);

  useEffect(() => {
    if (decodedJwt.userRole === "user" &&
      (currentUrl.includes("sponsor") || currentUrl.includes("admin"))) {
      navigate.push("/applicant/dashboard");
    } else if (decodedJwt.userRole === "sponsor" && currentUrl.includes("admin")) {
      navigate.push("/sponsor/dashboard");
    } else if (decodedJwt.userRole === "admin" && (currentUrl.includes("sponsor") || currentUrl.includes("applicant"))) {
      navigate.push("/admin/dashboard");
    }
  }, [decodedJwt.userRole]);

  return (
    <div>
      <div className={styleClasses.navbarContainer}>
        <NavLink
          style={{ textDecoration: "none" }}
          to={() =>
            employeeLevel(decodedJwt.userRole)
          }
        >
          <div className={styleClasses.logoDiv}>
            <img src={logo} alt="company logo"></img>
          </div>
          <div className={styleClasses.logoDivSmall}>
            <img src={logo} alt="company logo"></img>
          </div>
        </NavLink>
        {/* <div className={styleClasses.searchBarDiv}>
                  <NavLink style={{textDecoration:'none',color:'black'}} to="/search" > 
                     <div className={styleClasses.searchInputContainer} >
                        <div className={styleClasses.searchIcon} style={{padding:'6px',color:'#AFAFAF'}}><SearchIcon /></div>
                        <input className={styleClasses.searchInput}  placeholder="Search" ref={searchRef} onChange={(e)=>rest.debounceFunc(e.target.value)}  ></input>
                     </div>
                     </NavLink>
                </div> */}

        <div className={styleClasses.otherIconsDiv}>
          <div className={styleClasses.otherIconsContainer}>
            <div
              style={{
                paddingTop: "6px",
                marginLeft: "10px",
                cursor: "pointer",
              }}
              aria-controls="customized-menu"
              aria-haspopup="true"
              //  variant="contained"
              onClick={handleClick2}
            >
              {window.localStorage.getItem("userName")}
              <span
                style={{
                  height: "20px",
                  width: "20px",
                  borderRadius: "100%",
                  color: "#3AC652",
                  marginLeft: "10px",
                }}
              >
                ●
              </span>
            </div>
            <StyledMenu
              id="customized-menu"
              anchorEl={anchorE2}
              keepMounted
              open={Boolean(anchorE2)}
              onClose={handleClose2}
            >
              <NavLink className={styleClasses.navlink} to="/profile">
                <StyledMenuItem>
                  <ListItemText primary="Profile" />
                  <ListItemIcon>
                    <PersonOutlineIcon fontSize="small" />
                  </ListItemIcon>
                </StyledMenuItem>
              </NavLink>
              {/* <StyledMenuItem>
                <ListItemText primary="Insights" />
                <ListItemIcon>
                  <AssessmentOutlinedIcon fontSize="small" />
                </ListItemIcon>
              </StyledMenuItem> */}
              {
                window.localStorage.getItem("resourceAllow") === "true" || decodedJwt.userRole === "admin" ? (
                  <NavLink className={styleClasses.navlink} to="/resources">
                    <StyledMenuItem>
                      <ListItemText primary="Resources" />
                      <ListItemIcon>
                        <DataUsageOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                    </StyledMenuItem>
                  </NavLink>
                ) : null
              }
              {decodedJwt.userRole === "admin" ? (
                <>
                  {currentUrl.includes("admin") && decodedJwt.userRole === "admin" ? (
                    <></>
                  ) : (
                    <NavLink to="/admin/dashboard">
                      <StyledMenuItem>
                        <ListItemText primary="Switch to Admin" />
                        <ListItemIcon>
                          <SyncAltOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                      </StyledMenuItem>
                    </NavLink>
                  )}
                </>
              ) : decodedJwt.userRole === "sponsor" ? (
                <>
                  {currentUrl.includes("sponsor") ? (
                    <NavLink to="/applicant/dashboard">
                      <StyledMenuItem>
                        <ListItemText primary="Switch to Applicant" />
                        <ListItemIcon>
                          <SyncAltOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                      </StyledMenuItem>
                    </NavLink>
                  ) : currentUrl.includes("applicant") ? (
                    <NavLink to="/sponsor/dashboard">
                      <StyledMenuItem>
                        <ListItemText primary="Switch to Project Sponsor" />
                        <ListItemIcon>
                          <SyncAltOutlinedIcon fontSize="small" />
                        </ListItemIcon>
                      </StyledMenuItem>
                    </NavLink>
                  ) : (
                    <>
                      <NavLink to="/applicant/dashboard">
                        <StyledMenuItem>
                          <ListItemText primary="Switch to Applicant" />
                          <ListItemIcon>
                            <SyncAltOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                        </StyledMenuItem>
                      </NavLink>
                      <NavLink to="/sponsor/dashboard">
                        <StyledMenuItem>
                          <ListItemText primary="Switch to Project Sponsor" />
                          <ListItemIcon>
                            <SyncAltOutlinedIcon fontSize="small" />
                          </ListItemIcon>
                        </StyledMenuItem>
                      </NavLink>
                    </>
                  )}
                </>
              ) : <>
                {decodedJwt.userRole === "user" && currentUrl.includes("applicant") ? (
                  <></>
                ) : (
                  <NavLink to="/applicant/dashboard">
                    <StyledMenuItem>
                      <ListItemText primary="Switch to Applicant" />
                      <ListItemIcon>
                        <SyncAltOutlinedIcon fontSize="small" />
                      </ListItemIcon>
                    </StyledMenuItem>
                  </NavLink>
                )}
              </>
              }
              <StyledMenuItem onClick={handleLogOut}>
                <ListItemText primary="Logout" />
                <ListItemIcon>
                  <ExitToAppIcon fontSize="small" />
                </ListItemIcon>
              </StyledMenuItem>
            </StyledMenu>
            {/* <span style={{  height:'10px',width:'10px',borderRadius:'100%', color:'#3AC652'}}>●</span></div> */}
            <div style={{ marginLeft: "10px" }}>
              <Avatar
                sx={{ width: "16px", height: "16px" }}
                alt="Titan Logo"
                src={TitalLogo}
              />
            </div>
            {/* <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/notifications"
            >
              <Badge
                badgeContent={countOfNotifications}
                color="error"
                max={9}
                overlap="rectangular"
              >
                <NotificationsNoneOutlinedIcon
                  sx={{
                    padding: "12px",
                    marginRight: "10px",
                    color: "#444E59",
                  }}
                  fontSize="large"
                />
              </Badge>
            </NavLink> */}
          </div>
        </div>
        <div className={styleClasses.iconForSmallScreen}>
          <AiOutlineBars onClick={handleClick} />
        </div>
      </div>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <MenuItem onClick={handleClose}>User</MenuItem> */}
        <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to="/sponsor/dashboard"
        >
          <MenuItem onClick={handleClose}>Home</MenuItem>
        </NavLink>
        <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to="/profile"
        >
          <MenuItem onClick={handleClose}>Profile</MenuItem>
        </NavLink>
        {/* <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to="/notifications"
        >
          <MenuItem onClick={handleClose}>
            Notifications({countOfNotifications})
          </MenuItem>
        </NavLink> */}
        {/* <NavLink
          style={{ textDecoration: "none", color: "black" }}
          to="/search"
        >
          <MenuItem onClick={handleClose}>Insights</MenuItem>
        </NavLink> */}
        {
          window.localStorage.getItem("resourceAllow") === "true" || decodedJwt.userRole === "admin" ? (
            <NavLink
              style={{ textDecoration: 'none', color: 'black' }}
              to="/resources"
            >
              <MenuItem onClick={handleClose}>Resources</MenuItem>
            </NavLink>
          ) : null}
        {decodedJwt.userRole === "admin" ? (
          <>
            {currentUrl.includes("admin") && decodedJwt.userRole === "admin" ? (
              <></>
            ) : (
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/admin/dashboard"
                onClick={logout}
              >
                <MenuItem onClick={handleClose}>Switch to Admin</MenuItem>
              </Link>
            )}
          </>
        ) : decodedJwt.userRole === "sponsor" ? (
          <>
            {currentUrl.includes("sponsor") ? (
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/applicant/dashboard"
                onClick={logout}
              >
                <MenuItem onClick={handleClose}>Switch to Applicant</MenuItem>
              </Link>
            ) : currentUrl.includes("applicant") ? (
              <Link
                style={{ textDecoration: "none", color: "black" }}
                to="/sponsor/dashboard"
                onClick={logout}
              >
                <MenuItem onClick={handleClose}>
                  Switch to Project Sponsor
                </MenuItem>
              </Link>
            ) : (
              <>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/applicant/dashboard"
                  onClick={logout}
                >
                  <MenuItem onClick={handleClose}>Switch to Applicant</MenuItem>
                </Link>
                <Link
                  style={{ textDecoration: "none", color: "black" }}
                  to="/sponsor/dashboard"
                  onClick={logout}
                >
                  <MenuItem onClick={handleClose}>
                    Switch to Project Sponsor
                  </MenuItem>
                </Link>
              </>
            )}
          </>
        ) : <>
          {decodedJwt.userRole === "user" && currentUrl.includes("user") ? (
            <></>
          ) : (
            <Link
              style={{ textDecoration: "none", color: "black" }}
              to="/applicant/dashboard"
              onClick={logout}
            >
              <MenuItem onClick={handleClose}>Switch to Applicant</MenuItem>
            </Link>
          )}
        </>}
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/"
          onClick={handleLogOut}
        >
          <MenuItem onClick={handleClose}>Logout</MenuItem>
        </Link>
      </Menu>


    </div >
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Navbar);
