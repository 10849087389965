import React, { useState, useEffect } from "react";
import styles from "./ThankYou.module.css";
import logo from "../../assets/titanlogo.png";
import { useHistory } from "react-router-dom";
import Cookies from "js-cookie";
import useAutoLogout from "./useAutoLogout";
import { checkTokenValidity } from "../../services";

const UnderProgress = ({ loggedIn }) => {
    const navigate = useHistory();
    useAutoLogout(600000);
    const [isAuthenticated, setIsAuthenticated] = useState(true);

    const handleLogOut = async () => {
        window.localStorage.clear();
        Cookies.remove("token");
        Cookies.remove("connect.sid");
        navigate.push("/");
    };

    useEffect(() => {
        const verifyToken = async () => {
            const res = await checkTokenValidity();
            if (res.data.status === 401 || res.data.status === 403) {
                window.localStorage.clear();
                Cookies.remove("token");
                Cookies.remove("connect.sid");
                navigate.push("/");
                setIsAuthenticated(false);
            }
            else {
                setIsAuthenticated(true);
            }
        };
        verifyToken();
    }, [loggedIn]);

    return (
        <div className={styles.thankYouPage}>
            <div className={styles._header}>
                <div className={styles.logo}>
                    <img src={logo} alt="Titan Logo" />
                </div>
                {/* <h1>Thank You !</h1> */}
            </div>
            <div className={styles._body}>
                <div className={styles._box}>
                    <h2>
                        The Bridge Projects Platform is not yet live for Applicants.
                    </h2>
                </div>
            </div>
            <div className={styles._button}>
                <button onClick={handleLogOut}>Log Out</button>
            </div>
        </div>
    );
};

export default UnderProgress;
