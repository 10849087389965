import React, { useEffect } from "react";
import Image1 from "../assets/LandingPage/card-1.png";
import Image2 from "../assets/LandingPage/card-2.png";
import Image3 from "../assets/LandingPage/card-3.png";
import Image4 from "../assets/LandingPage/card-4.png";
import Image5 from "../assets/LandingPage/card-5.png";
import Image6 from "../assets/LandingPage/card-6.png";
import bgImage from '../assets/BackGround/landingbgweb.png';
import "../css/HomePage.css";
import { useHistory } from "react-router-dom";
import { employeeLevel, parseJwt, openUrl } from "../util/commenFunctions";
import Cookies from 'js-cookie';

const HomePage = () => {
    const history = useHistory();
    const token = Cookies.get('token');
    const decodedJwt = parseJwt(token);
    const requestUrl = localStorage.getItem('requestUrl');
    const userName = localStorage.getItem('userName');


    useEffect(() => {
        if (requestUrl.split("/").includes("thank-you")) {
            history.push(requestUrl.split(process.env.REACT_APP_HOST_FRONTEND)[1]);
        } else if (requestUrl.split("/").includes("project-details")) {
            localStorage.setItem("projectId", requestUrl.split("/project-details/")[1]);
            history.push("/applicant/project-details");
        } else if (decodedJwt.userRole === "admin") {
            window.localStorage.setItem("userRole", "admin");
            history.push("/admin/dashboard");
        } else if (decodedJwt.userRole === "sponsor") {
            window.localStorage.setItem("userRole", "sponsor");
            history.push("/sponsor/dashboard");
        } else if (decodedJwt.userRole === "user") {
            window.localStorage.setItem("userRole", "user");
            history.push("/applicant/dashboard");
        }
        // else if (decodedJwt.userRole === "admin" || decodedJwt.userRole === "sponsor" || decodedJwt.userRole === "user") {
        //     history.push("/home");
        // } 
        else {
            history.push("/");
        }
    }, []);

    return (
        <div className="home" style={{ backgroundImage: `url(${bgImage})` }}>
            <div className="container">
                <div className="welcome">
                    <p>Hi, {userName}</p>
                    <h1>What's on your mind today?</h1>
                    <p>Choose any one of the options</p>
                </div>
                <div className="options">
                    <div
                        className="option"
                        onClick={() => history.push(employeeLevel(decodedJwt.userRole))}
                    >
                        <img
                            src={Image1}
                            alt="Image 1"
                        />
                        <span>Explore Bridge Projects Marketplace</span>
                    </div>
                    <div
                        className="option"
                        onClick={() => openUrl("https://titan.edcast.com/pathways/ECL-89ebe7f4-c9c9-4cb8-bf50-cccab37b5cbf", '_blank')}
                    >
                        <img
                            src={Image2}
                            alt="Image 2"
                        />
                        <span>Upskill with Bridge Learning</span>
                    </div>
                    <div className="option"
                        onClick={() => openUrl("https://performancemanager10.successfactors.com/sf/mentoring?bplte_company=Titan", '_blank')}
                    >
                        <img
                            src={Image3}
                            alt="Image 3"
                        />
                        <span>Sign up for Mentorship</span>
                    </div>
                    <div
                        className="option"
                        onClick={() => openUrl("https://titan.edcast.com/pathways/ECL-a851ac7b-0de3-4df4-9776-6154603ed71e", '_blank')}
                    >
                        <img
                            src={Image4}
                            alt="Image 4"
                        />
                        <span>Sneak-peak into the diverse roles at Titan</span>
                    </div>
                    {/* <div className="option">
                        <img
                            src={Image5}
                            alt="Image 5"
                        />
                        <span>OwnYourCareer</span>
                    </div> */}
                    <div
                        className="option"
                        onClick={() => openUrl("https://titan.edcast.com/teams/titan-career-vista/27949", '_blank')}
                    >
                        <img
                            src={Image6}
                            alt="Image 6"
                        />
                        <span>Discover all Titan Career Vista Resources</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default HomePage;
