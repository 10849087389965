import { downloadFootFallData } from "../../services/index";
import XLSX from 'sheetjs-style';
import { saveAs } from 'file-saver';

  const handleFootFallDownload = async () => {
    const currentUrl = window.location.pathname;
    if (currentUrl.includes("admin")) {
    const response = await downloadFootFallData();
    if (response.data.status === "200") {
      handleDownload(response.data.logs)
    }
    }
  };

  const handleDownload = (downloadData) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
    const fileName = 'projectsReport';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(downloadData, { skipHeader: true });
    ws['!cols'] = [];
    for (let i = 0; i < 24; i++) {
      ws['!cols'].push({ width: 12 });
    }
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    saveAs(data, fileName + fileExtension);
  };



export default handleFootFallDownload;
