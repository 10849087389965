import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom/cjs/react-router-dom.min";
import { AiOutlineLeft } from "react-icons/ai";
import Navbar from "../common/Navbar";
import styels from "./Drafts.module.css";
import classes from "./OpenProjects.module.css";
import {
  getAdminProjectsList,
  getAdminFilterProject,
} from "../../services/index";
import { dateFormat, openUrl } from "../../util/commenFunctions";
import image from "../../assets/titanlogo.png";
import exportIcon from "../../assets/export.png";
import bgImage from '../../assets/BackGround/bg3web.png';
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import { thumbnails } from "../common/Images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from "../user/ProjectDetails.module.css";

const OpenProjects = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [openProjects, setOpenProjects] = useState([]);

  const getOpenProjectsList = async () => {
    const searchData = {
      status: "open",
    };
    const res = await getAdminProjectsList(searchData);
    if (res.data.status === "200") {
      setOpenProjects(res.data.projects);
    }
  };

  const filterProjects = async () => {
    const requestData = {
      searchText,
      employeeType,
      projectType,
      projectStatus,
    };
    const res = await getAdminFilterProject(requestData);
    if (res.data.status === "200") {
      setOpenProjects(res.data.openResult);
    }
  };

  const openNewTab = (data) => {
    window.localStorage.setItem("projectId", data._id);
    const newTab = window.open("/admin/project-details", "_blank");
    newTab.focus();
  };

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getOpenProjectsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getOpenProjectsList();
    }
  }, []);

  return (
    <div>
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styels.subdiv}>
          <div className={styels.backdiv}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/admin/dashboard"
            >
              <AiOutlineLeft style={{ fontSize: "32px" }} />
            </NavLink>
            <h1 className={styels.head}>Open Projects</h1>
          </div>
          <div style={{ padding: "0px 30px" }}>
            <Filter />
          </div>
          <div className={styels.draftsList}>
            {openProjects.map((project, index) => {
              const finalProject = { ...project, index };
              return (
                <div className={styels.draft} key={index}>
                  <div className={styels.draftHeader}>
                    <div className={styels.thumdiv}>
                      <img
                        src={
                          project.thumbnail === "" ||
                            project.thumbnail === "undefined"
                            ? thumbnails[project.localThumbnail]?.path || image
                            : project.thumbnail
                        }
                        alt="thumbnail"
                        className={styels.thumbnail}
                      />
                    </div>
                    <div className={styels.draftTitle}>
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/admin/project-details",
                          state: { project: finalProject },
                        }}
                      >
                        <h1>{project.title}</h1>
                      </NavLink>
                      <p>Posted on {dateFormat(project.createdAt)}</p>
                      <div className={styles.sponsor}>
                        <p>{JSON.parse(project.createdBy).name}</p>
                        {/* <Tippy content="View Sponsor Profile" placement="bottom">
                          <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${project.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={styles.link} />
                        </Tippy> */}
                      </div>
                    </div>
                  </div>
                  <div className={classes.exportdiv}>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={{
                        pathname: "/admin/edit-project",
                        state: { draft: finalProject, isEditProject: true },
                      }}
                    >
                      <button className={`${styels.editbtn} ${classes.editbtn}`}>Edit</button>
                    </NavLink>
                    <NavLink
                      style={{ textDecoration: "none", color: "black" }}
                      to={{
                        pathname: "/admin/project-details",
                        state: { project: finalProject },
                      }}
                    >
                      <button className={styels.editbtn}>View Details</button>
                    </NavLink>
                    <img
                      onClick={() => openNewTab(finalProject)}
                      src={exportIcon}
                      className={classes.export}
                      title="Open in new tab"
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OpenProjects;
