import React from "react";
import styels from "./Filter.module.css";
import searchIcon from "../../assets/search.png";
import arrowIcon from "../../assets/filterDownArrow.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import * as actions from "../../actions/filterActions";

const Filter = () => {
  const currentUrl = window.location.pathname;
  const dispatch = useDispatch();
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [anchorE3, setAnchorE3] = React.useState(null);
  const [anchorE4, setAnchorE4] = React.useState(null);
  const [anchorE5, setAnchorE5] = React.useState(null);

  const handleClick3 = (event) => {
    setAnchorE3(event.currentTarget);
  };

  const handleClick4 = (event) => {
    setAnchorE4(event.currentTarget);
  };

  const handleClick5 = (event) => {
    setAnchorE5(event.currentTarget);
  };

  const handleClose3 = () => {
    setAnchorE3(null);
  };

  const handleClose4 = () => {
    setAnchorE4(null);
  };

  const handleClose5 = () => {
    setAnchorE5(null);
  };

  const handleCheckboxEmployeeTypeChange = (option) => {
    const allIndex = employeeType.indexOf("All");
    if (option === "All") {
      const allChecked = allIndex !== -1;
      const newCheckedItems = allChecked
        ? []
        : ["L1", "L2", "L3", "L4", "L5", "L6", "L7", "L8", "L9", "All"];
      dispatch(actions.setEmployeeType(newCheckedItems));
      return newCheckedItems;
    } else {
      const optionIndex = employeeType.indexOf(option);
      const updatedCheckedItems =
        optionIndex !== -1
          ? employeeType.filter((item) => item !== option)
          : [...employeeType, option];
      if (allIndex !== -1) {
        updatedCheckedItems.splice(allIndex, 1);
      }
      dispatch(actions.setEmployeeType(updatedCheckedItems));
      return updatedCheckedItems;
    }
  };

  const handleEmployeeType = () => {
    handleClose3();
  };

  const handleCheckboxProjectTypeChange = (option) => {
    const optionIndex = projectType.indexOf(option);
    const updatedCheckedItems =
      optionIndex !== -1
        ? projectType.filter((item) => item !== option)
        : [...projectType, option];
    dispatch(actions.setProjectType(updatedCheckedItems));
  };

  const handleProjectType = () => {
    handleClose4();
  };

  const handleCheckboxProjectStatusChange = (option) => {
    const optionIndex = projectStatus.indexOf(option);
    const updatedCheckedItems =
      optionIndex !== -1
        ? projectStatus.filter((item) => item !== option)
        : [...projectStatus, option];
    dispatch(actions.setProjectStatus(updatedCheckedItems));
  };

  const handleProjectStatus = () => {
    handleClose5();
  };

  return (
    <div className={styels.filterDiv}>
      <div className={styels.searchDiv}>
        <img src={searchIcon} alt="search icon" />
        <input
          type="search"
          placeholder="Search project title"
          value={searchText}
          onChange={(e) => {
            dispatch(actions.setSearchText(e.target.value));
          }}
        />
      </div>
      <div className={styels.dropdownDiv}>
        {!currentUrl.includes("applicant") && <div>
          <div
            className={styels.filter}
            onClick={handleClick3}
            aria-controls="simple-menu"
            aria-haspopup="true"
          >
            <p>Employee level</p>
            <img src={arrowIcon} alt="arrow icon" />
          </div>
          <Menu
            getContentAnchorEl={null}
            id="simple-menu"
            elevation={0}
            anchorEl={anchorE3}
            keepMounted
            open={Boolean(anchorE3)}
            onClose={handleClose3}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <MenuItem>
              <p className={styels.filterhead}>Filter Employee level</p>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L1")}>
                <p>L1</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("L1") || employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L2")}>
                <p>L2</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("L2") || employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L3")}>
                <p>L3</p>
                <input
                  type="checkbox"
                  checked={
                    employeeType.includes("L3") || employeeType.includes("All")
                  }
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L4")}>
                <p>L4</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("L4") || employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L5")}>
                <p>L5</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("L5") || employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L6")}>
                <p>L6</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("L6") || employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L7")}>
                <p>L7</p>
                <input
                  type="checkbox"
                  checked={
                    employeeType.includes("L7") || employeeType.includes("All")
                  }
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L8")}>
                <p>L8</p>
                <input
                  type="checkbox"
                  checked={
                    employeeType.includes("L8") || employeeType.includes("All")
                  }
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("L9")}>
                <p>L9</p>
                <input
                  type="checkbox"
                  checked={
                    employeeType.includes("L9") || employeeType.includes("All")
                  }
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <div className={styels.filterOpt} onClick={() => handleCheckboxEmployeeTypeChange("All")}>
                <p>All employees</p>
                <input
                  type="checkbox"
                  checked={employeeType.includes("All")}
                  onChange={(e) => e.stopPropagation()}
                />
              </div>
            </MenuItem>
            <MenuItem>
              <button onClick={handleEmployeeType} className={styels.filterBtn}>
                Filter
              </button>
            </MenuItem>
          </Menu>
        </div>}
        <div
          className={styels.filter}
          onClick={handleClick4}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <p>Project type</p>
          <img src={arrowIcon} alt="arrow icon" />
        </div>
        <Menu
          getContentAnchorEl={null}
          id="simple-menu"
          elevation={0}
          anchorEl={anchorE4}
          keepMounted
          open={Boolean(anchorE4)}
          onClose={handleClose4}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem>
            <p className={styels.filterhead}>Filter Project type</p>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectTypeChange("Remote")}>
              <p>Remote</p>
              <input
                type="checkbox"
                checked={projectType.includes("Remote")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectTypeChange("Hybrid")}>
              <p>Hybrid</p>
              <input
                type="checkbox"
                checked={projectType.includes("Hybrid")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectTypeChange("Work From Office")}>
              <p>Work From Office</p>
              <input
                type="checkbox"
                checked={projectType.includes("Work From Office")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <button onClick={handleProjectType} className={styels.filterBtn}>
              Filter
            </button>
          </MenuItem>
        </Menu>
        <div
          className={styels.filter}
          onClick={handleClick5}
          aria-controls="simple-menu"
          aria-haspopup="true"
        >
          <p>Project status</p>
          <img src={arrowIcon} alt="arrow icon" />
        </div>
        <Menu
          getContentAnchorEl={null}
          id="simple-menu"
          elevation={0}
          anchorEl={anchorE5}
          keepMounted
          open={Boolean(anchorE5)}
          onClose={handleClose5}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuItem>
            <p className={styels.filterhead}>Filter Project type</p>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectStatusChange("ongoing")}>
              <p>In progress</p>
              <input
                type="checkbox"
                checked={projectStatus.includes("ongoing")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectStatusChange("completed")}>
              <p>Completed</p>
              <input
                type="checkbox"
                checked={projectStatus.includes("completed")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <div className={styels.filterOpt} onClick={() => handleCheckboxProjectStatusChange("open")}>
              <p>Open Projects</p>
              <input
                type="checkbox"
                checked={projectStatus.includes("open")}
                onChange={(e) => e.stopPropagation()}
              />
            </div>
          </MenuItem>
          <MenuItem>
            <button onClick={handleProjectStatus} className={styels.filterBtn}>
              Filter
            </button>
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default Filter;
