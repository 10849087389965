import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import { toast } from "react-toastify";
import styels from "../common/Profile.module.css";
import exemplaryExpert from "../../assets/ExemplaryExpert.png";
import noviceAchiever from "../../assets/NoviceAchiever.png";
import skillfulPerformer from "../../assets/SkillfulPerformer.png";
import proficientContributor from "../../assets/ProficientContributor.png";
import outstandingAchiever from "../../assets/OutstandingAchiever.png";
import masterCollaborator from "../../assets/MasterCollaborator.png";
import distinguishedProfessional from "../../assets/DistinguishedProfessional.png";
import elitePerformer from "../../assets/ElitePerformer.png";
import exceptionalLeader from "../../assets/ExceptionalLeader.png";
import legendaryAchiever from "../../assets/LegendaryAchiever.png";
import personImage from "../../assets/person.png";
import bgImage from '../../assets/BackGround/bg4web.png';
import modelStyles from "./Preview.module.css";
import {
  projectUserApply, getUserDetails, getUserProjectCounts, sendMessage
} from "../../services/index";
import moment from 'moment';

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "33px 80px",
  },
}));

const Profile = ({
  recommendation,
  onApply,
  getRecommendations,
  getProjectCountsList,
}) => {
  const history = useHistory();
  const maxCharacters = 100;
  const [profileData, setProfileData] = useState();
  const [userProfilePhoto, setUserProfilePhoto] = useState(personImage);
  const [open, setOpen] = React.useState(false);
  const [msg, setMsg] = React.useState("");
  const [projectCounts, setProjectCounts] = useState(0);
  const [readMore, setReadMore] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const toggleReadMore = () => {
    setReadMore(!readMore);
  };

  const skillsToShow = readMore ? profileData?.skills : profileData?.skills?.slice(0, 5);
  const hasMoreSkills = profileData?.skills?.length > 5;

  const mesg = `
<p>Dear [Title Name],</p>

<p>Hope this email finds you well.</p>
<p>Your team member [Applicant Name] has applied for a Bridge Project titled [Project Title], published by [Sponsor Name].</p>

<p>Bridge projects are aimed at providing experience & exposure to Titanians through short-term projects within or outside of their current function. This would give them an opportunity to diversify their skillset, as well as enrich their overall experience and learning.</p>
<p>The next steps in this process would be as follows:</p>
<p>Profile based Shortlisting > Interview > Selection > Assignment > Project execution > Project completion</p>
<p>We will keep you informed as & when [Applicant Name] progresses in this process.</p>

<p>While [Applicant Name] has taken on an initiative to go above & beyond their role & responsibilities to enhance their skillset & gain diverse experience, we encourage you to play your part in supporting [Applicant Name] on this journey of development.</p>

<p>For your reference, the details of the project are shared in the table below:</p>

<table style="width: 100%; border-collapse: collapse;">
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project ID</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project ID]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Title</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Project Title]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Start Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Start Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project End Date</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[End Date]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Open To</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Open To]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Skills Required</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Skills Required]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Weekly Time Commitment</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Time Commitment] hours</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Type</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Type]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Project Description</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Description]</td>
  </tr>
  <tr>
    <th style="border: 1px solid #dddddd; text-align: left; padding: 8px; background-color: #f2f2f2;">Key Deliverables</th>
    <td style="border: 1px solid #dddddd; text-align: left; padding: 8px;">[Deliverables]</td>
  </tr>
</table>

<p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

<p>Regards,</p>
<p>Team Titan Career Vista</p>
  `;

  const applicantMesg = `
  <p>Dear [Title Name],</p>
  
  <p>Your application for the "[Project ID]: [Project Title]" has been submitted successfully.</p>

  <p>The next steps in the process would be as follows:</p>
  <p>Profile based Shortlisting > Interview > Selection > Assignment > Project execution > Project completion</p>
  
  <p>You will get an update on your application shortly. You may also check the status of your application on the platform <a href="${process.env.REACT_APP_HOST_FRONTEND}/applicant/project-details/${recommendation?._id}">Bridge Projects Marketplace</a>.</p>
  
  <p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>
  
  <p>
  Regards,<br/>
  Team Titan Career Vista
  </p>
    `;

  const sponsorMesg = `
    <p>Dear [Title Name],</p>

    <p>[Applicant Name] has applied to your Bridge Project "[Project ID]: [Project Title]".</p>

    <p>You may view the Applicant's profile on the platform <a href="${process.env.REACT_APP_HOST_FRONTEND}/sponsor/project-details/${recommendation?._id}">Bridge Projects Marketplace</a></p>

    <p>The next steps in the process would be as follows:</p>
    <p>Profile based Shortlisting > Interview > Selection > Assignment > Project execution > Project completion</p>

    <p>In case you don't want to go ahead with this application, kindly share feedback with the applicant stating your reason on the platform <a href="${process.env.REACT_APP_HOST_FRONTEND}/sponsor/project-details/${recommendation?._id}">Bridge Projects Marketplace</a></p>

    <p>For any support required, feel free to write to us @<a href="mailto:DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net">DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net</a></p>

    <p>
    Regards,<br/>
    Team Titan Career Vista
    </p>
      `;

  const handleChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= maxCharacters) {
      setMsg(inputValue);
    }
  };

  const getAboutMe = async () => {
    const res = await getUserDetails(window.localStorage.getItem("userEmail"));
    if (res.data.status === "200") {
      setMsg(res.data.data[0].description);
    }
  };

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleApply = async (projectId) => {
    setIsDisabled(true);
    // if (msg) {
    const applyData = {
      projectId: projectId,
      id: window.localStorage.getItem("userId"),
      name: window.localStorage.getItem("userName"),
      email: window.localStorage.getItem("userEmail"),
      phone: window.localStorage.getItem("userPhoneNumber"),
      profilePhoto: window.localStorage.getItem("userProfilePhoto"),
      level: window.localStorage.getItem("userLevel"),
      department: window.localStorage.getItem("userDepartment"),
      division: window.localStorage.getItem("userDivision"),
      orgUnit: window.localStorage.getItem("userOrgUnit"),
      sbu: window.localStorage.getItem("userSbu"),
      function: window.localStorage.getItem("userFunction"),
      experience: window.localStorage.getItem("userExperience"),
      title: window.localStorage.getItem("userTitle"),
      managerEmail: window.localStorage.getItem("managerEmail"),
      managerName: window.localStorage.getItem("managerName"),
      skills: window.localStorage.getItem("userSkills") === 'null' ? [] : window.localStorage.getItem("userSkills")?.split(","),
      msg: msg,
    };
    const res = await projectUserApply(applyData);
    if (res.data.status === "200") {
      onApply();
      getRecommendations();
      getProjectCountsList();
      toast.success("Project Applied Successfully");
      history.push("/applicant/dashboard");
      const dataEmail = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: window.localStorage.getItem("managerEmail"),
        titleName: window.localStorage.getItem("managerName"),
        sponsorName: recommendation?.sponsorName,
        applicantName: window.localStorage.getItem("userName"),
        subject: `Update: "[Applicant Name]" has applied for a Bridge Project`,
        message: mesg,
        id: recommendation?._id,
        projectId: recommendation?.projectId,
        projectTitle: recommendation?.title,
        projectDescription: recommendation?.description,
        projectStatus: recommendation?.status,
        startDate: moment(recommendation?.startDate).format("DD-MM-YYYY"),
        endDate: moment(Number(recommendation?.endDate[recommendation?.endDate.length - 1])).format("DD-MM-YYYY"),
        openTo: recommendation?.employeeLevels.join(", "),
        skillRequired: recommendation?.requiredSkills.map((each) => JSON.parse(each)).map((each) => each.label).join(", "),
        timeCommitment: recommendation?.weeklyTime,
        type: recommendation?.typeOfRequirement,
        keyDeliverables: recommendation?.keyDeliverables,
      };
      await sendMessage(dataEmail);
      const dataEmailApplicant = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: window.localStorage.getItem("userEmail"),
        titleName: window.localStorage.getItem("userName"),
        subject: `Application received for "[Project ID]: [Project Title]" | Bridge Projects`,
        message: applicantMesg,
        projectId: recommendation?.projectId,
        projectTitle: recommendation?.title,
      };
      await sendMessage(dataEmailApplicant);
      const dataEmailSponsor = {
        fromEmail: `DoNotReply@4f37937c-0053-4f7d-9e13-c7c9c13261ee.azurecomm.net`,
        toEmail: JSON.parse(recommendation.createdBy).email,
        titleName: recommendation?.sponsorName,
        applicantName: window.localStorage.getItem("userName"),
        subject: `Application received for "[Project ID]: [Project Title]" | Bridge Projects`,
        message: sponsorMesg,
        projectId: recommendation?.projectId,
        projectTitle: recommendation?.title,
      };
      await sendMessage(dataEmailSponsor);
      setIsDisabled(false);
    }
  };

  const handleProfileData = async () => {
    const responseData = {
      department: window.localStorage.getItem("userDepartment"),
      division: window.localStorage.getItem("userDivision"),
      orgUnit: window.localStorage.getItem("userOrgUnit"),
      sbu: window.localStorage.getItem("userSbu"),
      level: window.localStorage.getItem("userLevel"),
      experience: window.localStorage.getItem("userExperience"),
      skills: window.localStorage.getItem("userSkills")?.split(","),
      phoneNumber: window.localStorage.getItem("userPhoneNumber"),
      title: window.localStorage.getItem("userTitle"),
    };
    setProfileData(responseData);
  };

  const handleProjectCounts = async (userId) => {
    const res = await getUserProjectCounts(userId);
    if (res.data.status === "200") {
      setProjectCounts(res.data.project);
    }
  }

  useEffect(() => {
    handleProfileData();
    getAboutMe();
    handleProjectCounts(window.localStorage.getItem("userId"))
  }, []);

  useEffect(() => {
    window.localStorage.getItem(userProfilePhoto) !== null &&
      setUserProfilePhoto(
        `data:image/jpeg;base64,${window.localStorage.getItem(
          userProfilePhoto
        )}`
      );
  }, [window.localStorage.getItem(userProfilePhoto)]);

  const body = (
    <div style={modalStyle} className={classes.paper}>
      <div className={styels.headdiv}>
        <h1>Badges</h1>
        <p>
          Here you’ll be able to see complete information about the badge
          system.{" "}
        </p>
      </div>
      <div className={styels.badgesdiv}>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={noviceAchiever}
              alt="Novice Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Novice Achiever</h3>
              <p>
                Earned after completing the first streak of a project
                successfully.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.badgeimg}
              src={skillfulPerformer}
              alt="Skillful Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Skillful Performer</h3>
              <p>Achieved after completing two streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              src={proficientContributor}
              alt="Proficient Contributor Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Proficient Contributor</h3>
              <p> Earned after completing three streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={outstandingAchiever} alt="Outstanding Achiever Bagde" />
            <div className={styels.badgetext}>
              <h3>Outstanding Achiever</h3>
              <p>Achieved after completing four streaks of 5 projects each.</p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img src={masterCollaborator} alt="Master Collaborator Bagde" />
            <div className={styels.badgetext}>
              <h3>Master Collaborator</h3>
              <p>Earned after completing five streaks of 5 projects each.</p>
            </div>
          </div>
        </div>
        <div className={styels.bottomBadgediv}>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exemplaryExpert}
              alt="Exemplary Expert Bagde"
            />
            <div className={styels.badgetext}>
              <h3>ExemplaryExpert</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 5 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={distinguishedProfessional}
              alt="Distinguished Professional Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Distinguished Professional</h3>
              <p>
                {" "}
                Earned after receiving positive feedback from project managers
                on 10 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={elitePerformer}
              alt="Elite Performer Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Elite Performer</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 15 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={exceptionalLeader}
              alt="Exceptional Leader Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Exceptional Leader</h3>
              <p>
                Earned after receiving positive feedback from project managers
                on 20 different projects.
              </p>
            </div>
          </div>
          <div className={styels.badgediv}>
            <img
              className={styels.topImg}
              src={legendaryAchiever}
              alt="Legendary Achiever Bagde"
            />
            <div className={styels.badgetext}>
              <h3>Legendary Achiever</h3>
              <p>
                Achieved after receiving positive feedback from project managers
                on 25 different projects.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className={modelStyles.mainDiv} style={{ backgroundImage: `url(${bgImage})` }}>
      <div className={modelStyles.subdiv}>
        <div className={styels.subSection}>
          <div className={styels.profileSection}>
            <div className="Profilediv">
              <img
                src={userProfilePhoto}
                alt="User Profile Photo"
                className={styels.profilePhoto}
              />
              {/* <div
                className={styels.profile}
              >
                <img
                  onClick={handleOpen}
                  className={styels.topBadge}
                  src={exemplaryExpert}
                  alt="Exemplary Expert Badge"
                />
                <img
                  onClick={handleOpen}
                  className={styels.bottomBadge}
                  src={noviceAchiever}
                  alt="Exemplary Expert Badge"
                />
              </div> */}
            </div>
            {/* <div className={styels.badgesTitle}>
              <button>Exemplary Expert </button>
              <button>Novice Achiever</button>
            </div> */}
            <div className={styels.aboutdiv}>
              <h1>ABOUT ME</h1>
              <textarea
                placeholder="Write something in 100 characters."
                value={msg}
                onChange={handleChange}
                maxLength={maxCharacters}
              />
            </div>
          </div>
          <div className={styels.detailSection}>
            <div className={styels.userDetails}>
              <div className={styels.skillDetails}>
                <div className={styels.info}>
                  <h3>Name: </h3>
                  <p>{window.localStorage.getItem("userName")}</p>
                </div>
                <div className={styels.info}>
                  <h3>Function: </h3>
                  <p>
                    {profileData?.department === "null" ? "-" : profileData?.department}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Division: </h3>
                  <p>
                    {profileData?.division === "null" ? "-" : profileData?.division}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>SBU: </h3>
                  <p>
                    {profileData?.sbu === "null" ? "-" : profileData?.sbu}
                  </p>
                </div>
                {/* <div className={styels.info}>
                  <h3>Ongoing Bridge Project: </h3>
                  <p>{projectCounts === 0 ? "-" : `${projectCounts} Project`}</p>
                </div> */}
                <div className={styels.info}>
                  <h3>Skills:</h3>
                  <ul className={styels.skillsList}>
                    {skillsToShow && skillsToShow.some(skill => skill !== "null")
                      ? skillsToShow.map((item, index) => (
                        <li key={index}>{item}</li>
                      ))
                      : <p>-</p>}
                    {hasMoreSkills && (
                      <li style={{ cursor: 'pointer' }} onClick={toggleReadMore}>
                        {readMore ? 'Show Less' : 'Read More'}
                      </li>
                    )}
                  </ul>
                </div>
              </div>
              <div className={styels.address}>
                {/* <div
                  style={{ flexDirection: "column" }}
                  className={styels.info}
                >
                  <h3>Address:</h3>
                  <p>{window.localStorage.getItem("userEmail")}</p>
                  <p>
                    {profileData?.phoneNumber === "null" ? "-" : profileData?.phoneNumber}
                  </p>
                </div> */}
                <div className={styels.info}>
                  <h3>Email: </h3>
                  <p>
                    {window.localStorage.getItem("userEmail")}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Contact Number: </h3>
                  <p>
                    {profileData?.phoneNumber === "null" ? "-" : profileData?.phoneNumber}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Experience: </h3>
                  <p>
                    {profileData?.experience === "null" ? "-" : profileData?.experience}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Level: </h3>
                  <p>
                    {profileData?.level === "null" ? "-" : profileData?.level}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Location: </h3>
                  <p>
                    {profileData?.orgUnit === "null" ? "-" : profileData?.orgUnit}
                  </p>
                </div>
                <div className={styels.info}>
                  <h3>Title: </h3>
                  <p>
                    {profileData?.title === "null" ? "-" : profileData?.title}
                  </p>
                </div>
              </div>
            </div>
            {/* <div className={styels.workSection}>
              <h1>Previous Work: </h1>
              <div className={styels.projects}>
                <ul className={styels.scrool}>
                  <li className={styels.project}>
                    <div className={styels.projectTitle}>
                      <h1>Graphic Designer</h1>
                      <div className={styels.rating}>
                        {Array.from({ length: 5 }).map((_, i) => (
                          <img
                            className={styels.star}
                            src={starImg}
                            alt="star"
                            key={i}
                          />
                        ))}
                      </div>
                    </div>
                    <p>Duration: 2 months</p>
                    <h4>
                      Feedback from Project Sponsor: “Your exceptional work
                      ethic, attention to detail, and collaborative approach
                      have made a significant impact on the success of the
                      project. Thank you for your valuable contributions!”
                    </h4>
                  </li>
                  <li className={styels.project}>
                    <div className={styels.projectTitle}>
                      <h1>Project Manager</h1>
                      <div className={styels.rating}>
                        {Array.from({ length: 4 }).map((_, i) => (
                          <img
                            className={styels.star}
                            src={starImg}
                            alt="star"
                            key={i}
                          />
                        ))}
                        {Array.from({ length: 1 }).map((_, i) => (
                          <img
                            className={styels.star}
                            src={emptyStar}
                            alt="star"
                            key={i}
                          />
                        ))}
                      </div>
                    </div>
                    <p>Duration: 2 months</p>
                    <h4>
                      Feedback from Project Sponsor: “Your exceptional work
                      ethic, attention to detail, and collaborative approach
                      have made a significant impact on the success of the
                      project. Thank you for your valuable contributions!”
                    </h4>
                  </li>
                  <li className={styels.project}>
                    <div className={styels.projectTitle}>
                      <h1>Graphic Designer</h1>
                      <div className={styels.rating}>
                        {Array.from({ length: 5 }).map((_, i) => (
                          <img
                            className={styels.star}
                            src={starImg}
                            alt="star"
                            key={i}
                          />
                        ))}
                      </div>
                    </div>
                    <p>Duration: 2 months</p>
                    <h4>
                      Feedback from Project Sponsor: “Your exceptional work
                      ethic, attention to detail, and collaborative approach
                      have made a significant impact on the success of the
                      project. Thank you for your valuable contributions!”
                    </h4>
                  </li>
                </ul>
              </div>
            </div> */}
          </div>
        </div>
      </div>
      <button
        onClick={() => handleApply(recommendation._id)}
        className={modelStyles.applybtn}
        disabled={isDisabled}
      >
        Apply
      </button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Profile;
