import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Navbar from "../common/Navbar";
import { AiOutlineLeft } from "react-icons/ai";
import styels from "./Drafts.module.css";
import {
  getAdminProjectsList,
  getAdminFilterProject,
} from "../../services/index";
import image from "../../assets/titanlogo.png";
import { dateFormat, openUrl } from "../../util/commenFunctions";
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import { thumbnails } from "../common/Images";
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import styles from "../user/ProjectDetails.module.css";
import exportIcon from "../../assets/export.png";
import bgImage from '../../assets/BackGround/bg5web.png';

const Drafts = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [drafts, setDrafts] = useState([]);

  const getDraftsList = async () => {
    const searchData = {
      status: "draft",
    };
    const res = await getAdminProjectsList(searchData);
    if (res.data.status === "200") {
      setDrafts(res.data.projects);
    }
  };

  const filterProjects = async () => {
    const requestData = {
      searchText,
      employeeType,
      projectType,
      projectStatus,
    };
    const res = await getAdminFilterProject(requestData);
    if (res.data.status === "200") {
      setDrafts(res.data.draftResult);
    }
  };

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getDraftsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getDraftsList();
    }
  }, []);

  return (
    <div>
      <div className="Profile-page">
        <Navbar />
        <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
          <div className={styels.subdiv}>
            <div className={styels.backdiv}>
              <NavLink
                style={{ textDecoration: "none", color: "black" }}
                to="/admin/dashboard"
              >
                <AiOutlineLeft style={{ fontSize: "32px" }} />
              </NavLink>
              <h1 className={styels.head}>Drafts</h1>
            </div>
            <div style={{ padding: "0px 30px" }}>
              <Filter />
            </div>
            <div className={styels.draftsList}>
              {drafts.map((draft, index) => {
                const finalProject = { ...draft, index };
                return (
                  <div className={styels.draft} key={index}>
                    <div className={styels.draftHeader}>
                      <div className={styels.thumdiv}>
                        <img
                          src={
                            draft.thumbnail === "" ||
                              draft.thumbnail === "undefined"
                              ? thumbnails[draft.localThumbnail]?.path || image
                              : draft.thumbnail
                          }
                          alt="thumbnail"
                          className={styels.thumbnail}
                        />
                      </div>
                      <div className={styels.draftTitle}>
                        <NavLink
                          style={{ textDecoration: "none", color: "black" }}
                          to={{
                            pathname: "/admin/edit-draft",
                            state: { draft },
                          }}
                        >
                          <h1>{draft.title}</h1>
                        </NavLink>
                        <p>Posted on {dateFormat(draft.createdAt)}</p>
                        <div className={styles.sponsor}>
                          <p>{JSON.parse(draft.createdBy).name}</p>
                          {/* <Tippy content="View Sponsor Profile" placement="bottom">
                            <img onClick={() => openUrl(`${process.env.REACT_APP_HOST_SF}${draft.sponsorId}`, '_blank')} src={exportIcon} alt="link" className={styles.link} />
                          </Tippy> */}
                        </div>
                      </div>
                    </div>
                    <div>
                      <NavLink
                        style={{ textDecoration: "none", color: "black" }}
                        to={{
                          pathname: "/admin/edit-draft",
                          state: { draft },
                        }}
                      >
                        <button className={styels.editbtn}>Edit draft</button>
                      </NavLink>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Drafts;
