import axios from "axios";
import Cookies from 'js-cookie';

const API_VERSION = "v1";
const BACKEND_URL = process.env.REACT_APP_HOST_BACKEND;
axios.defaults.headers.common['Authorization'] = `Bearer ${Cookies.get('token')}`;

export const getToken = (token) => {
  axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export const checkTokenValidity = () => {
  return axios.get(`${BACKEND_URL}/protected-route`);
}

// Admin apis

export const getAdminProjects = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/admin/list`);
};

export const getAdminProjectsList = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/admin/status`,
    data
  );
};

export const getAdminFilterProject = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/admin/filter`,
    data
  );
};

export const getAdminProjectSuggestions = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/admin/projects/search`,
    data
  )
}

// Sponsor apis

export const getSponsorProjectSuggestions = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/sponsor/projects/search`,
    data
  )
}

export const getSponsorFilterProject = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/sponsor/filter`,
    data
  );
};

export const getSponsorProjectCount = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/sponsor/list/${id}`);
};

export const getProjectsList = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/sponsor/status`,
    data
  );
};

export const downloadProjectsData = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/downloadData`,
    data
  );
};

// User apis

export const getUserFilterProject = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/user/filter`, data);
};

export const getUserProjectCount = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/user/list/${id}`);
};

export const getRecommendationList = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/user/recommendations`, data);
};

export const projectApplyRequest = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/user/applyRequest`,
    data
  );
};

export const projectUserApply = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/user/apply`, data);
};

export const projectUserUpdateStatus = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/user/updateStatus`, data);
};

// Common apis

export const deleteDraftUpload = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/projects/deleteDraftUpload`,
    data
  );
}

export const deleteUploadedResource = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/resources/delete`,
    data
  );
}

export const getDivision = () => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/divisions/list`);
};

export const getSbu = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/sbus/list/${id}`);
};

export const getOrgUnit = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/orgunits/list/${id}`);
};

export const getDepartment = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/departments/list/${id}`);
};

export const getFunction = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/functions/list/`);
};

export const getRequiredSkill = () => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/skills/required`);
};

export const getImproveLearnSkill = () => {
  // return axios.post(`${BACKEND_URL}/${API_VERSION}/skills/improveLearn`, data);
  return axios.get(`${BACKEND_URL}/${API_VERSION}/skills/improveLearn`);
};

export const createProject = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/add`, data);
};

export const updateProject = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/update`, data);
};

export const deleteTemporary = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/temporaryDelete`, data);
};

export const deletePermanently = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/permanentlyDelete`, data);
};

export const getRestore = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/restore/${id}`);
};

export const getApplications = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/applications/${id}`);
};

export const getShortList = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/shortlist/${id}`);
};

export const shortListProject = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/shortlist`, data);
};

export const updateNotShortlisted = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/notShortlisted`, data);
}

export const AssignProject = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/assign`, data);
};

export const updateNotSelected = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/notSelected`, data);
}

export const deleteApplication = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/applicationDelete`, data);
};

export const getProjectDetails = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/details/${id}`);
};

export const getMyNotifications = (email) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/notifications/my/${email}`);
};

export const getUserLastSeenTime = (email) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/lastSeenTime/${email}`);
};

export const updateUserLastSeen = (email) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/users/updateLastSeen`, {
    email: email,
  });
};

export const getMyNotificationsCount = (email) => {
  return axios.get(
    `${BACKEND_URL}/${API_VERSION}/notifications/count/${email}`
  );
};

export const sendMessage = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/sendMessage`, data);
};

export const getUserData = (email) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/id/${email}`);
};

export const getUserExperienceData = (id) => {
  return axios.get(
    `${BACKEND_URL}/${API_VERSION}/users/experience/${id}`
  );
};

export const getUserPhoneNumberData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/phoneNumber/${id}`);
};

export const getUserSkillsData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/skills/${id}`);
};

export const getUserEmployeeLevelData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/employeeLevel/${id}`);
};

export const getUserProfilePhotoData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/profilePhoto/${id}`);
};

export const getManagerData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/managerDetails/${id}`);
};

export const getDivisionData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/divisionDetails/${id}`);
};

export const getFunctionData = (id) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/functionDetails/${id}`);
};

export const addUser = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/users/add`, data);
};

export const updateAboutMe = (data) => {
  return axios.post(
    `${BACKEND_URL}/${API_VERSION}/users/updateDescription`,
    data
  );
};

export const getUserDetails = (email) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/users/details/${email}`);
};

export const projectUpdateStatus = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/updateStatus`, data);
};

export const projectUpdateProgress = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/updateProgress`, data);
};

export const projectMarkAsComplete = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/markAsComplete`, data);
};

export const getUserProjectCounts = (userId) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/countWorkingProjects/${userId}`);
};

export const updateProjectStatus = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/updateProjectStatus`, data);
};

export const addSkill = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/skills/addSkill`, data);
};

export const addResource = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/resources/add`, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getResource = () => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/resources/get`);
};

export const saveModifications = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/projects/needModification`, data);
}

export const updateLog = (data) => {
  return axios.post(`${BACKEND_URL}/${API_VERSION}/logs/updateLogs`, data)
}

export const downloadFootFallData = () => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/logs/downloadData`)
}

export const getAlreadyAssigned = (userId) => {
  return axios.get(`${BACKEND_URL}/${API_VERSION}/projects/alreadyAssigned/${userId}`);
};