import React, { useState, useEffect, useCallback } from "react";
import Navbar from "./Navbar";
import styleClasses from "./Notifications.module.css";
import { connect } from "react-redux";
import { AiOutlineArrowLeft } from "react-icons/ai";
import { NavLink, useHistory } from "react-router-dom";
// import DummyPost from "./Dummypost";
import { FiRefreshCcw } from "react-icons/fi";
import { toast } from "react-toastify";
import { IoMdDoneAll } from "react-icons/io";
import {
  getMyNotifications,
  getUserLastSeenTime,
  updateUserLastSeen,
} from "../../services";
import bgImage from '../../assets/BackGround/bg1web.png';

function Notifications({ profile }) {
  const [userNotifications, setUserNotifications] = useState([]);
  const [currentUserLastSeenTime, setCurrentUserLastSeenTime] = useState(0);
  const navigate = useHistory();

  // useEffect(()=>{
  if (profile === null) {
    // navigate.push("/auth");
    // window.location.reload();
  }
  // },[])
  const { email } = window.localStorage.getItem("userEmail"); // fullname

  const fromAgo = (date) => {
    date = date - 7 * 24 * 60 * 60 * 1000;
    const ms = Date.now() - date;
    const seconds = Math.round(ms / 1000);
    const minutes = Math.round(ms / 60000);
    const hours = Math.round(ms / 3600000);
    const days = Math.round(ms / 86400000);
    const months = Math.round(ms / 2592000000);
    const years = Math.round(ms / 31104000000);
    switch (true) {
      case seconds < 60:
        return `${seconds}s ago`;
      case minutes < 60:
        return `${minutes}m ago`;
      case hours < 24:
        return `${hours}h ago`;
      case days < 30:
        return `${days}d ago`;
      case months < 12:
        return `${months}mon ago`;
      default:
        return `${years}year ago`;
    }
  };

  const getAllNotifications = useCallback(async () => {
    const result = await getMyNotifications(email);
    if (result?.data?.status === "200") {
      setUserNotifications(() => {
        return result.data.notifications;
      });
    }
  }, []);

  const getCurrentUserLastSeen = useCallback(async () => {
    const result = await getUserLastSeenTime(email);
    if (result?.data?.status === "200") {
      setCurrentUserLastSeenTime(() => {
        return result.data.lastSeenTime;
      });
    }
  }, []);

  const handleUpdateUserCurrentTime = useCallback(async () => {
    const result = await updateUserLastSeen(email);
    if (result?.data?.status === "200") {
      toast.success("Notifications marked as read.");
      setCurrentUserLastSeenTime(() => {
        return result.data.lastSeenTime;
      });
      refreshNotification();
    }
  }, []);

  const refreshNotification = useCallback(async () => {
    const result = await getUserLastSeenTime(email);
    if (result?.data?.status === "200") {
      toast.success("Notifications Updated.");
      setCurrentUserLastSeenTime(() => {
        return result.data.lastSeenTime;
      });
      // refreshNo();
      navigate.push("/notifications");
    }
  }, []);

  useEffect(() => {
    getCurrentUserLastSeen();
    getAllNotifications();
  }, []);

  useEffect(() => { }, [userNotifications, currentUserLastSeenTime]);

  return (
    <>
      <Navbar isSearchActive={false} />
      <div className={styleClasses.mainNotificationsContainer} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styleClasses.notificationsContainer}>
          <div className={styleClasses.notificationsHeader}>
            <NavLink
              style={{ textDecoration: "none", color: "black" }}
              to="/sponsor/dashboard"
            >
              <div>
                <AiOutlineArrowLeft />
              </div>
            </NavLink>
            <div className={styleClasses.notificationsHeading}>
              Notifications
            </div>
            <div className={styleClasses.refreshNotifications}>
              <div className={styleClasses.iconsAndTheirInfoDiv}>
                <FiRefreshCcw
                  onClick={refreshNotification}
                  className={styleClasses.refreshIcons}
                />
                <div className={styleClasses.smallText}> Refresh</div>
              </div>

              <div className={styleClasses.iconsAndTheirInfoDiv}>
                <IoMdDoneAll
                  onClick={handleUpdateUserCurrentTime}
                  className={styleClasses.refreshIcons2}
                />
                <div className={styleClasses.smallText}> Mark Read</div>
              </div>
            </div>
          </div>
          {/* <DummyPost text={'Notifications'} /> */}
          {userNotifications.length === 0 ? (
            <div style={{ padding: "12px" }}>No Notifications</div>
          ) : (
            userNotifications.map((notification, index) => {
              return (
                // <NavLink style={{textDecoration:'none',color:'black',marginTop:'12px'}} to={`/event/${notification.linkEventId}`} key={index} >
                <div
                  key={index}
                  className={
                    currentUserLastSeenTime < notification.addedOn
                      ? styleClasses.notificationEachUnread
                      : styleClasses.notificationEachRead
                  }
                >
                  <strong>{notification.notificationBy}</strong>
                  {notification.notificationDesc} .
                  {/* <div className={styleClasses.postPostContent}> {(+notification.postTextContentByHrNotification.length < 80)? (notification.postTextContentByHrNotification):notification.postTextContentByHrNotification.substring(0,80)+"..."} </div> */}
                  <div className={styleClasses.postPostTime}>
                    {fromAgo(notification.addedOn)}
                  </div>
                </div>
                // </NavLink>
              );
            })
          )}
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  profile: state.profile,
});

export default connect(mapStateToProps)(Notifications);
