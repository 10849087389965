import React, { useState, useEffect } from "react";
import { NavLink, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Navbar from "../common/Navbar";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import { withStyles } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import styels from "./Dashboard.module.css";
import downloadIcon from "../../assets/download.png";
import createIcon from "../../assets/dropdown.png";
import create from "../../assets/create.png";
import draftIcon from "../../assets/draft.png";
import deleteIcon from "../../assets/delete.png";
import openIcon from "../../assets/openProject.png";
import pendingIcon from "../../assets/pendingProject.png";
import ongoingIcon from "../../assets/ongoingProject.png";
import completedIcon from "../../assets/completedProject.png";
import bgImage from '../../assets/BackGround/bg2web.png';
import {
  getSponsorProjectCount,
  getSponsorFilterProject,
} from "../../services/index";
import Filter from "../common/Filter";
import { useSelector } from "react-redux";
import Modal from "@material-ui/core/Modal";
import { makeStyles } from "@material-ui/core/styles";
import DownloadData from "../common/DownloadData";

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: "absolute",
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: "33px 80px",
    [theme.breakpoints.down("sm")]: {
      padding: "20px",
      width: "80%",
    },
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.common.white,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.green,
      },
    },
  },
}))(MenuItem);

const Dashboard = () => {
  const { searchText, employeeType, projectType, projectStatus } = useSelector(
    (state) => state.filter
  );
  const [anchor, setAnchor] = useState(null);
  const [projectCounts, setProjectCounts] = useState(null);
  const [open, setOpen] = React.useState(false);

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  const history = useHistory();

  const handleModalOpen = () => {
    setOpen(true);
  };

  const handleModalClose = () => {
    setOpen(false);
  };

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const getProjectCountsList = async () => {
    const userId = window.localStorage.getItem("userId");
    const res = await getSponsorProjectCount(userId);
    if (res.data.status === "200") {
      setProjectCounts(res.data);
    }
  };

  const filterProjects = async () => {
    const userId = window.localStorage.getItem("userId");
    const requestData = {
      userId,
      searchText,
      employeeType,
      projectType,
      projectStatus,
    };
    const res = await getSponsorFilterProject(requestData);
    if (res.data.status === "200") {
      setProjectCounts(res.data);
    }
  };

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getProjectCountsList();
    }
  }, [searchText, employeeType, projectType, projectStatus]);

  useEffect(() => {
    if (
      searchText ||
      employeeType.length ||
      projectType.length ||
      projectStatus.length
    ) {
      filterProjects();
    } else {
      getProjectCountsList();
    }
  }, []);

  const body = (
    <div
      style={modalStyle}
      className={`${classes.paper} ${styels.modalWidthHeight}`}
    >
      <DownloadData onClose={handleModalClose} />
    </div>
  );

  return (
    <div>
      <Navbar />
      <div className={styels.maindiv} style={{ backgroundImage: `url(${bgImage})` }}>
        <div className={styels.subdiv}>
          <div className={styels.headdiv}>
            <div>
              <h1 className={styels.head}>Dashboard - Project Sponsor</h1>
              <p className={styels.navtext} onClick={() => history.push("/applicant/dashboard")}>Click here to switch to Applicant Dashboard</p>
            </div>

            <div className={styels.creatediv}>
              {/* <div className={styels.tab} onClick={handleModalOpen}>
                <img
                  src={downloadIcon}
                  alt="downloadIcon"
                  className={styels.tabicon}
                />
                <p className={styels.tabtext}>Download Data</p>
              </div> */}
              <div></div>
              <div className={styels.tab} role="button" onClick={handleClick}>
                <p className={styels.tabtext}>Create a project</p>
                <img
                  src={createIcon}
                  alt="createIcon"
                  className={styels.dropIcon}
                />
              </div>
              <StyledMenu
                id="customized-menu"
                anchorEl={anchor}
                keepMounted
                open={Boolean(anchor)}
                onClose={handleClose}
              >
                <NavLink to="/sponsor/create-project">
                  <StyledMenuItem>
                    <ListItemIcon>
                      <img
                        src={create}
                        alt="createIcon"
                        className={styels.createIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Create project"
                      className={styels.createText}
                    />
                  </StyledMenuItem>
                </NavLink>
                <NavLink to="/sponsor/drafts">
                  <StyledMenuItem>
                    <ListItemIcon>
                      <img
                        src={draftIcon}
                        alt="createIcon"
                        className={styels.createIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Go to Drafts"
                      className={styels.createText}
                    />
                  </StyledMenuItem>
                </NavLink>
                <NavLink to="/sponsor/trash">
                  <StyledMenuItem>
                    <ListItemIcon>
                      <img
                        src={deleteIcon}
                        alt="createIcon"
                        className={styels.createIcon}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary="Trash"
                      className={styels.createText}
                    />
                  </StyledMenuItem>
                </NavLink>
              </StyledMenu>
            </div>
          </div>
          <Filter />
          <div className={styels.cardsDiv}>
            <div className={styels.card}>
              <div className={styels.imgDiv}>
                <img src={pendingIcon} alt="open project icon" />
              </div>
              <div className={styels.cardInfo}>
                <div className={styels.countdDiv}>
                  <h1>Pending Projects</h1>
                  <p>Total Projects: {projectCounts?.pendingResult?.length}</p>
                </div>
                <div>
                  <NavLink to="/sponsor/pending-projects">
                    <button className={styels.viewBtn}>View all</button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={styels.card1}>
              <div className={styels.imgDiv1}>
                <img src={openIcon} alt="open project icon" />
              </div>
              <div className={styels.cardInfo}>
                <div className={styels.countdDiv}>
                  <h1>Open Projects</h1>
                  <p>Total Projects: {projectCounts?.openResult?.length}</p>
                </div>
                <div>
                  <NavLink to="/sponsor/open-projects">
                    <button className={styels.viewBtn}>View all</button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={styels.card2}>
              <div className={styels.imgDiv2}>
                <img src={ongoingIcon} alt="ongoing project icon" />
              </div>
              <div className={styels.cardInfo}>
                <div className={styels.countdDiv}>
                  <h1>Ongoing Projects</h1>
                  <p>Total Projects: {projectCounts?.onGoingResult?.length}</p>
                </div>
                <div>
                  <NavLink to="/sponsor/ongoing-projects">
                    <button className={styels.viewBtn}>View all</button>
                  </NavLink>
                </div>
              </div>
            </div>
            <div className={styels.card3}>
              <div className={styels.imgDiv3}>
                <img src={completedIcon} alt="completed project icon" />
              </div>
              <div className={styels.cardInfo}>
                <div className={styels.countdDiv}>
                  <h1>Completed Projects</h1>
                  <p>Total Projects: {projectCounts?.completeResult?.length}</p>
                </div>
                <div>
                  <NavLink to="/sponsor/completed-projects">
                    <button className={styels.viewBtn}>View all</button>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleModalClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
};

export default Dashboard;
